import React from "react";
import { Redirect } from "react-router-dom";
import * as PersonPageResult from "../Persons/Persons";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { RotateSpinner } from "react-spinners-kit";
import { FaFemale } from "react-icons/fa";
import { UserRole } from "../Users/User";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { ActiveType } from "../ItemTypes/ItemTypes";

type PersonsProps = PersonPageResult.PersonsState & {
  roles?: Array<UserRole>;
  activeType: ActiveType;
} & typeof PersonPageResult.actionCreators;

const pageSize = 20;

class Persons extends React.PureComponent<PersonsProps> {
  state = { redirect: null };

  componentDidMount = () => {
    this.handleFirstPage();
  };

  appendReturnTo = (redirect: string): string => {
    return `${redirect}?returnTo=/persons/${this.props.activeType.toLocaleLowerCase()}`;
  };

  handleAdd = () => {
    this.setState({ redirect: this.appendReturnTo("/persons/new/") });
  };

  handlePrevPage = () => {
    this.props.get(
      this.props.activeType,
      this.props.tokens[this.props.pageNumber - 1] ?? "",
      pageSize
    );
  };

  handleNextPage = () => {
    this.props.get(this.props.activeType, this.props.continuationToken, pageSize);
  };

  handleFirstPage = () => {
    this.props.get(this.props.activeType, "", pageSize);
  };

  public render() {
    const getStyle = (isActive: boolean): any => {
      return !isActive ? { color: "#D3D3D3" } : {};
    };

    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect} />;
    }

    return (
      <>
        <p className="h4 pt-4">Persons</p>

        <hr />

        <RotateSpinner loading={this.props.isLoading} />

        {!this.props.isLoading && (
          <>
            {this.props.roles?.includes("ScheduleWriter") && (
              <button
                type="button"
                className="btn btn-primary float-left"
                onClick={this.handleAdd}
              >
                Add
              </button>
            )}
            <div className="float-right">
              <nav className="page" aria-label="Page navigation">
                <ul className="pagination justify-content-end">
                  <li
                    key="filters"
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      className=" float-left ml-5"
                      value={this.props.activeType}
                      onChange={(args) => {
                        this.setState({ redirect: `/persons/${args.target.value.toLocaleLowerCase()}` })
                      }}
                    >
                      <FormControlLabel
                        value="active"
                        control={<Radio size="small" color="primary" />}
                        label="Active"
                      />
                      <FormControlLabel
                        value="inactive"
                        control={<Radio size="small" color="secondary" />}
                        label="Inactive"
                      />
                      <FormControlLabel value="all" control={<Radio size="small" color="secondary" />} label="All" />
                    </RadioGroup>
                  </li>
                  <li
                    key="prevButton"
                    className={
                      this.props.pageNumber > 0 && !this.props.isLoading
                        ? "page-item"
                        : "page-item disabled"
                    }
                  >
                    <button className="page-link" onClick={this.handlePrevPage}>
                      Previous
                    </button>
                  </li>
                  <li
                    key="nextButton"
                    className={
                      this.props.hasMoreResults && !this.props.isLoading
                        ? "page-item"
                        : "page-item disabled"
                    }
                  >
                    <button className="page-link" onClick={this.handleNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            <table className="table table-hover table-sm mt-3 border">
              <thead>
                <tr>
                  <th scope="col" className="pl-3" style={{ width: "35%" }}>
                    Full name
                  </th>
                  <th scope="col" className="pl-3">
                    Comments
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.values.map((v) => (
                  <tr key={v.id}>
                    <td className="pl-3">
                      <a
                        style={getStyle(v.isActive ?? false)}
                        href={this.appendReturnTo(`/persons/${v.id}/details`)}
                      >
                        {v.fullName}
                      </a>
                      <span>{v.isFemale ? <FaFemale /> : null}</span>{" "}
                    </td>
                    <td className="pl-3" style={getStyle(v.isActive ?? false)}>
                      {v.comments}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="input-group-sm page float-right">
              Page {this.props.pageNumber + 1}
            </div>
          </>
        )}
      </>
    );
  }
}

export default connect((state: ApplicationState) => {
  let props = state.persons as PersonsProps;
  props.roles = state.principal?.roles;
  return props;
}, PersonPageResult.actionCreators)(Persons as any);
