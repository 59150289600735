import * as React from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./NavMenu.css";
import * as Authentication from "../Auth/Authentication";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { UserInfo } from "../Auth/AuthContext";
import { HasAuthParams, useAuth } from "../Auth/useAuth";
import { useState } from "react";
import { RotateSpinner } from "react-spinners-kit";
import { Avatar } from "./Avatar";

type NavBarProps = Authentication.Principal &
  typeof Authentication.actionCreators;

export const NavMenu: React.FunctionComponent<NavBarProps> = (props) => {
  const onSignedIn = (userInfo?: UserInfo) => {
    if (userInfo) {
      props.signedIn(userInfo);
    }

    setIsAuthenticating(false);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, signIn, signOut] = useAuth(onSignedIn);
  const [isAuthenticating, setIsAuthenticating] = useState(HasAuthParams());

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const isLoggingIn = (): boolean => {
    return isAuthenticating || isLoading;
  };

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
        light
      >
        <Container>
          <NavbarBrand tag={Link} to="/">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div className="mr-2">Meeting scheduler</div>
              {props.isAuthenticated &&
                props.hubClients?.map((client, index) => {
                  return (
                    <div key={client.clientInitials}>
                      <div
                        className="a"
                        data-toggle="tooltip"
                        title={client.clientName}
                        style={{
                          marginRight: "5px",
                          marginTop: "2px",
                        }}
                      >
                        <Avatar
                          id={String(index)}
                          name={client.clientName}
                          picturePath={client.profilePicturePath}
                          size="30px"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </NavbarBrand>

          {props.isAuthenticated && (
            <NavbarToggler onClick={toggle} className="mr-2" />
          )}

          {props.isAuthenticated && (
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={isOpen}
              navbar
            >
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/schedule">
                    Schedule
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark"
                    to="/itemtypes/midweek/active"
                  >
                    Item Types
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/persons/active">
                    Persons
                  </NavLink>
                </NavItem>
                {props.roles?.includes("Admin") && (
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/externalsources">
                      External Sources
                    </NavLink>
                  </NavItem>
                )}
                {props.roles?.includes("Admin") && (
                  <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/users">
                      Users
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <button
                    onClick={async () => {
                      signOut();
                      props.logOut();
                    }}
                    className="text-dark nav-link no-border no-background"
                  >
                    Sign Out
                  </button>
                </NavItem>
              </ul>
            </Collapse>
          )}
          {!props.isAuthenticated && !isLoggingIn() && (
            <button
              className="text-dark nav-link no-border no-background"
              onClick={async () => {
                setIsAuthenticating(true);
                signIn();
              }}
            >
              Login
            </button>
          )}
          <RotateSpinner loading={isLoggingIn()} />
        </Container>
      </Navbar>
    </header>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  isAuthenticated: state.principal?.isAuthenticated,
  hubClients: state.principal?.hubClients,
  roles: state.principal?.roles,
});

export default connect(
  mapStateToProps,
  Authentication.actionCreators
)(NavMenu as any);
