import * as WorkbookStore from "./Workbook";
import React from "react";
import { WorkbookWeekItemState } from "../Items/ItemStore";
import { HubClient } from "../Auth/AuthContext";
import { ItemButtons } from "./ItemButtons";
import { Item } from "../Items/Item";
import { Avatar } from "../components/Avatar";

type Props = {
  workbookId: string;
  weekId: string;
  item: WorkbookWeekItemState;
  defaultHandledBy: string;
  editMode: boolean;
  itemChanges: Array<WorkbookStore.WorkbookItemChangeState>;
  hubClients: Array<HubClient>;
  canEdit: boolean;
  canEditItem: (itemTypeId: string, handledById: string) => boolean
};

export const WorkbookWeekItem: React.FunctionComponent<Props> = (
  props: Props
) => {
  const itemChange = props.itemChanges?.find((x) => x.itemId === props.item.id);
  let client: HubClient | undefined;
  if (itemChange) {
    client = props.hubClients?.find(
      (x) => x.clientName === itemChange?.changedBy
    );
  }
  return (
    <div className="p-0 pl-2">
      {/* {itemChange && itemChange.isDeletion && <span>deleted by</span>} */}
      {itemChange && client && (
        <div
          className="mt-1"
          style={{
            cursor: "default",
            position: "relative",
            float: "left",
            width: "0",
            left: "-40px",
          }}
        >
          <Avatar
            id={props.item.id}
            name={client.clientName}
            picturePath={client.profilePicturePath}
            size="16px"
          />
        </div>
      )}
      <Item
        editMode={props.editMode}
        item={props.item}
        defaultHandledBy={props.defaultHandledBy}
        canEdit={props.canEdit}
        canEditItem={props.canEditItem}
      />
      {props.editMode && props.canEdit && (
        <div style={{ float: "right", marginRight: "5px" }}>
          <ItemButtons
            workbookId={props.workbookId}
            weekId={props.weekId}
            meetingType={props.item.meetingType}
            itemId={props.item.id}
            itemIndex={props.item.index}
            renderDelete={true}
          />
        </div>
      )}
    </div>
  );
};
