import React from "react";
import { bindActionCreators } from "redux";
import * as ItemTypeStore from "./ItemType";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import ItemTypeForm from "./ItemTypeFormComponent";
import { MeetingType } from "./ItemTypes";
import { UserRole } from "../Users/User";

type ItemTypeProps = typeof ItemTypeStore.actionCreators & {
  readOnly: boolean;
  itemType: ItemTypeStore.ItemTypeState | undefined;
  meetingType: MeetingType;
  roles?: Array<UserRole>;
} & RouteComponentProps<{ id?: string; returnTo: string }>;

class ItemType extends React.PureComponent<ItemTypeProps> {
  state = {
    redirect: undefined,
    readOnly: this.props.readOnly,
    id: this.props.match.params.id,
    returnTo: new URLSearchParams(this.props.location.search).get("returnTo"),
  };

  async componentDidMount() {
    if (this.state.id) {
      this.props.get(this.state.id);
    } else {
      this.props.reset(this.props.meetingType);
    }
  }

  componentDidUpdate(
    prevProps: ItemTypeProps,
    prevState: ItemTypeStore.ItemTypeState
  ) {
    if (this.props.itemType?.savingSucceeded) {
      this.setState({
        redirect: this.state.returnTo,
      });
    }
  }

  save = async (entity: any) => {
    this.props.save(entity);
  };

  private handleCancel = () => {
    if (!this.state.readOnly) {
      if (!this.state.id) {
        this.setState({
          redirect: this.state.returnTo,
        });
      } else {
        this.setState({ readOnly: true });
      }
    }
  };

  private handleClose = () => {
    if (this.state.readOnly) {
      this.setState({
        redirect: this.state.returnTo,
      });
    }
  };

  private handleEdit = () => {
    if (this.state.readOnly) {
      this.setState({ readOnly: false });
    }
  };

  private handleDelete = () => {
    if (this.state.id) {
      Promise.resolve(this.props.delete(this.state.id)).then(() => {
        this.setState({
          redirect: this.state.returnTo,
        });
      });
    }
  };

  public render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect || ``} />;
    }

    const isAdmin = this.props.roles?.includes("Admin");

    return (
      <ItemTypeForm
        readOnly={this.state.readOnly}
        initialValues={this.props.itemType}
        onSubmit={this.save}
        onCancel={this.handleCancel}
        onEdit={isAdmin ? this.handleEdit : undefined}
        onClose={this.handleClose}
        onDelete={isAdmin ? this.handleDelete : undefined}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  itemType: state.itemType,
  roles: state.principal?.roles
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      get: ItemTypeStore.actionCreators.get,
      reset: ItemTypeStore.actionCreators.reset,
      delete: ItemTypeStore.actionCreators.delete,
      save: ItemTypeStore.actionCreators.save,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ItemType);
