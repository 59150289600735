import React from "react";
import * as ExternalSourceStore from "./ExternalSource";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";
import { UserRole } from "../Users/User";
import { ExternalSourceForm } from "./ExternalSourceFormComponent";

type ExternalSourceProps = typeof ExternalSourceStore.actionCreators & {
  readOnly: boolean;
  roles: Array<UserRole> | undefined;
  externalSource: ExternalSourceStore.ExternalSourceState | undefined;
} & RouteComponentProps<{ id?: string }>;

// get rid of classes
class ExternalSource extends React.PureComponent<ExternalSourceProps> {
  state = {
    redirect: undefined,
    readOnly: this.props.readOnly,
    id: this.props.match.params.id,
  };

  async componentDidMount() {
    if (this.state.id) {
      this.props.get(this.state.id);
    } else {
      this.props.reset();
    }
  }

  componentDidUpdate(
    prevProps: ExternalSourceProps,
    prevState: ExternalSourceStore.ExternalSourceState
  ) {
    if (this.props.externalSource?.savingSucceeded) {
      this.setState({ redirect: `/externalsources/` });
    }
  }

  save = async (
    entity: ExternalSourceStore.ExternalSourceState
  ): Promise<any> => {
    return this.props.save(entity);
  };

  private handleCancel = () => {
    if (!this.state.readOnly) {
      if (!this.state.id) {
        this.setState({ redirect: `/externalsources/` });
      } else {
        this.setState({ readOnly: true });
      }
    }
  };

  private handleClose = () => {
    if (this.state.readOnly) {
      this.setState({ redirect: `/externalsources/` });
    }
  };

  private handleEdit = () => {
    if (this.state.readOnly) {
      this.setState({ readOnly: false });
    }
  };

  private handleDelete = () => {
    if (this.state.id) {
      Promise.resolve(this.props.delete(this.state.id)).then(() => {
        this.setState({ redirect: `/externalsources/` });
      });
    }
  };

  public render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect || ``} />;
    }

    const isAdmin = this.props.roles?.includes("Admin");

    return (
      <ExternalSourceForm
        readOnly={this.state.readOnly}
        initialValues={this.props.externalSource}
        onSubmit={this.save}
        onCancel={this.handleCancel}
        onEdit={isAdmin ? this.handleEdit : undefined}
        onClose={this.handleClose}
        onDelete={isAdmin ? this.handleDelete : undefined}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  externalSource: state.externalSource,
  roles: state.principal?.roles,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      get: ExternalSourceStore.actionCreators.get,
      reset: ExternalSourceStore.actionCreators.reset,
      delete: ExternalSourceStore.actionCreators.delete,
      save: ExternalSourceStore.actionCreators.save,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ExternalSource);
