import AppSettingsService from "../services/AppSettingsService";
import { IEntityWithId } from "./IEntityWithId";

const endpoint = new AppSettingsService().GetWebApiBaseUri();
const appSettings = new AppSettingsService();

const getAccessToken = (): string | null => {
  const storageKey = `oidc.user:https://accounts.google.com:${appSettings.GetAuthClientId()}`;
  const user = sessionStorage.getItem(storageKey);
  if (user) {
    return JSON.parse(user).access_token;
  } else {
    return null;
  }
};

export class RequestParam {
  constructor(name: string, value: any) {
    this.name = name;
    this.value = value?.toString();
  }

  name: string;
  value: string;
}

export class RequestParams extends Array<RequestParam> {
  constructor(
    name1: string,
    value1: any,
    name2?: string,
    value2?: any,
    name3?: string,
    value3?: any,
    name4?: string,
    value4?: any,
    name5?: string,
    value5?: any
  ) {
    super();

    this.push(new RequestParam(name1, value1));

    if (name2) {
      this.push(new RequestParam(name2, value2));
    }

    if (name3) {
      this.push(new RequestParam(name3, value3));
    }

    if (name4) {
      this.push(new RequestParam(name4, value4));
    }

    if (name5) {
      this.push(new RequestParam(name5, value5));
    }
  }
}

export const httpGet = async <T>(
  action: string,
  requestParams: Array<RequestParam> = new Array<RequestParam>()
): Promise<T> => {
  const url = new URL(`${endpoint}/${action}`);
  const params = requestParams.map((x) => [x.name, x.value]);
  const accessToken = getAccessToken();

  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
};

export const httpDownload = async (
  action: string,
  requestParams: Array<RequestParam> = new Array<RequestParam>(),
  contentType: string
): Promise<Blob> => {
  const url = new URL(`${endpoint}/${action}`);
  const params = requestParams.map((x) => [x.name, x.value]);
  const accessToken = getAccessToken();

  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: "GET",
    headers: {
      Authorization: "Bearer " + accessToken,
      Accept: contentType,
      "Content-Type": contentType
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.blob();
  });
};

export const httpGetPage = <T>(
  action: string,
  continuationToken: string,
  pageSize: number = 20
): Promise<T> => {
  return httpGet<T>(
    action,
    new RequestParams(
      "continuationToken",
      continuationToken,
      "pageSize",
      pageSize
    )
  );
};

export const httpGetEntity = async <T>(
  action: string,
  id: string
): Promise<T> => {
  const url = new URL(`${endpoint}/${action}/${id}`);
  const accessToken = getAccessToken();

  return fetch(url.toString(), {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
};

export const httpPost = async <T>(
  action: string,
  requestParams: Array<RequestParam> = new Array<RequestParam>()
): Promise<T> => {
  const url = new URL(`${endpoint}/${action}`);
  const params = requestParams.map((x) => [x.name, x.value]);
  const accessToken = getAccessToken();

  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return response.json() as Promise<T>;
  });
};

export const httpSave = async <T>(
  action: string,
  entity: IEntityWithId
): Promise<T> => {
  let url = new URL(`${endpoint}/${action}`);
  const accessToken = getAccessToken();

  if (entity.id) {
    url = new URL(`${endpoint}/${action}/${entity.id}`);
  }

  return fetch(url.toString(), {
    method: entity.id ? "PUT" : "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
    body: JSON.stringify(entity),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
};

export const httpPut = async <T>(
  action: string,
  requestParams: RequestParams
): Promise<T> => {
  const url = new URL(`${endpoint}/${action}`);
  const params = requestParams.map((x) => [x.name, x.value]);
  const accessToken = getAccessToken();

  url.search = new URLSearchParams(params).toString();

  return fetch(url.toString(), {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  }).then((response) => {
    if (!response.ok) {
      throw new Error(response.statusText);
    }
    return response.json() as Promise<T>;
  });
};

export const httpDelete = async (
  action: string,
  id: string
): Promise<boolean> => {
  const url = new URL(`${endpoint}/${action}/${id}`);
  const accessToken = getAccessToken();

  return fetch(url.toString(), {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<string>;
    })
    .then((responseId) => {
      return responseId === id;
    });
};
