import * as React from 'react';
import { connect } from 'react-redux';
import * as PersonStore from './Person';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays'
import { CheckboxField } from '../components/CheckboxField';
import { TextField } from '../components/TextField';
import { FORM_ERROR } from 'final-form';
import { FormButtons } from '../components/FormButtons';
import { ItemTypeTreeForm } from '../components/ItemTypeTreeForm';

const UsedForCollection: React.FunctionComponent<{ readOnly: boolean }> = (props, meta) => (
  <div>
    <div className="card">
      <div className="card-header p-0 pl-3">
        <div className="input-group input-group-sm">
          <div className="input-group-prepend">
            <span className="input-group-text no-background no-border">Used for</span>
          </div>
        </div>
      </div>
    </div>

    <ItemTypeTreeForm readOnly={props.readOnly} fields="usedFor" />
  </div >
);

const validatePerson = (values) => {
  type PersonErrors = {
    fullName: string | undefined;
    emailAddress: string | undefined;
  }

  const errors: PersonErrors = {
    fullName: undefined,
    emailAddress: undefined
  };

  if (!values.fullName || values.fullName === '') {
    errors.fullName = 'Required';
  }

  if (values.emailAddress && !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.emailAddress)) {
    errors.emailAddress = 'Invalid';
  }

  return errors
}

interface PersonProps {
  readOnly: boolean;
  initialValues: PersonStore.PersonState | undefined;
  onSubmit?: (person: PersonStore.PersonState) => Promise<any>;
  onCancel?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
  onDelete?: () => void;
  onPrev?: () => void;
  onNext?: () => void;
}

export class PersonForm extends React.PureComponent<PersonProps> {
  public componentDidMount() {
  }

  public render() {
    const { readOnly, initialValues } = this.props;

    return (<>
      <p className="h4 pt-4">
        {readOnly
          ? `View person`
          : initialValues?.fullName
            ? `Edit person`
            : `New person`}
      </p>
      <hr />
      <Form
        onSubmit={async (values) => {
          if (!this.props.onSubmit) {
            return;
          }

          try {
            return await this.props.onSubmit(values as PersonStore.PersonState)
          } catch (err) {
            return { [FORM_ERROR]: `Could not save the changes: ${err.message}` };
          }
        }}
        keepDirtyOnReinitialize={true}
        mutators={{ ...arrayMutators }}
        initialValues={this.props.initialValues}
        validate={validatePerson}
        render={({ handleSubmit, form, submitting, pristine, hasSubmitErrors, submitError }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Field
                label="Is active"
                readOnly={readOnly}
                component={CheckboxField}
                type="input"
                name="isActive"
                id="isActive" />
            </div>

            <div className="mb-3">
              <Field
                label="Full name"
                readOnly={readOnly}
                component={TextField}
                name="fullName" />
            </div>

            <div className="mb-3">
              <Field
                label="Email address"
                readOnly={readOnly}
                component={TextField}
                name="emailAddress" />
            </div>

            <div className="mb-3">
              <Field
                label="Is sister"
                readOnly={readOnly}
                component={CheckboxField}
                type="input"
                name="isFemale"
                id="isFemale" />
            </div>

            <div className="mb-3">
              <Field
                label="Comments"
                readOnly={readOnly}
                component={TextField}
                name="comments" />
            </div>

            <div className="mb-3">
              <UsedForCollection readOnly={readOnly} />
            </div>

            <hr />

            {hasSubmitErrors && !readOnly && <span className="form-control-sm text-danger">{submitError}</span>}

            <FormButtons
              readOnly={readOnly}
              pristine={pristine}
              submitting={submitting}
              onCancel={this.props.onCancel}
              onEdit={this.props.onEdit}
              onClose={this.props.onClose}
              onDelete={this.props.onDelete}
              onPrev={this.props.onPrev}
              onNext={this.props.onNext}
            />

          </form>
        )} />
    </>
    );
  }
};

export default connect(
  null,
  PersonStore.actionCreators
)(PersonForm);