import React, { Component } from "react";
import "./App.css";
import { Route } from "react-router";
import Layout from "./components/Layout";
import Workbook from "./Workbooks/WorkbookComponent";
import ItemType from "./ItemTypes/ItemTypeComponent";
import ItemTypes from "./ItemTypes/ItemTypesComponent";
import Person from "./Persons/PersonComponent";
import Persons from "./Persons/PersonsComponent";
import User from "./Users/UserComponent";
import Users from "./Users/UsersComponent";
import ExternalSource from "./ExternalSources/ExternalSourceComponent";
import ExternalSources from "./ExternalSources/ExternalSourcesComponent";
import * as Authentication from "./Auth/Authentication";
import { connect } from "react-redux";
import { ApplicationState } from "./store";
import Footer from "./components/Footer";
import AppSettingsService from "./services/AppSettingsService";
import { ActiveType, MeetingType } from "./ItemTypes/ItemTypes";

const itemTypes = (
  props: any,
  meetingType: MeetingType,
  activeType: ActiveType
) => <ItemTypes {...props} meetingType={meetingType} activeType={activeType} />;

const persons = (props: any, activeType: ActiveType) => (
  <Persons {...props} activeType={activeType.toLocaleLowerCase()} />
);

const newMidweekItemType = (props: any) => (
  <ItemType {...props} readOnly={false} meetingType="midweek" />
);
const newWeekendItemType = (props: any) => (
  <ItemType {...props} readOnly={false} meetingType="weekend" />
);

const viewItemType = (props: any) => (
  <ItemType {...props} id={props.id} readOnly={true} />
);

const newPerson = (props: any) => <Person {...props} readOnly={false} />;
const viewPerson = (props: any) => (
  <Person {...props} id={props.id} readOnly={true} />
);

const newUser = (props: any) => <User {...props} readOnly={false} />;
const viewUser = (props: any) => (
  <User {...props} id={props.id} readOnly={true} />
);

const newExternalSource = (props: any) => (
  <ExternalSource {...props} readOnly={false} />
);
const viewExternalSource = (props: any) => (
  <ExternalSource {...props} id={props.id} readOnly={true} />
);

type AppProps = Authentication.Principal & typeof Authentication.actionCreators;

class App extends Component<AppProps> {
  async componentDidMount() {
    this.wakeApiServerUp();

    // window.addEventListener("beforeunload", async (ev) => {
    //   ev.preventDefault();
    //   ev.stopImmediatePropagation();

    //   if (!this.props.hubConnection) {
    //     return null;
    //   }

    //   await this.props.hubConnection.stop();

    //   return null;
    // });
  }

  wakeApiServerUp = () => {
    const endpoint = new AppSettingsService().GetWebApiBaseUri();
    const url = new URL(`${endpoint}/health/heartbeat`);

    fetch(url.toString(), {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      console.debug(response.json());
    });
  };

  render() {
    return (
      <>
        <Layout>
          {this.props.isAuthenticated && (
            <>
              <Route path="/schedule/:id?" component={Workbook} />

              <Route
                exact
                path="/itemtypes/midweek/active"
                render={() => itemTypes(this.props, "midweek", "active")}
              />

              <Route
                exact
                path="/itemtypes/midweek/inactive"
                render={() => itemTypes(this.props, "midweek", "inactive")}
              />

              <Route
                exact
                path="/itemtypes/midweek/all"
                render={() => itemTypes(this.props, "midweek", "all")}
              />

              <Route
                exact
                path="/itemtypes/weekend/active"
                render={() => itemTypes(this.props, "weekend", "active")}
              />

              <Route
                exact
                path="/itemtypes/weekend/inactive"
                render={() => itemTypes(this.props, "weekend", "inactive")}
              />

              <Route
                exact
                path="/itemtypes/weekend/all"
                render={() => itemTypes(this.props, "weekend", "all")}
              />

              <Route path="/itemtypes/:id/details" render={viewItemType} />

              {this.props.roles?.includes("Admin") && (
                <>
                  <Route
                    exact
                    path="/itemtypes/new-midweek/"
                    render={newMidweekItemType}
                  />
                  
                  <Route
                    exact
                    path="/itemtypes/new-weekend/"
                    render={newWeekendItemType}
                  />

                  <Route
                    exact
                    path="/externalsources"
                    component={ExternalSources}
                  />
                  <Route
                    exact
                    path="/externalsources/new/"
                    render={newExternalSource}
                  />
                  <Route
                    path="/externalsources/:id/details"
                    render={viewExternalSource}
                  />

                  <Route exact path="/users" component={Users} />
                  <Route exact path="/users/new/" render={newUser} />
                  <Route path="/users/:id/details" render={viewUser} />
                </>
              )}

              <Route exact path="/persons" component={Persons} />

              <Route
                exact
                path="/persons/active"
                render={() => persons(this.props, "active")}
              />

              <Route
                exact
                path="/persons/inactive"
                render={() => persons(this.props, "inactive")}
              />

              <Route
                exact
                path="/persons/all"
                render={() => persons(this.props, "all")}
              />

              <Route path="/persons/:id/details" render={viewPerson} />
              {this.props.roles?.includes("ScheduleWriter") && (
                <Route exact path="/persons/new/" render={newPerson} />
              )}
              <Footer />
            </>
          )}
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  displayName: state.principal?.displayName,
  email: state.principal?.email,
  isAuthenticated: state.principal?.isAuthenticated,
  hubConnection: state.principal?.hubConnection,
  roles: state.principal?.roles,
});

export default connect(
  mapStateToProps,
  Authentication.actionCreators
)(App as any);
