import React from "react";
import Popover from '@mui/material/Popover';

type Props = {
  id: string,
  isOpen: boolean,
  anchorEl: Element,
  onClose: () => void,
  children?: React.ReactNode,
};

export const PersonPickerPopover: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <Popover
      id={"pick-person-popover" + props.id?.toString()}
      open={props.isOpen}
      onClose={props.onClose}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}>
      {props.children}
    </Popover>
  );
};