import * as React from "react";
import { connect } from "react-redux";
import * as UserStore from "./User";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { CheckboxField } from "../components/CheckboxField";
import { TextField } from "../components/TextField";
import { FORM_ERROR } from "final-form";
import { FormButtons } from "../components/FormButtons";
import { ItemTypeTreeForm } from "../components/ItemTypeTreeForm";

const UserRolesCollection: React.FunctionComponent<{ readOnly: boolean }> = (
  props,
  meta
) => (
  <>
    <div className="mb-3">
      <div className="card">
        <div className="card-header p-0 pl-3">
          <div className="input-group input-group-sm">
            <div className="input-group-prepend">
              <span className="input-group-text no-background no-border">
                Roles
              </span>
            </div>
          </div>
        </div>
      </div>

      <ul className="list-group list-group-flush border-bottom border-right border-left pt-3">
        <FieldArray name="editableRoles">
          {({ fields }) =>
            fields.map((role, index) => {
              const name = fields.value[index]["name"];
              return (
                <li
                  className="list-group-item input-group-sm no-border pb-0 pt-0 mb-0"
                  key={index}
                >
                  <div className="input-group-prepend w-100 float-left mr-3">
                    <Field
                      label={name}
                      readOnly={props.readOnly}
                      component={CheckboxField}
                      name={`${role}.isSelected`}
                      type="input"
                      id={`${role}.isSelected`}
                    />
                  </div>
                </li>
              )
            })
          }
        </FieldArray>
      </ul>
    </div>
    <div>
      <div className="card">
        <div className="card-header p-0 pl-3">
          <div className="input-group input-group-sm">
            <div className="input-group-prepend">
              <span className="input-group-text no-background no-border">Allow to make assignments for</span>
            </div>
          </div>
        </div>
      </div>

      <ItemTypeTreeForm readOnly={props.readOnly} fields="scheduleWriterItemTypes" />
    </div >
  </>
);

const validateUser = (values) => {
  type UserErrors = {
    name: string | undefined;
    emailAddress: string | undefined;
  };

  const errors: UserErrors = {
    name: undefined,
    emailAddress: undefined,
  };

  if (!values.name || values.name === "") {
    errors.name = "Required";
  }

  if (
    values.emailAddress &&
    !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      values.emailAddress
    )
  ) {
    errors.emailAddress = "Invalid";
  }

  return errors;
};

interface UserProps {
  readOnly: boolean;
  initialValues: UserStore.UserState | undefined;
  onSubmit?: (user: UserStore.UserState) => Promise<any>;
  onCancel?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
  onDelete?: () => void;
}

export class UserForm extends React.PureComponent<UserProps> {
  public componentDidMount() { }

  public render() {
    const { readOnly } = this.props;

    type UserWithEditableRoles = typeof this.props.initialValues & {
      editableRoles?: { name: UserStore.UserRole; isSelected: boolean }[];
    };

    let initialValues: UserWithEditableRoles | undefined =
      this.props.initialValues;

    if (initialValues) {
      initialValues.editableRoles = [];
      Object.keys(UserStore.UserRoles).forEach((value) => {
        initialValues!.editableRoles!.push({
          name: value as UserStore.UserRole,
          isSelected:
            initialValues?.roles.includes(value as UserStore.UserRole) ?? false,
        });
      });
    }

    return (
      <>
        <p className="h4 pt-4">{readOnly ? "Edit user" : "View user"}</p>
        <hr />
        <Form
          onSubmit={async (values) => {
            if (!this.props.onSubmit) {
              return;
            }

            try {
              let user = values as UserWithEditableRoles;

              user.roles = [];
              user.editableRoles!.forEach((editableRole) => {
                if (!editableRole.isSelected) {
                  return;
                }

                user.roles.push(editableRole.name);
              });

              return await this.props.onSubmit(user);
            } catch (err) {
              return {
                [FORM_ERROR]: `Could not save the changes: ${err.message}`,
              };
            }
          }}
          keepDirtyOnReinitialize={true}
          mutators={{ ...arrayMutators }}
          initialValues={initialValues}
          validate={validateUser}
          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            hasSubmitErrors,
            submitError,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <Field
                  label="Is active"
                  readOnly={readOnly}
                  component={CheckboxField}
                  type="input"
                  name="isActive"
                  id="isActive"
                />
              </div>

              <div className="mb-3">
                <Field
                  label="Email address"
                  readOnly={
                    readOnly || !!this.props.initialValues?.emailAddress
                  }
                  component={TextField}
                  name="emailAddress"
                />
              </div>

              <div className="mb-3">
                <Field
                  label="Name"
                  readOnly={readOnly}
                  component={TextField}
                  name="name"
                />
              </div>

              <div className="mb-3">
                <UserRolesCollection readOnly={readOnly} />
              </div>

              <hr />

              {hasSubmitErrors && !readOnly && (
                <span className="form-control-sm text-danger">
                  {submitError}
                </span>
              )}

              <FormButtons
                readOnly={readOnly}
                pristine={pristine}
                submitting={submitting}
                onCancel={this.props.onCancel}
                onEdit={this.props.onEdit}
                onClose={this.props.onClose}
                onDelete={this.props.onDelete}
              />
            </form>
          )}
        />
      </>
    );
  }
}

export default connect(null, UserStore.actionCreators)(UserForm);
