import React, { useState } from "react";
import Popover from "@mui/material/Popover";

export const DeleteButton: React.FunctionComponent<{ onDelete: () => void }> = (
  props
) => {
  const [anchorEl, setAnchorEl] = useState(undefined);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteItemOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsDeleting(false);
  };

  const handleDeleteItemClose = () => {
    setAnchorEl(undefined);
    setIsDeleting(false);
  };

  const handleDeleteItem = async () => {
    setIsDeleting(true);
    props.onDelete();
    handleDeleteItemClose();
    setIsDeleting(false);
  };

  const handleDeleteKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      handleDeleteItem();
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === "Escape") {
      handleDeleteItemClose();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-danger btn-sm ml-3"
        onClick={handleDeleteItemOpen}
      >
        Delete
      </button>
      <Popover
        id={"delete-popover"}
        open={Boolean(anchorEl)}
        onClose={handleDeleteItemClose}
        onKeyDown={(e) => handleDeleteKeyDown(e)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="m-3">
          <button
            type="button"
            autoFocus={true}
            className="btn btn-danger"
            disabled={isDeleting}
            onClick={() => handleDeleteItem()}
          >
            Delete
          </button>
        </div>
      </Popover>
    </>
  );
};
