import { httpGet } from "../../store/HttpUtils";

export type GetRevisionsResponse<T> = {
  queryKey: string[];
  queryFn: () => Promise<T>;
};

export type WorkbookRevisionsDto = {
  workbookId: string;
  midweekHeadRevision: string;
  weekendHeadRevision: string;
  midweekPublishedRevision: string;
  weekendPublishedRevision: string;
};

export function getRevisionsQuery(
  workbookId: string
): GetRevisionsResponse<WorkbookRevisionsDto> {
  return {
    queryKey: ["workbookRevisions", workbookId],
    queryFn: async () => await httpGet<WorkbookRevisionsDto>(`Workbooks/${workbookId}/Revisions`),
  };
}

export const RevisionsApi = {
  workbookRevisionsQueryKeys: (workbookId: string) => ["workbookRevisions", workbookId],
  workbookRevisionsQueryInvoke: async (workbookId: string): Promise<WorkbookRevisionsDto> => await httpGet<WorkbookRevisionsDto>(`Workbooks/${workbookId}/Revisions`)
};
