import * as React from "react";
import "react-tabs/style/react-tabs.css";
import { BiError } from "react-icons/bi";
import PersonPickerButton from "./PersonPickerButton";
import { useState } from "react";

type PersonPickerProps = {
  editMode: boolean;
  personId: string | null;
  personFullName: string;
  itemTypeId: string;
  handledById: string;
  assignmentType: string;
  externalSourceMappingId: string;
  hideLabel: boolean;
  date: Date;
  onPickPerson: (personId: string | null, personFullName: string) => void;
  onClearPerson: () => void;
};

export const PersonPicker: React.FunctionComponent<PersonPickerProps> = (
  props: PersonPickerProps
) => {
  const personPickerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingRight: "2px",
    cursor: "default",
    // border: "2px white",
    // borderRadius: "4px",
    // marginTop: "0px",
    // marginLeft: "0px"
  };

  const [personFullName, setPersonFullName] = useState(props.personFullName);

  if (props.editMode) {
    if (props.externalSourceMappingId) {
      return (
        <div
          key={props.assignmentType + props.personFullName}
          style={personPickerStyle}
        >
          <span
            className="font-size-small no-border no-background float-left"
            style={{}}
          >
            <input
              type="text"
              style={{
                marginTop: "0px",
                marginBottom: "1px",
                paddingBottom: "2px",
                // width: "100%",
              }}
              className="font-size-small no-border"
              data-toggle="tooltip"
              title={props.assignmentType}
              value={personFullName ?? ""}
              onChange={(e) => setPersonFullName(e.target.value)}
              onBlur={(e) => props.onPickPerson(null, personFullName)}
            />

            {!props.personFullName && (
              <BiError
                style={{ marginTop: "-3px", marginRight: "5px" }}
                className="ml-1"
                size="16"
                color="#ffc107"
              />
            )}
          </span>
        </div>
      );
    }
    return (
      <div style={personPickerStyle} className="hoverable">
        <span
          className="font-size-small no-border no-background float-left"
          style={{}}
        >
          <span>
            {props.hideLabel ? "" : props.assignmentType + ": "}{" "}
            {props.personFullName}
          </span>
          {!props.personFullName && (
            <BiError
              style={{ marginTop: "-3px", marginRight: "5px" }}
              className="ml-1"
              size="16"
              color="#ffc107"
            />
          )}
        </span>
        <PersonPickerButton
          personId={props.personId}
          personFullName={props.personFullName}
          itemTypeId={props.itemTypeId}
          handledById={props.handledById}
          date={props.date}
          onPickPerson={props.onPickPerson}
          onClearPerson={props.onClearPerson}
        />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <span
          key={props.assignmentType + props.personFullName}
          className="font-size-small no-border no-background float-left"
          style={{}}
        >
          {props.hideLabel ? "" : props.assignmentType + ": "}
          {props.personFullName}
          {!props.personFullName && (
            <BiError
              className="ml-1"
              style={{ marginTop: "-3px", marginRight: "5px" }}
              size="16"
              color="#ffc107"
            />
          )}
        </span>
      </React.Fragment>
    );
  }
};
