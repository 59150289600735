class AppSettingsService {
  GetWebApiBaseUri(): string {
    return process.env.REACT_APP_WEB_API_BASE_URI as string;
  }
  GetLoginRedirectUri(): string {
    return process.env.REACT_APP_LOGIN_REDIRECT_URI as string;
  }
  GetSignalRHubUri(): string {
    return process.env.REACT_APP_WEB_SIGNALR_HUB_URI as string;
  }
  GetAuthClientId(): string {
    return process.env.REACT_APP_AUTH_CLIENT_ID as string;
  }
  GetAuthClientSecret(): string {
    return process.env.REACT_APP_AUTH_CLIENT_SECRET as string;
  }
  GetLoginAuthEndpoint(): string {
    return process.env.REACT_APP_LOGIN_ENDPOINT as string;
  }
  GetReactAppRedirectUri(): string {
    return process.env.REACT_APP_REDIRECT_URI as string;
  }
}

export default AppSettingsService;