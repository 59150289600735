import { Action, Reducer } from 'redux';

export interface AppErrorAction { type: 'APP_ERROR', errorMessage: string }
export interface AppExecutingAction { type: 'APP_EXECUTING', executingAction?: string }

export interface AppState {
  errorMessage?: string;
  executingAction?: string
}

export type KnownAction = AppErrorAction | AppExecutingAction;

export const reducer: Reducer<AppState> = (state: AppState | undefined, incomingAction: Action): AppState => {
  const unloadedState: AppState = {};

  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'APP_ERROR':
      return {
        errorMessage: action.errorMessage,
        executingAction: undefined
      };
    case 'APP_EXECUTING':
      return {
        errorMessage: undefined,
        executingAction: action.executingAction
      };
  }

  return state;
};