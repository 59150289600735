import React, { useState } from "react";
import { BiHistory } from "react-icons/bi";
import { connect } from "react-redux";
import { ImpulseSpinner } from "react-spinners-kit";
import { RenderItemHistoryList } from "./RenderItemHistoryList";
import { ApplicationState } from "../store";
import * as PersonPickerStoreState from "./PersonPickerStore";
import { PersonItemHistoryPopover } from "./PersonItemHistoryPopover";

type DispatchProps = {
  selectPrev: () => void;
  selectNext: () => void;
  loadItemHistory: (personId: string, date: Date, restDays: number) => void;
};

type Props = {
  handledById: string;
  date: Date;
  anchorEl?: Element | undefined;
  onPersonSelect: (personId: string, personFullName: string) => void;
  onClose: () => void;
  children?: React.ReactNode;
} & DispatchProps &
  PersonPickerStoreState.PersonFors;

const PersonPickerPopoverList: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [historyAnchorEl, setHistoryAnchorEl] = useState(null);
  const [historyPersonId, setHistoryPersonId] = useState("");
  const [historyPersonFullName, setHistoryPersonFullName] = useState("");

  const getRestPeriod = (restDays: string) => {
    let days = parseInt(restDays);

    if (days === 6) {
      days = 7;
    }

    if (days === 0) {
      return "same meeting";
    }

    if (days < 6) {
      return "same week";
    }

    const restWeeks = Math.round(days / 7);

    return restWeeks === 1 ? `${restWeeks} week` : `${restWeeks} weeks`;
  };

  const handlePickPersonHistoryOpen = async (
    event: any,
    personId: string,
    personFullName: string,
    restDays: string
  ) => {

    props.loadItemHistory(personId, props.date, +restDays);
    setHistoryAnchorEl(event.currentTarget);
    setHistoryPersonId(personId);
    setHistoryPersonFullName(personFullName);

    event.stopPropagation();
    event.preventDefault();
  };

  const handlePickPersonHistoryClose = () => {
    setHistoryAnchorEl(null);
    setHistoryPersonId("");
    setHistoryPersonFullName("");
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === "ArrowUp") {
      props.selectPrev();
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === "ArrowDown") {
      props.selectNext();
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === "Enter") {
      props.values?.forEach((v) => {
        if (v.isSelected) {
          props.onPersonSelect(v.id, v.fullName);
          e.preventDefault();
          e.stopPropagation();
          return;
        }
      });
    }

    if (e.key === "Escape") {
      props.onClose();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <>
      <table
        className="table table-hover table-sm border"
        onKeyDown={(e) => handleKeyDown(e)}
      >
        <tbody>
          {props.values.map((pf) => (
            <tr
              key={pf.id}
              onClick={() => props.onPersonSelect(pf.id, pf.fullName)}
              className={`${pf.isSelected ? "table-active" : ""} ${parseInt(pf.restDays) < 1 && !pf.wasDutyItem
                  ? "bg-danger"
                  : parseInt(pf.restDays) < 7 || pf.wasDutyItem
                    ? "bg-warning"
                    : ""
                }`}
              style={{ cursor: "pointer" }}
            >
              <td className="pl-3">
                {pf.fullName}
                {pf.comments && (
                  <span className="font-size-extra-extra-small">
                    <br />
                    {pf.comments}
                  </span>
                )}
              </td>
              <td className="pl-3 pr-3">{getRestPeriod(pf.restDays)}</td>
              <td className="pl-3 pr-3">
                <button
                  className="no-border no-background"
                  style={{ cursor: "help" }}
                  onClick={(e) =>
                    handlePickPersonHistoryOpen(e, pf.id, pf.fullName, pf.restDays)
                  }
                  data-toggle="tooltip"
                  title="Assignment history"
                >
                  <BiHistory />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <PersonItemHistoryPopover
        id={props.handledById}
        isOpen={Boolean(historyAnchorEl)}
        anchorEl={historyAnchorEl}
        onClose={handlePickPersonHistoryClose}
      >
        {props.isLoadingHistory ? (
          <ImpulseSpinner />
        ) : (
          <RenderItemHistoryList
            personId={historyPersonId}
            personName={historyPersonFullName}
            onClose={handlePickPersonHistoryClose}
            history={props.history}
            isLoadingHistory={props.isLoadingHistory}
          />
        )}
      </PersonItemHistoryPopover>
    </>
  );
};

interface DispatchFromProps {
  selectPrev: () => void;
  selectNext: () => void;
  loadItemHistory: (personId: string, date: Date, restDays: number) => void;
}

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  selectPrev: () =>
    dispatch(PersonPickerStoreState.actionCreators.selectPrev()),
  selectNext: () =>
    dispatch(PersonPickerStoreState.actionCreators.selectNext()),
  loadItemHistory: (personId: string, date: Date, restDays: number) =>
    dispatch(
      PersonPickerStoreState.actionCreators.loadItemHistory(personId, date, restDays + 31)
    ),
});

export default connect(
  (state: ApplicationState) => state.personFors,
  mapDispatchToProps
)(PersonPickerPopoverList);
