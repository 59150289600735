import * as React from "react";
import { WorkbookWeekItemState } from "./ItemStore";
import ItemHandleBy from "./ItemHandledBy";
import ItemMetadata from "./ItemMetadata";
import ItemDescription from "./ItemDescription";
import ItemDuration from "./ItemDuration";

type Props = {
  editMode: boolean;
  item: WorkbookWeekItemState;
  defaultHandledBy: string;
  canEdit: boolean;
  canEditItem: (itemTypeId: string, handledById: string) => boolean
};

export const Item: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <React.Fragment>
      <div
        className="float-left pr-1"
        style={{
          borderRight: "1px solid rgba(0, 0, 0, 0.125)",
          width: "50%",
          maxWidth: "400px",
          display: "flex",
          // alignItems: "center",
          flexDirection: "column"
        }}
      >

        <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", width: "100%" }}>
          <ItemDescription editMode={props.editMode && props.canEdit} item={props.item} />
          <ItemDuration editMode={props.editMode && props.canEdit} item={props.item} />
        </div>

        <div style={{ marginLeft: "15px" }}>
          <ItemMetadata
            editMode={props.editMode && props.canEdit}
            workbookId={props.item.workbookId}
            workbookWeekId={props.item.workbookWeekId}
            itemId={props.item.id}
            metadata={props.item.metadata}
          />
        </div>

      </div>

      <ItemHandleBy
        key={props.item.id + props.item.description}
        editMode={props.editMode}
        defaultHandledBy={props.defaultHandledBy}
        workbookId={props.item.workbookId}
        workbookWeekId={props.item.workbookWeekId}
        itemId={props.item.id}
        itemTypeId={props.item.itemTypeId}
        itemDate={props.item.date}
        handledBy={props.item.handledBy}
        canEditItem={props.canEditItem}
      />

    </React.Fragment >
  );
};
