import { AppThunkAction } from "../store";
import { Action, Reducer } from "redux";
import { IEntityWithId } from "../store/IEntityWithId";
import { ItemTypesState, MeetingType } from "./ItemTypes";
import { AppErrorAction, AppExecutingAction } from "../store/AppActions";
import { httpDelete, httpGetEntity, httpSave } from "../store/HttpUtils";

export interface ResetItemTypeRequestedAction {
  type: "RESET_ITEMTYPE_REQUESTED";
  meetingType: string;
}
export interface GetItemTypeRequestedAction {
  type: "GET_ITEMTYPE_REQUESTED";
  id: string;
}
export interface GetItemTypeResponseAction {
  type: "GET_ITEMTYPE_RESPONSE";
  data: ItemTypeState;
}
export interface GetItemTypePageRequestedAction {
  type: "GET_ITEMTYPE_PAGE_REQUESTED";
  continuationToken: string;
  pageSize: number;
}
export interface GetItemTypePageResponseAction {
  type: "GET_ITEMTYPE_PAGE_RESPONSE";
  data: ItemTypesState;
  meetingType: MeetingType;
}
export interface ItemTypeSearchRequestedAction {
  type: "ITEMTYPE_SEARCH_REQUESTED";
  term: string;
}
export interface ItemTypeSelectedResponseAction {
  type: "ITEMTYPE_SELECTED_RESPONSE";
  data: ItemTypesState;
  meetingType: MeetingType;
}
export interface SaveRequestedAction {
  type: "SAVE_ITEMTYPE_REQUESTED";
}
export interface SaveResponseAction {
  type: "SAVE_ITEMTYPE_RESPONSE";
  errorMessage?: string;
}

export interface ItemTypeState extends IEntityWithId {
  id: string;
  description: string;
  showInHeader: boolean;
  hideFromSchedule: boolean;
  hasStudentAssignmentSlip: boolean;
  chairmanAdditionalTimeMinutes?: boolean;
  durationMinutes?: string;
  section?: string;
  index: number;
  autoAdd?: number;
  isActive: boolean;
  isDutyItem: boolean;
  regexMask?: string;
  handledBy: HandledByState[];
  metadataDefinition: MetadataDefinitionState[];
  isLoading?: boolean;
  isSaving?: boolean;
  savingSucceeded?: boolean;
  meetingType: string;
  remindInAnnouncements?: boolean;
  externalSourceMappingId?: string;
}

export interface HandledByState {
  assignmentType: string;
  hideLabel: boolean;
  externalSourceMappingId?: string;
}

export interface MetadataDefinitionState {
  key: string;
  regexMask: string;
  externalSourceMappingId?: string;
}

export type KnownAction =
  | AppExecutingAction
  | AppErrorAction
  | ResetItemTypeRequestedAction
  | GetItemTypeRequestedAction
  | GetItemTypeResponseAction
  | SaveRequestedAction
  | SaveResponseAction;

export const actionCreators = {
  reset:
    (meetingType: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "RESET_ITEMTYPE_REQUESTED", meetingType: meetingType });
    },

  save:
    (entity: ItemTypeState): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "SAVE_ITEMTYPE_REQUESTED" });
      dispatch({ type: "APP_EXECUTING", executingAction: "Saving" });
      return httpSave<string>(`itemtypes`, entity)
        .then(() => {
          dispatch({ type: "SAVE_ITEMTYPE_RESPONSE", errorMessage: undefined });
        })
        .finally(() => {
          dispatch({ type: "APP_EXECUTING", executingAction: undefined });
        });
    },

  get:
    (id: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "GET_ITEMTYPE_REQUESTED", id });

      return httpGetEntity<ItemTypeState>("itemtypes", id).then((data) => {
        dispatch({ type: "GET_ITEMTYPE_RESPONSE", data: data });
        return data;
      });
    },

  delete:
    (id: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "APP_EXECUTING", executingAction: "Deleting" });
      return httpDelete(`itemtypes`, id).finally(() => {
        dispatch({ type: "APP_EXECUTING", executingAction: undefined });
      });
    },
};

const unloadedState: ItemTypeState = {
  id: "",
  description: "",
  showInHeader: false,
  hideFromSchedule: false,
  hasStudentAssignmentSlip: false,
  index: 0,
  isActive: true,
  isDutyItem: false,
  handledBy: [],
  metadataDefinition: [],
  meetingType: "",
};

export const reducer: Reducer<ItemTypeState> = (
  state: ItemTypeState | undefined,
  incomingAction: Action
): ItemTypeState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "RESET_ITEMTYPE_REQUESTED":
      return {
        ...unloadedState,
        meetingType: action.meetingType,
      };
    case "GET_ITEMTYPE_REQUESTED":
      return {
        ...state,
        id: action.id,
        isLoading: true,
      };

    case "GET_ITEMTYPE_RESPONSE":
      if (state.id === action.data.id) {
        return {
          ...action.data,
          isLoading: false,
        };
      }
      break;
    case "SAVE_ITEMTYPE_REQUESTED":
      return {
        ...state,
        isSaving: true,
      };
    case "SAVE_ITEMTYPE_RESPONSE":
      return {
        ...state,
        isSaving: false,
        savingSucceeded: action.errorMessage ? false : true,
      };
  }

  return state;
};
