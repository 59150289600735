import { Action, Reducer } from "redux";
import { UsersState } from "./Users";
import { IEntityWithId } from "../store/IEntityWithId";
import { GetItemTypePageResponseAction } from "../ItemTypes/ItemType";
import { AppThunkAction } from "../store";
import { AppExecutingAction, AppErrorAction } from "../store/AppActions";
import { httpSave, httpGetEntity, httpDelete, httpGet, RequestParam } from "../store/HttpUtils";
import { ItemTypeUsageRecordState } from "../ItemTypes/ItemTypeUsageRecordState";

export interface ResetRequestedAction {
  type: "RESET_USER_REQUESTED";
  data: UserState;
}
export interface GetRequestedAction {
  type: "GET_USER_REQUESTED";
  id: string;
}
export interface GetResponseAction {
  type: "GET_USER_RESPONSE";
  data: UserState;
}
export interface GetPageRequestedAction {
  type: "GET_USER_PAGE_REQUESTED";
  continuationToken: string;
  pageSize: number;
}
export interface GetPageResponseAction {
  type: "GET_USER_PAGE_RESPONSE";
  data: UsersState;
}
export interface SaveRequestedAction {
  type: "SAVE_USER_REQUESTED";
}
export interface SaveResponseAction {
  type: "SAVE_USER_RESPONSE";
  errorMessage?: string;
}

export interface UserState extends IEntityWithId {
  id: string;
  name: string;
  emailAddress: string;
  profilePicturePath?: string;
  isActive: boolean;
  roles: UserRole[];
  scheduleWriterItemTypes: ItemTypeUsageRecordState[]
  isLoading?: boolean; //TODO: extract these 3 into an interface
  isSaving?: boolean;
  savingSucceeded?: boolean;
}

export enum UserRoles {
  ScheduleReader = "ScheduleReader",
  ScheduleWriter = "ScheduleWriter",
  Admin = "Admin",
}

export type UserRole = "ScheduleReader" | "ScheduleWriter" | "Admin" | `ScheduleWriter:${string}:${string}`;

export type KnownAction =
  | AppExecutingAction
  | AppErrorAction
  | ResetRequestedAction
  | GetRequestedAction
  | GetResponseAction
  | GetItemTypePageResponseAction
  | SaveRequestedAction
  | SaveResponseAction;

export const actionCreators = {
  reset: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    return httpGet<UserState>(`users/new`, new Array<RequestParam>()).then(data => {
      dispatch({ type: 'RESET_USER_REQUESTED', data: data });
      return data;
    });
  },

  save:
    (entity: UserState): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        dispatch({ type: "SAVE_USER_REQUESTED" });
        dispatch({ type: "APP_EXECUTING", executingAction: "Saving" });
        return httpSave<string>(`users`, entity)
          .then(() => {
            dispatch({ type: "SAVE_USER_RESPONSE", errorMessage: undefined });
          })
          .finally(() => {
            dispatch({ type: "APP_EXECUTING", executingAction: undefined });
          });
      },

  get:
    (id: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        dispatch({ type: "GET_USER_REQUESTED", id });

        return httpGetEntity<UserState>("users", id).then((data) => {
          dispatch({ type: "GET_USER_RESPONSE", data: data });
          return data;
        });
      },

  delete:
    (id: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        dispatch({ type: "APP_EXECUTING", executingAction: "Deleting" });
        return httpDelete(`users`, id).finally(() => {
          dispatch({ type: "APP_EXECUTING", executingAction: undefined });
        });
      },
};

export const reducer: Reducer<UserState> = (
  state: UserState | undefined,
  incomingAction: Action
): UserState => {
  const unloadedState: UserState = {
    id: "",
    name: "",
    emailAddress: "",
    profilePicturePath: undefined,
    roles: [],
    scheduleWriterItemTypes: [],
    isActive: true,
  };

  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "RESET_USER_REQUESTED":
      return {
        ...action.data,
      };
    case "GET_USER_REQUESTED":
      return {
        ...state,
        id: action.id,
        isLoading: true,
      };
    case "GET_USER_RESPONSE":
      return {
        ...action.data,
        isLoading: false,
      };
    case "SAVE_USER_REQUESTED":
      return {
        ...state,
        isSaving: true,
      };
    case "SAVE_USER_RESPONSE":
      return {
        ...state,
        isSaving: false,
        savingSucceeded: action.errorMessage ? false : true,
      };
  }

  return state;
};
