import { Action, Reducer } from "redux";
import { ApplicationState, AppThunkAction } from "../store";
import { httpGet, RequestParams } from "../store/HttpUtils";
import { PageResultState } from "../store/PageResultState";
import {
  GetItemTypePageRequestedAction,
  GetItemTypePageResponseAction,
  ItemTypeSelectedResponseAction,
} from "./ItemType";

export type MeetingType = "midweek" | "weekend";
export type ActiveType = "active" | "inactive" | "all";
export interface ItemTypeState {
  id: string;
  description: string;
  section?: string;
  hideFromSchedule: boolean;
  index: number;
  isActive: boolean;
  isSelected: boolean;
}

export interface ItemTypesState extends PageResultState<ItemTypeState> {}

export type KnownAction =
  | GetItemTypePageRequestedAction
  | GetItemTypePageResponseAction
  | ItemTypeSelectedResponseAction;

export const actionCreators = {
  get:
    (
      meetingType: MeetingType,
      activeType: ActiveType,
      searchTerm: string | "",
      continuationToken: string | "",
      pageSize: number
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({
        type: "GET_ITEMTYPE_PAGE_REQUESTED",
        continuationToken,
        pageSize,
      });

      return httpGet<ItemTypesState>(
        "ItemTypes",
        new RequestParams(
          "meetingType",
          meetingType,
          "activeType",
          activeType,
          "searchTerm",
          searchTerm,
          "continuationToken",
          continuationToken,
          "pageSize",
          pageSize
        )
      ).then((data) => {
        dispatch({
          type: "GET_ITEMTYPE_PAGE_RESPONSE",
          data: data,
          meetingType: meetingType,
        });
        return data;
      });
    },
  selectNext:
    (meetingType: MeetingType): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const state = getState() as ApplicationState;

      let itemTypesState: ItemTypesState | undefined;
      switch (meetingType) {
        case "midweek":
          itemTypesState = state.midweekItemTypes;
          break;
        case "weekend":
          itemTypesState = state.weekendItemTypes;
          break;
        default:
          throw new Error(`${meetingType} not supported.`);
      }

      if (!itemTypesState) {
        return;
      }

      let itemTypes = [...itemTypesState.values];

      const selectedItemTypeIndex = itemTypes.findIndex((x) => x.isSelected);

      if (itemTypes.length > selectedItemTypeIndex + 1) {
        if (selectedItemTypeIndex >= 0) {
          itemTypes[selectedItemTypeIndex].isSelected = false;
        }

        itemTypes[selectedItemTypeIndex + 1].isSelected = true;
      }

      dispatch({
        type: "ITEMTYPE_SELECTED_RESPONSE",
        data: {
          continuationToken: itemTypesState.continuationToken,
          hasMoreResults: itemTypesState.hasMoreResults,
          isLoading: itemTypesState.isLoading,
          pageNumber: itemTypesState.pageNumber,
          sourceContinuationToken: itemTypesState.sourceContinuationToken,
          tokens: itemTypesState.tokens,
          values: itemTypes,
        },
        meetingType: meetingType,
      });
    },
  selectPrev:
    (meetingType: MeetingType): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const state = getState() as ApplicationState;
      let itemTypesState: ItemTypesState | undefined;
      switch (meetingType) {
        case "midweek":
          itemTypesState = state.midweekItemTypes;
          break;
        case "weekend":
          itemTypesState = state.weekendItemTypes;
          break;
        default:
          throw new Error(`${meetingType} not supported.`);
      }

      if (!itemTypesState) {
        return;
      }

      let itemTypes = [...itemTypesState.values];

      const selectedItemTypeIndex = itemTypes.findIndex((x) => x.isSelected);

      if (selectedItemTypeIndex <= 0) {
        return;
      }

      itemTypes[selectedItemTypeIndex].isSelected = false;

      if (selectedItemTypeIndex > 0) {
        itemTypes[selectedItemTypeIndex - 1].isSelected = true;
      }

      dispatch({
        type: "ITEMTYPE_SELECTED_RESPONSE",
        data: {
          continuationToken: itemTypesState.continuationToken,
          hasMoreResults: itemTypesState.hasMoreResults,
          isLoading: itemTypesState.isLoading,
          pageNumber: itemTypesState.pageNumber,
          sourceContinuationToken: itemTypesState.sourceContinuationToken,
          tokens: itemTypesState.tokens,
          values: itemTypes,
        },
        meetingType: meetingType,
      });
    },
};

const unloadedState: ItemTypesState = {
  values: [],
  isLoading: false,
  hasMoreResults: false,
  pageNumber: -1,
  tokens: [],
  continuationToken: "",
  sourceContinuationToken: "",
};

export const reducer: Reducer<ItemTypesState> = (
  state: ItemTypesState | undefined,
  incomingAction: Action
): ItemTypesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_ITEMTYPE_PAGE_REQUESTED":
      return {
        ...state,
        continuationToken: action.continuationToken,
        isLoading: true,
      };
    case "GET_ITEMTYPE_PAGE_RESPONSE":
      if (
        state.continuationToken === action.data.sourceContinuationToken ||
        !action.data.sourceContinuationToken
      ) {
        const tokenIndex = state.tokens.indexOf(
          action.data.sourceContinuationToken
        );
        const pageNumber = tokenIndex >= 0 ? tokenIndex : state.tokens.length;

        let tokens = state.tokens;
        if (tokenIndex === -1) {
          tokens = tokens.concat([action.data.sourceContinuationToken]);
        }

        return {
          ...state,
          continuationToken: action.data.continuationToken,
          values: action.data.values,
          hasMoreResults: action.data.hasMoreResults,
          pageNumber: pageNumber,
          tokens: tokens,
          isLoading: false,
        };
      }
      break;

    case "ITEMTYPE_SELECTED_RESPONSE":
      if (
        state.continuationToken === action.data.sourceContinuationToken ||
        !action.data.sourceContinuationToken
      ) {
        return {
          ...state,
          values: action.data.values,
          isLoading: false,
        };
      }
      break;
  }

  return state;
};
