import React, { } from "react";
import { ItemHistory } from "./PersonPickerStore";

type Props = {
  personId: string;
  personName: string;
  history: Array<ItemHistory>;
  isLoadingHistory: boolean;
  onClose: () => void;
};

export const RenderItemHistoryList: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className="m-3">
      <div className="no-border font-weight-bold mb-3">Recent assignments for {props.personName}</div>
      <table className="table table-hover table-striped table-sm no-border">
        <tbody>
          {props.history.map(historyLine => (
            <tr key={historyLine.itemDescription + historyLine.assignmentDate}>
              <td>
                {historyLine.itemDescription}
                <span className="text-muted"> ({historyLine.assignmentType})</span>
                <br />
                {historyLine.otherHandledByPerson && <span className="text-muted font-size-extra-small">{historyLine.otherHandledByPerson} ({historyLine.otherAssignmentType})</span>}
              </td>
              <td>{historyLine.assignmentDate}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <button className="btn btn-secondary btn-sm" onClick={props.onClose}>Close</button>
    </div>
  );
};