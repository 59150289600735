import * as React from "react";
import { connect } from "react-redux";
import { ItemHandledByStore, actionCreators } from "./ItemStore";
import { PersonPicker } from "../PersonPicker/PersonPickerComponent";

type Props = {
  editMode: boolean;
  workbookId: string;
  workbookWeekId: string;
  itemId: string;
  itemTypeId: string;
  itemDate: Date;
  handledBy: Array<ItemHandledByStore>;
  defaultHandledBy: string;
  canEditItem: (itemTypeId: string, handledById: string) => boolean
};

interface State {
  handledBy: Array<ItemHandledByStore>;
}

class ItemHandledBy extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      handledBy: this.props.handledBy.map(
        (h) =>
        ({
          assignmentType: h.assignmentType,
          handledById: h.handledById,
          hideLabel: h.hideLabel,
          personId: h.personId,
          personFullName: h.personFullName,
          externalSourceMappingId: h.externalSourceMappingId,
        } as ItemHandledByStore)
      ),
    };
  }

  async handleUpdates(e: any) {
    this.state.handledBy.forEach((h) => {
      const updatedHandledById = h.handledById;
      const updatedPersonFullName = h.personFullName;
      const updatedPersonId = h.personId;
      const initialHandledBy = this.props.handledBy.find(
        (x) => x.handledById === h.handledById
      );

      if (initialHandledBy) {
        if (
          updatedPersonFullName !== initialHandledBy.personFullName ||
          updatedPersonId !== initialHandledBy.personId
        ) {
          if (!updatedPersonId && !updatedPersonFullName) {
            (this.props as any)
              .clearHandledBy(
                this.props.workbookId,
                this.props.workbookWeekId,
                this.props.itemId,
                updatedHandledById
              )
              .then((success: boolean) => {
                if (success) {
                  initialHandledBy.personFullName = updatedPersonFullName;
                  initialHandledBy.personId = updatedPersonId;
                } else {
                  e?.target?.focus();
                }
              });
          } else {
            (this.props as any)
              .updateHandledBy(
                this.props.workbookId,
                this.props.workbookWeekId,
                this.props.itemId,
                updatedHandledById,
                updatedPersonId,
                updatedPersonFullName
              )
              .then((success: boolean) => {
                if (success) {
                  initialHandledBy.personFullName = updatedPersonFullName;
                  initialHandledBy.personId = updatedPersonId;
                } else {
                  e?.target?.focus();
                }
              });
          }
        }
      }
    });
  }

  public render() {
    const handlePickPerson = (
      handledById: string,
      personId: string | null,
      personFullName: string
    ) => {
      let handledBy = [...this.state.handledBy].filter(
        (x) => x.handledById === handledById
      )[0];

      if (personId) {
        handledBy.personId = personId;
      }

      handledBy.personFullName = personFullName;

      this.setState({ handledBy: [...this.state.handledBy] }, () =>
        this.handleUpdates(undefined)
      );
    };

    const handleClearPerson = (handledById: string) => {
      let handledBy = this.state.handledBy.filter(
        (x) => x.handledById === handledById
      )[0];

      handledBy.personId = "";
      handledBy.personFullName = "";

      this.setState({ handledBy: [...this.state.handledBy] }, () =>
        this.handleUpdates(undefined)
      );
    };

    if (this.state.handledBy?.length === 0) {
      return null;
      // return (
      //   <div key={this.props.itemId + "default-handled-by"} style={{ paddingTop: "2px" }} className="float-left ml-2 mr-4 ">
      //     <span className="font-size-small no-border no-background float-left">
      //       {this.props.defaultHandledBy}
      //     </span>
      //   </div>
      // );
    } else {

      // was state ALU!!
      return this.props.handledBy?.map((handledBy: ItemHandledByStore) => {
        const canEditHandledBy = this.props.editMode && this.props.canEditItem(this.props.itemTypeId, handledBy.handledById);
        if (canEditHandledBy) {
          return (
            <div
              key={handledBy.assignmentType + handledBy.personFullName}
              style={{ paddingTop: "2px" }}
              className="float-left font-size-small ml-2 mr-4 "
              data-toggle="tooltip"
              title={handledBy.assignmentType}
            >
              <PersonPicker
                editMode={canEditHandledBy}
                hideLabel={handledBy.hideLabel}
                date={this.props.itemDate}
                personId={handledBy.personId}
                personFullName={handledBy.personFullName}
                itemTypeId={this.props.itemTypeId}
                handledById={handledBy.handledById}
                assignmentType={handledBy.assignmentType}
                externalSourceMappingId={handledBy.externalSourceMappingId}
                onPickPerson={(
                  personId: string | null,
                  personFullName: string
                ) =>
                  handlePickPerson(
                    handledBy.handledById,
                    personId,
                    personFullName
                  )
                }
                onClearPerson={() => handleClearPerson(handledBy.handledById)}
              />
            </div>
          );
        }
        else {
          return (
            <div
              key={handledBy.assignmentType + handledBy.personFullName}
              style={{ paddingTop: "2px" }}
              className="float-left font-size-small ml-2 mr-2"
              data-toggle="tooltip"
              title={handledBy.assignmentType}
            >
              <PersonPicker
                editMode={canEditHandledBy}
                hideLabel={handledBy.hideLabel}
                date={this.props.itemDate}
                personId={handledBy.personId}
                personFullName={handledBy.personFullName}
                itemTypeId={this.props.itemTypeId}
                handledById={handledBy.handledById}
                assignmentType={handledBy.assignmentType}
                externalSourceMappingId={handledBy.externalSourceMappingId}
                onPickPerson={(
                  personId: string | null,
                  personFullName: string
                ) =>
                  handlePickPerson(
                    handledBy.handledById,
                    personId,
                    personFullName
                  )
                }
                onClearPerson={() => handleClearPerson(handledBy.handledById)}
              />
            </div>
          );
        }
      });
    }
  }
}

interface DispatchFromProps {
  updateHandledBy: (
    workbookId: string,
    workbookWeekId: string,
    itemId: string,
    handledById: string,
    personId: string,
    personFullName: string
  ) => void;
  clearHandledBy: (
    workbookId: string,
    workbookWeekId: string,
    itemId: string,
    handledById: string
  ) => void;
}

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  updateHandledBy: (
    workbookId: string,
    workbookWeekId: string,
    itemId: string,
    handledById: string,
    personId: string,
    personFullName: string
  ) =>
    dispatch(
      actionCreators.updateHandledBy(
        workbookId,
        workbookWeekId,
        itemId,
        handledById,
        personId,
        personFullName
      )
    ),
  clearHandledBy: (
    workbookId: string,
    workbookWeekId: string,
    itemId: string,
    handledById: string
  ) =>
    dispatch(
      actionCreators.clearHandledBy(
        workbookId,
        workbookWeekId,
        itemId,
        handledById
      )
    ),
});

export default connect(null, mapDispatchToProps)(ItemHandledBy);
