import { Action, Reducer } from "redux";
import { ExternalSourcesState } from "./ExternalSources";
import { IEntityWithId } from "../store/IEntityWithId";
import { AppThunkAction } from "../store";
import { AppExecutingAction, AppErrorAction } from "../store/AppActions";
import { httpSave, httpGetEntity, httpDelete } from "../store/HttpUtils";

export interface ResetRequestedAction {
  type: "RESET_EXTERNAL_SOURCE_REQUESTED";
}
export interface GetRequestedAction {
  type: "GET_EXTERNAL_SOURCE_REQUESTED";
  id: string;
}
export interface GetResponseAction {
  type: "GET_EXTERNAL_SOURCE_RESPONSE";
  data: ExternalSourceState;
}
export interface GetPageRequestedAction {
  type: "GET_EXTERNAL_SOURCE_PAGE_REQUESTED";
  continuationToken: string;
  pageSize: number;
}
export interface GetPageResponseAction {
  type: "GET_EXTERNAL_SOURCE_PAGE_RESPONSE";
  data: ExternalSourcesState;
}
export interface SaveRequestedAction {
  type: "SAVE_EXTERNAL_SOURCE_REQUESTED";
}
export interface SaveResponseAction {
  type: "SAVE_EXTERNAL_SOURCE_RESPONSE";
  errorMessage?: string;
}

export interface ExternalSourceState extends IEntityWithId {
  id: string;
  name: string;
  googleSpreadsheetId: string;
  dateColumn: string;
  mappings: ExternalSourceMappingState[];
  isLoading?: boolean; //TODO: extract these 3 into an interface
  isSaving?: boolean;
  savingSucceeded?: boolean;
}

export interface ExternalSourceMappingState {
  id: string;
  name: string;
  sourceColumn: string;
}

export type KnownAction =
  | AppExecutingAction
  | AppErrorAction
  | ResetRequestedAction
  | GetRequestedAction
  | GetResponseAction
  | SaveRequestedAction
  | SaveResponseAction;

export const actionCreators = {
  reset: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: "RESET_EXTERNAL_SOURCE_REQUESTED" });
  },

  save:
    (entity: ExternalSourceState): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "SAVE_EXTERNAL_SOURCE_REQUESTED" });
      dispatch({ type: "APP_EXECUTING", executingAction: "Saving" });
      return httpSave<string>(`externalsources`, entity)
        .then(() => {
          dispatch({
            type: "SAVE_EXTERNAL_SOURCE_RESPONSE",
            errorMessage: undefined,
          });
        })
        .finally(() => {
          dispatch({ type: "APP_EXECUTING", executingAction: undefined });
        });
    },

  get:
    (id: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "GET_EXTERNAL_SOURCE_REQUESTED", id });

      return httpGetEntity<ExternalSourceState>("externalsources", id).then(
        (data) => {
          dispatch({ type: "GET_EXTERNAL_SOURCE_RESPONSE", data: data });
          return data;
        }
      );
    },

  delete:
    (id: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      dispatch({ type: "APP_EXECUTING", executingAction: "Deleting" });
      return httpDelete(`externalsources`, id).finally(() => {
        dispatch({ type: "APP_EXECUTING", executingAction: undefined });
      });
    },
};

export const reducer: Reducer<ExternalSourceState> = (
  state: ExternalSourceState | undefined,
  incomingAction: Action
): ExternalSourceState => {
  const unloadedState: ExternalSourceState = {
    id: "",
    name: "",
    googleSpreadsheetId: "",
    dateColumn: "",
    mappings: [],
  };

  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "RESET_EXTERNAL_SOURCE_REQUESTED":
      return {
        ...unloadedState,
      };
    case "GET_EXTERNAL_SOURCE_REQUESTED":
      return {
        ...state,
        id: action.id,
        isLoading: true,
      };
    case "GET_EXTERNAL_SOURCE_RESPONSE":
      return {
        ...action.data,
        isLoading: false,
      };
    case "SAVE_EXTERNAL_SOURCE_REQUESTED":
      return {
        ...state,
        isSaving: true,
      };
    case "SAVE_EXTERNAL_SOURCE_RESPONSE":
      return {
        ...state,
        isSaving: false,
        savingSucceeded: action.errorMessage ? false : true,
      };
  }

  return state;
};
