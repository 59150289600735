import React from "react";
import { Redirect } from "react-router-dom";
import * as ExternalSourcePageResult from "./ExternalSources";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { RotateSpinner } from "react-spinners-kit";
import { UserRole } from "../Users/User";

type ExternalSourcesProps = ExternalSourcePageResult.ExternalSourcesState & {
  roles?: Array<UserRole>;
} & typeof ExternalSourcePageResult.actionCreators;

const pageSize = 20;

class ExternalSources extends React.PureComponent<ExternalSourcesProps> {
  state = { redirect: false };

  componentDidMount = () => {
    this.handleFirstPage();
  };

  handleAdd = () => {
    this.setState({ redirect: true });
  };

  handlePrevPage = () => {
    this.props.get(
      this.props.tokens[this.props.pageNumber - 1] ?? "",
      pageSize
    );
  };

  handleNextPage = () => {
    this.props.get(this.props.continuationToken, pageSize);
  };

  handleFirstPage = () => {
    this.props.get("", pageSize);
  };

  public render() {
    if (this.state.redirect) {
      return <Redirect push={true} to="/externalsources/new/" />;
    }

    return (
      <>
        <p className="h4 pt-4">External Sources</p>

        <hr />

        <RotateSpinner loading={this.props.isLoading} />

        {!this.props.isLoading && (
          <>
            {this.props.roles?.includes("Admin") && (
              <button
                type="button"
                className="btn btn-primary float-left"
                onClick={this.handleAdd}
              >
                Add
              </button>
            )}
            <div className="float-right">
              <nav className="page" aria-label="Page navigation">
                <ul className="pagination justify-content-end">
                  <li
                    key="prevButton"
                    className={
                      this.props.pageNumber > 0 && !this.props.isLoading
                        ? "page-item"
                        : "page-item disabled"
                    }
                  >
                    <button className="page-link" onClick={this.handlePrevPage}>
                      Previous
                    </button>
                  </li>
                  <li
                    key="nextButton"
                    className={
                      this.props.hasMoreResults && !this.props.isLoading
                        ? "page-item"
                        : "page-item disabled"
                    }
                  >
                    <button className="page-link" onClick={this.handleNextPage}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>

            <table className="table table-hover table-sm mt-3 border">
              <thead>
                <tr>
                  <th scope="col" className="pl-3">
                    Name
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.values.map((v) => {
                  return (
                    <tr key={v.id}>
                      <td className="pl-3">
                        <a href={`/externalsources/${v.id}/details`}>{v.name}</a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="input-group-sm page float-right">
              Page {this.props.pageNumber + 1}
            </div>
          </>
        )}
      </>
    );
  }
}

export default connect((state: ApplicationState) => {
  let props = state.externalSources as ExternalSourcesProps;
  props.roles = state.principal?.roles;
  return props;
}, ExternalSourcePageResult.actionCreators)(ExternalSources as any);
