import * as React from 'react';
import { connect } from 'react-redux';
import { ItemMetadataStore, actionCreators } from './ItemStore';
import { BiError } from 'react-icons/bi';

type Props = {
  editMode: boolean,
  workbookId: string,
  workbookWeekId: string,
  itemId: string;
  metadata: Array<ItemMetadataStore>
}

interface State {
  metadata: Array<ItemMetadataStore>
};

class ItemMetadata extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      metadata: this.props.metadata.map(m => ({ key: m.key, value: m.value, isInvalid: false } as ItemMetadataStore)),
    }
  }

  async handleUpdates(e: any) {
    this.state.metadata.forEach(m => {
      const updatedMetadataValue = m.value;
      const initialMetadata = this.props.metadata.filter(x => x.key === m.key)[0];
      initialMetadata.isInvalid = false;
      if (updatedMetadataValue !== initialMetadata.value) {
        (this.props as any).updateMetadata(this.props.workbookId, this.props.workbookWeekId, this.props.itemId, m.key, updatedMetadataValue)
          .then((success: boolean) => {
            initialMetadata.isInvalid = !success;
            if (success) {
              initialMetadata.value = updatedMetadataValue;
            }
            else {
              e?.target?.focus();
            }
          });
      }
    });
  }

  public render() {
    if (this.props.editMode) {
      const onMetadataChanged = (e: React.ChangeEvent<HTMLInputElement>, m: ItemMetadataStore) => {
        let metadata = [...this.state.metadata];
        let um = metadata.filter(x => x.key === m.key)[0];
        um.value = e.target.value; this.setState({ metadata: metadata });
      }

      const resizeMetadataInput = (e: React.FormEvent<HTMLInputElement>) => {
        e.currentTarget.style.width = (e.currentTarget.value.length + 1) + 'ch';
      }

      return (
        this.state.metadata?.map((m: ItemMetadataStore) => {
          return <div key={m.key} style={{ marginTop: "3px" }} className="float-left font-size-extra-small font-weight-light mr-4 " data-toggle="tooltip" title={m.value ?? m.key}>
            <span className="font-size-extra-small no-border no-background p-0 m-0 pr-1 text-muted font-weight-light" data-toggle="tooltip" title={m.key}>{m.key}</span>

            <input type="text"
              className={"font-size-extra-small text-align-left no-border ml-1 text-muted font-weight-light" + (m.isInvalid ?? false ? "is-invalid" : "")} placeholder={m.key}
              style={{ width: ((m?.value?.length ?? 0)) + "ch" }}
              value={m.value || ""}
              onChange={e => onMetadataChanged(e, m)}
              onBlur={e => this.handleUpdates(e)}
              onInput={e => resizeMetadataInput(e)}
            />
          </div>
        })
      );
    }
    else {
      return (
        this.props.metadata?.map((m: ItemMetadataStore) => {
          return <div key={m.key} style={{ marginTop: "3px" }} className="float-left font-size-extra-small font-weight-light mr-4" data-toggle="tooltip" title={m.value ?? m.key}>
            <span className="font-size-extra-small no-border no-background pl-1 text-muted font-weight-light">
              {m.key} {m.value}
              {!m.value && <BiError style={{ marginTop: "-3px" }} className="ml-1" size="16" color="#ffc107" />}
            </span>
          </div>
        })
      )
    }
  }
};

interface DispatchFromProps {
  updateMetadata: (workbookId: string, workbookWeekId: string, itemId: string, key: string, value: string) => void;
}

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  updateMetadata: (workbookId: string, workbookWeekId: string, itemId: string, key: string, value: string) => dispatch(actionCreators.updateMetadata(workbookId, workbookWeekId, itemId, key, value)),
});

export default connect(
  null,
  mapDispatchToProps
)(ItemMetadata);
