import React, { ComponentType } from "react";
import { FieldRenderProps } from "react-final-form";

export const SelectField: ComponentType<
  FieldRenderProps<any, HTMLElement, any> & {
    label: any;
    readOnly: boolean;
    options: Array<any> | (() => Array<any>);
  }
> = ({ input, label, readOnly, options, meta: { touched, error } }) => (
  <div className="input-group input-group-sm normal-text">
    <div className="input-group-prepend">
      <label className="input-group-text">{label}</label>
    </div>
    {!readOnly && (
      <select {...input} className="form-control form-control-sm">
        {(Array.isArray(options) ? options : options()).map((opt, index) => (
          <option key={opt.id ?? opt.description} value={opt.id}>
            {opt.description}
          </option>
        ))}
      </select>
    )}
    {readOnly && (
      <label {...input} className="form-control form-control-sm no-border">
        {
          (Array.isArray(options) ? options : options()).find(
            (o) => o.id === input.value
          )?.description
        }
      </label>
    )}
    {!readOnly && touched && error && (
      <span className="form-control-sm text-danger">{error}</span>
    )}
  </div>
);
