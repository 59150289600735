import React from "react";
import * as UserStore from "./User";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";
import { UserForm } from "./UserFormComponent";

type UserProps = typeof UserStore.actionCreators & {
  readOnly: boolean;
  roles: Array<UserStore.UserRole> | undefined;
  itemTypesInRoles: Record<UserStore.UserRole, Array<string>> | undefined;
  user: UserStore.UserState | undefined;
} & RouteComponentProps<{ id?: string }>;

//TODO: get rid of classes
class User extends React.PureComponent<UserProps> {
  state = {
    redirect: undefined,
    readOnly: this.props.readOnly,
    id: this.props.match.params.id,
  };

  async componentDidMount() {
    if (this.state.id) {
      this.props.get(this.state.id);
    } else {
      this.props.reset();
    }
  }

  componentDidUpdate(prevProps: UserProps, prevState: UserStore.UserState) {
    if (this.props.user?.savingSucceeded) {
      this.setState({ redirect: `/users/` });
    }
  }

  save = async (entity: UserStore.UserState): Promise<any> => {
    return this.props.save(entity);
  };

  private handleCancel = () => {
    if (!this.state.readOnly) {
      if (!this.state.id) {
        this.setState({ redirect: `/users/` });
      } else {
        this.setState({ readOnly: true });
      }
    }
  };

  private handleClose = () => {
    if (this.state.readOnly) {
      this.setState({ redirect: `/users/` });
    }
  };

  private handleEdit = () => {
    if (this.state.readOnly) {
      this.setState({ readOnly: false });
    }
  };

  private handleDelete = () => {
    if (this.state.id) {
      Promise.resolve(this.props.delete(this.state.id)).then(() => {
        this.setState({ redirect: `/users/` });
      });
    }
  };

  public render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect || ``} />;
    }

    const isAdmin = this.props.roles?.includes("Admin");

    return (
      <UserForm
        readOnly={this.state.readOnly}
        initialValues={this.props.user}
        onSubmit={this.save}
        onCancel={this.handleCancel}
        onEdit={isAdmin ? this.handleEdit : undefined}
        onClose={this.handleClose}
        onDelete={isAdmin ? this.handleDelete : undefined}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user,
  roles: state.principal?.roles,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      get: UserStore.actionCreators.get,
      reset: UserStore.actionCreators.reset,
      delete: UserStore.actionCreators.delete,
      save: UserStore.actionCreators.save,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(User);
