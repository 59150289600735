import React from "react"
import { MeetingType } from "../ItemTypes/ItemTypes";
import ItemAddPicker from "./ItemAddPicker";
import ItemDeleteButton from "./ItemDeleteButton";

type Props = {
  workbookId: string,
  weekId: string,
  meetingType: MeetingType,
  itemId: string,
  itemIndex: number,
  renderDelete: boolean
};

export const ItemButtons: React.FunctionComponent<Props> = (props: Props) => {
  return <div>
    <ItemAddPicker workbookId={props.workbookId} weekId={props.weekId} meetingType={props.meetingType} itemIndex={props.itemIndex} />
    {props.renderDelete && <ItemDeleteButton workbookId={props.workbookId} weekId={props.weekId} itemId={props.itemId} itemIndex={props.itemIndex} />}
  </div>
}
