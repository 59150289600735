import * as React from "react";
import { connect } from "react-redux";
import * as ItemTypeStore from "./ItemType";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { CheckboxField } from "../components/CheckboxField";
import { TextField } from "../components/TextField";
import { SelectField } from "../components/SelectField";
import { FORM_ERROR } from "final-form";
import { FormButtons } from "../components/FormButtons";
import { useEffect, useState } from "react";
import { httpGet, RequestParams } from "../store/HttpUtils";
import { ExternalSourcesState } from "../ExternalSources/ExternalSources";

const HandledByCollection: React.FunctionComponent<{
  readOnly: boolean;
  externalSources: Array<ExternalSourceInfo>;
}> = (props, meta) => (
  <FieldArray name="handledBy" readOnly={props.readOnly}>
    {({ fields }) => (
      <div>
        <div className="card">
          <div className="card-header p-0 pl-3">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <span className="input-group-text no-background no-border">
                  Handled by
                </span>
              </div>
            </div>
          </div>
        </div>
        <ul className="list-group list-group-flush border-bottom border-right border-left pt-3">
          {fields.map((assignee, index) => (
            <li
              className="list-group-item input-group-sm no-border pb-0 pt-0 mb-3"
              key={index}
            >
              <div className="input-group-prepend float-left mr-3">
                <Field
                  label="Assignment type"
                  readOnly={props.readOnly}
                  component={TextField}
                  type="input"
                  validate={required}
                  name={`${assignee}.assignmentType`}
                />
              </div>
              <div className="input-group-prepend float-left mr-3">
                <Field
                  label="Map from external source"
                  readOnly={props.readOnly}
                  component={SelectField}
                  options={props.externalSources}
                  type="input"
                  name={`${assignee}.externalSourceMappingId`}
                />
              </div>

              <div className="input-group-prepend float-left">
                <Field
                  label="Hide label"
                  readOnly={props.readOnly}
                  component={CheckboxField}
                  type="input"
                  name={`${assignee}.hideLabel`}
                />
              </div>
              {!props.readOnly && (
                <button
                  className="btn float-right pr-0  pt-0"
                  type="button"
                  title="Remove assignee"
                  onClick={() => fields.remove(index)}
                >
                  <span className="bi-trash" />
                </button>
              )}
            </li>
          ))}
          {!props.readOnly && (
            <li className="list-group-item input-group-sm p-0 pl-3">
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => fields.push({})}
              >
                Add
              </button>
              {(meta.touched || meta.submitFailed) && meta.error && (
                <span className="form-control-sm text-danger">
                  {meta.error}
                </span>
              )}
            </li>
          )}
        </ul>
      </div>
    )}
  </FieldArray>
);

const MetadataDefinitionCollection: React.FunctionComponent<{
  readOnly: boolean;
  externalSources: Array<ExternalSourceInfo>;
}> = (props, meta) => (
  <FieldArray name="metadataDefinition" readOnly={props.readOnly}>
    {({ fields }) => (
      <div>
        <div className="card">
          <div className="card-header p-0 pl-3">
            <div className="input-group input-group-sm">
              <div className="input-group-prepend">
                <span className="input-group-text no-background no-border">
                  Metadata definition
                </span>
              </div>
            </div>
          </div>
        </div>
        <ul className="list-group list-group-flush border-bottom border-right border-left pt-3">
          {fields.map((metadataInfo, index) => (
            <li
              className="list-group-item input-group-sm no-border pb-0 pt-0 mb-3"
              key={index}
            >
              <div
                className="input-group-prepend float-left mr-3"
                style={{ width: "25%" }}
              >
                <Field
                  label="Key"
                  readOnly={props.readOnly}
                  component={TextField}
                  type="input"
                  validate={required}
                  name={`${metadataInfo}.key`}
                />
              </div>
              <div
                className="input-group-prepend float-left mr-3"
                style={{ width: "25%" }}
              >
                <Field
                  label="Regex mask (jw.org)"
                  readOnly={props.readOnly}
                  component={TextField}
                  type="input"
                  name={`${metadataInfo}.regexMask`}
                />
              </div>

              <div className="input-group-prepend float-left">
                <Field
                  label="Map from external source"
                  readOnly={props.readOnly}
                  component={SelectField}
                  options={props.externalSources}
                  type="input"
                  name={`${metadataInfo}.externalSourceMappingId`}
                />
              </div>

              {!props.readOnly && (
                <button
                  className="btn float-right pr-0 pt-0"
                  type="button"
                  title="Remove assignee"
                  onClick={() => fields.remove(index)}
                >
                  <span className="bi-trash" />
                </button>
              )}
            </li>
          ))}
          {!props.readOnly && (
            <li className="list-group-item input-group-sm p-0 pl-3">
              <button
                className="btn btn-sm"
                type="button"
                onClick={() => fields.push({})}
              >
                Add
              </button>
              {(meta.touched || meta.submitFailed) && meta.error && (
                <span className="form-control-sm text-danger">
                  {meta.error}
                </span>
              )}
            </li>
          )}
        </ul>
      </div>
    )}
  </FieldArray>
);

const required = (value: any) => (value ? undefined : "Required");

const validateItemType = (values: ItemTypeStore.ItemTypeState) => {
  type itemTypeErrors = {
    description: string | undefined;
    durationMinutes: string | undefined;
  };

  const errors: itemTypeErrors = {
    description: undefined,
    durationMinutes: undefined,
  };

  if (!values.description || values.description === "") {
    errors.description = "Required";
  }

  if (
    values.durationMinutes &&
    !/(^(<)?[0-9]*$)/i.test(values.durationMinutes)
  ) {
    errors.durationMinutes = "Invalid duration";
  }

  return errors;
};

interface ItemTypeProps {
  readOnly: boolean;
  initialValues: ItemTypeStore.ItemTypeState | undefined;
  onSubmit?: (itemType: ItemTypeStore.ItemTypeState) => void;
  onCancel?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
  onDelete?: () => void;
}

interface ExternalSourceInfo {
  id: string;
  description: string;
}

export const ItemTypeForm: React.FunctionComponent<ItemTypeProps> = (
  props: ItemTypeProps
) => {
  const { readOnly, initialValues } = props;

  const [externalSources, setExternalSources] = useState(
    Array<ExternalSourceInfo>
  );

  useEffect(() => {
    httpGet<ExternalSourcesState>(
      `ExternalSources`,
      new RequestParams("pageSize", 200)
    ).then((data) => {
      let externalSources = new Array<ExternalSourceInfo>();
      externalSources.push({
        id: "",
        description: "",
      });

      data.values.forEach((value) =>
        value.mappings.forEach((mapping) => {
          externalSources.push({
            id: mapping.id,
            description: `${value.name} - ${mapping.name}`,
          });
        })
      );

      setExternalSources(externalSources);
    });
  }, []);

  return (
    <>
      <p className="h4 pt-4">
        {readOnly
          ? `View ${initialValues?.meetingType} item type`
          : initialValues?.id !== ""
          ? `Edit ${initialValues?.meetingType} item type`
          : `New ${initialValues?.meetingType} item type`}
      </p>
      <hr />
      <Form
        onSubmit={async (values) => {
          if (!props.onSubmit) {
            return;
          }

          try {
            return props.onSubmit(values as ItemTypeStore.ItemTypeState);
          } catch (err) {
            return {
              [FORM_ERROR]: `Could not save the changes: ${err.message}`,
            };
          }
        }}
        mutators={{ ...arrayMutators }}
        initialValues={props.initialValues}
        validate={validateItemType}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          hasSubmitErrors,
          submitError,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Field
                label="Is active"
                readOnly={readOnly}
                component={CheckboxField}
                type="input"
                name="isActive"
                id="isActive"
              />
            </div>

            <div className="mb-3">
              <Field
                label="Description"
                readOnly={readOnly}
                component={TextField}
                type="input"
                name="description"
              />
            </div>

            <div className="mb-3">
              <Field
                label="Section"
                readOnly={readOnly}
                component={TextField}
                type="input"
                name="section"
              />
            </div>

            <div className="mb-3">
              <Field
                label="Duration (minutes)"
                readOnly={readOnly}
                component={TextField}
                type="input"
                name="durationMinutes"
              />
            </div>

            <div className="mb-3">
              <Field
                label="Chairman additional time (minutes)"
                readOnly={readOnly}
                component={TextField}
                type="input"
                name="chairmanAdditionalTimeMinutes"
              />
            </div>

            <div className="mb-3">
              <Field
                label="Add automatically"
                readOnly={readOnly}
                component={SelectField}
                type="input"
                options={[
                  { id: undefined, description: "disabled" },
                  { id: 1, description: "at the top" },
                  { id: 2, description: "at the bottom" },
                ]}
                name="autoAdd"
                id="autoAdd"
              />
            </div>

            <div className="mb-3">
              <Field
                label="Regex mask (jw.org)"
                readOnly={readOnly}
                component={TextField}
                type="input"
                name="regexMask"
              />
            </div>

            <div className="mb-3">
              <Field
                label="Map from external source"
                readOnly={readOnly}
                component={SelectField}
                options={externalSources}
                type="input"
                name="externalSourceMappingId"
              />
            </div>

            <div className="mb-3">
              <HandledByCollection
                readOnly={readOnly}
                externalSources={externalSources}
              />
            </div>

            <div className="mb-3">
              <MetadataDefinitionCollection
                readOnly={readOnly}
                externalSources={externalSources}
              />
            </div>

            <div className="mb-3">
              <Field
                label="Display index"
                readOnly={readOnly}
                component={TextField}
                type="input"
                name="index"
              />
            </div>

            <div>
              <Field
                label="Show in header"
                readOnly={readOnly}
                component={CheckboxField}
                type="input"
                name="showInHeader"
                id="showInHeader"
              />
            </div>

            <div>
              <Field
                label="Hide from schedule"
                readOnly={readOnly}
                component={CheckboxField}
                type="input"
                name="hideFromSchedule"
                id="hideFromSchedule"
              />
            </div>

            <div>
              <Field
                label="Has student assignment slip"
                readOnly={readOnly}
                component={CheckboxField}
                type="input"
                name="hasStudentAssignmentSlip"
                id="hasStudentAssignmentSlip"
              />
            </div>

            <div>
              <Field
                label="Remind during announcements a week before"
                readOnly={readOnly}
                component={CheckboxField}
                type="input"
                name="remindInAnnouncements"
                id="remindInAnnouncements"
              />
            </div>

            <div>
              <Field
                label="Can be used as a secondary assignment"
                readOnly={readOnly}
                component={CheckboxField}
                type="input"
                name="isDutyItem"
                id="isDutyItem"
              />
            </div>

            <hr />

            {hasSubmitErrors && !readOnly && (
              <span className="form-control-sm text-danger">{submitError}</span>
            )}

            <FormButtons
              readOnly={readOnly}
              pristine={pristine}
              submitting={submitting}
              onCancel={props.onCancel}
              onEdit={props.onEdit}
              onClose={props.onClose}
              onDelete={props.onDelete}
            />
          </form>
        )}
      />
    </>
  );
};

export default connect(null, ItemTypeStore.actionCreators)(ItemTypeForm);
