import * as React from 'react';
import { DeleteButton } from './DeleteButton';

interface Props {
  readOnly: boolean;
  pristine: boolean;
  submitting: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
  onDelete?: () => void;
  onPrev?: () => void;
  onNext?: () => void;
}

export const FormButtons: React.FunctionComponent<Props> = (props: Props) => {
  const { pristine, submitting, readOnly, onCancel, onEdit, onClose, onDelete, onPrev, onNext } = props;

  return (<>
    <div className="float-left">
      {!readOnly && <button type="submit" className="btn btn-primary btn-sm ml-3" disabled={pristine || submitting}>Save</button>}
      {readOnly && onEdit && <button type="button" className="btn btn-primary btn-sm ml-3" onClick={onEdit}>Edit</button>}
      {!readOnly && onCancel && <button type="button" className="btn btn-secondary btn-sm ml-3" onClick={onCancel}>Cancel</button>}
      {readOnly && onClose && <button type="button" className="btn btn-secondary btn-sm ml-3" onClick={onClose}>Close</button>}
      {readOnly && onPrev && <button type="button" className="btn btn-secondary btn-sm ml-3" onClick={onPrev}>Previous</button>}
      {readOnly && onNext && <button type="button" className="btn btn-secondary btn-sm ml-3" onClick={onNext}>Next</button>}
    </div>

    <div className="float-right">
      {readOnly && onDelete && <DeleteButton onDelete={onDelete} />}
    </div>
  </>
  );
};