import { Action, Reducer } from 'redux';
import { PersonsState } from "./Persons";
import { IEntityWithId } from '../store/IEntityWithId';
import { GetItemTypePageResponseAction } from '../ItemTypes/ItemType';
import { AppThunkAction } from '../store';
import { AppExecutingAction, AppErrorAction } from '../store/AppActions';
import { httpGet, RequestParam, httpSave, httpGetEntity, httpDelete } from '../store/HttpUtils';
import { ActiveType } from '../ItemTypes/ItemTypes';
import { ItemTypeUsageRecordState } from '../ItemTypes/ItemTypeUsageRecordState';

export interface ResetResponseAction { type: 'RESET_PERSON_RESPONSE', data: PersonState }
export interface GetRequestedAction { type: 'GET_PERSON_REQUESTED', id: string }
export interface GetResponseAction { type: 'GET_PERSON_RESPONSE', data: PersonState }
export interface GetPageRequestedAction { type: 'GET_PERSON_PAGE_REQUESTED', activeType: ActiveType, continuationToken: string, pageSize: number }
export interface GetPageResponseAction { type: 'GET_PERSON_PAGE_RESPONSE', data: PersonsState }
export interface SaveRequestedAction { type: 'SAVE_PERSON_REQUESTED' }
export interface SaveResponseAction { type: 'SAVE_PERSON_RESPONSE', errorMessage?: string }

export interface PersonState extends IEntityWithId {
  id: string;
  fullName: string;
  emailAddress: string;
  isFemale: boolean;
  isActive: boolean;
  comments?: string;
  usedFor: ItemTypeUsageRecordState[]
  isLoading?: boolean
  isSaving?: boolean
  savingSucceeded?: boolean
}

export type KnownAction = AppExecutingAction | AppErrorAction | ResetResponseAction | GetRequestedAction | GetResponseAction | GetItemTypePageResponseAction | SaveRequestedAction | SaveResponseAction;

export const actionCreators = {
  reset: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    return httpGet<PersonState>(`persons/new`, new Array<RequestParam>()).then(data => {
      dispatch({ type: 'GET_PERSON_RESPONSE', data: data });
      return data;
    });
  },

  save: (entity: PersonState): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: 'SAVE_PERSON_REQUESTED' });
    dispatch({ type: 'APP_EXECUTING', executingAction: "Saving" });
    return httpSave<string>(`persons`, entity)
      .then(() => {
        dispatch({ type: 'SAVE_PERSON_RESPONSE', errorMessage: undefined });
      })
      .finally(() => {
        dispatch({ type: 'APP_EXECUTING', executingAction: undefined });
      });
  },

  get: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: 'GET_PERSON_REQUESTED', id });

    return httpGetEntity<PersonState>('persons', id).then(data => {
      dispatch({ type: 'GET_PERSON_RESPONSE', data: data });
      return data;
    })
  },

  delete: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
    dispatch({ type: 'APP_EXECUTING', executingAction: "Deleting" });
    return httpDelete(`persons`, id)
      .finally(() => {
        dispatch({ type: 'APP_EXECUTING', executingAction: undefined });
      });
  }
};

export const reducer: Reducer<PersonState> = (state: PersonState | undefined, incomingAction: Action): PersonState => {
  const unloadedState: PersonState = { id: "", fullName: "", emailAddress: "", isFemale: false, isActive: true, usedFor: [] };

  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'RESET_PERSON_RESPONSE':
      return {
        ...action.data,
        isLoading: false
      };
    case 'GET_PERSON_REQUESTED':
      return {
        ...state,
        id: action.id,
        isLoading: true
      };
    case 'GET_PERSON_RESPONSE':
      return {
        ...action.data,
        isLoading: false
      };
    case 'SAVE_PERSON_REQUESTED':
      return {
        ...state,
        isSaving: true
      };
    case 'SAVE_PERSON_RESPONSE':
      return {
        ...state,
        isSaving: false,
        savingSucceeded: action.errorMessage ? false : true
      };
  }

  return state;
};