import Button from "@mui/material/Button";
import React, { } from "react"
import { connect } from "react-redux"
import { MeetingType } from "../ItemTypes/ItemTypes";
import { ApplicationState } from "../store"
import LocalMenu, { LocalMenuItem } from "./LocalMenu";
import * as WorkbookStore from './Workbook';
import { WorkbookWeekState } from "./Workbook";

type Props = {
  workbookId: string;
  month: number;
  year: number;
  weeks: Array<WorkbookWeekState>;
  selectedWeek?: WorkbookWeekState;
  goPrev: () => void;
  goNext: () => void;
  selectWeek: (week: WorkbookWeekState) => void;
} & DispatchFromProps;

const NavigationButtons: React.FunctionComponent<Props> = (props: Props) => {

  const getMonth = (): string => {
    return new Date(props.year, props.month - 1, 1).toLocaleString('default', { month: 'long' });
  }

  const menuItems: LocalMenuItem[] = [
    {
      description: "CLMM schedule (docx)",
      onClick: () =>
        props.createSchedule(
          props.workbookId,
          'midweek',
          `CLMM schedule ${getMonth()} ${props.year}.docx`,
          "Docx2013"
        ),
    },
    {
      description: "CLMM schedule (odt)",
      onClick: () =>
        props.createSchedule(
          props.workbookId,
          'midweek',
          `CLMM schedule ${getMonth()} ${props.year}.odt`,
          "Odt"
        ),
    }
  ];

  return (<>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Button variant="contained" size="large" onClick={() => props.goPrev()}>
        <span className="bi-arrow-left" />
      </Button>
      <div style={{
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
      }}>
        <div style={{
          fontWeight: "bold", fontSize: "1.2rem", display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <div className="no-border no-background">{getMonth()} {props.year}</div>

          <LocalMenu
            key="menu"
            items={menuItems}
          />
        </div>

        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0.2em", flexWrap: "wrap" }}>
          {
            props.weeks.map(week =>
              <Button
                key={week.week}
                variant={week === props.selectedWeek ? "contained" : "text"}
                size="medium"
                style={{
                  width: `${100 / props.weeks.length}%`
                }} onClick={() => props.selectWeek(week)}>
                {week.week.substring(0, 5)}
              </Button>)
          }
        </div>
      </div>

      <Button variant="contained" size="large" onClick={() => props.goNext()}>
        <span className="bi-arrow-right" />
      </Button>
    </div>

    {/* <div className="input-group input-group-lg mb-2">
      <div className="input-group-prepend ml-1">
        <button className="btn" type="button" onClick={() => props.goPrev()}>
          <span className="bi-arrow-left" />
        </button>
      </div>

      <div className="input-group-prepend">
        <label className="input-group-text no-border no-background font-weight-bold">{getMonth()} {props.year}</label>
      </div>

      <div className="input-group-prepend mr-1">
        <button className="btn" type="button" onClick={() => props.goNext()}>
          <span className="bi-arrow-right" />
        </button>
      </div>

      <div className="input-group-prepend mr-0">
        <PrintButton />
      </div>
    </div> */}
  </>)
}

interface DispatchFromProps {
  goPrev: () => void;
  goNext: () => void;
  selectWeek: (week: WorkbookWeekState) => void;
  createSchedule: (workbookId: string, meetingType: MeetingType, fileName: string, fileFormat: "Odt" | "Docx2013",) => void;
}

const mapStateToProps = (state: ApplicationState) => ({
  workbookId: state.workbook!.id,
  month: state.workbook!.month,
  year: state.workbook!.year,
  isPrinting: state.workbook!.isPrinting,
  weeks: state.workbook!.weeks,
  selectedWeek: state.workbook!.selectedWeek,
});

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  goPrev: () => dispatch(WorkbookStore.actionCreators.goPrev()),
  goNext: () => dispatch(WorkbookStore.actionCreators.goNext()),
  selectWeek: (week: WorkbookWeekState) => dispatch(WorkbookStore.actionCreators.selectWeek(week)),
  createSchedule: (workbookId: string, meetingType: MeetingType, fileName: string, fileFormat: "Odt" | "Docx2013",) => dispatch(WorkbookStore.actionCreators.createSchedule(workbookId, meetingType, fileName, fileFormat)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationButtons);
