import Popover from "@mui/material/Popover";
import React, { useState } from "react";

export type LocalMenuItem = {
  description: string;
  onClick: () => void;
  className?: string;
};

type Props = {
  items: Array<LocalMenuItem>;
};

const LocalMenu: React.FunctionComponent<Props> = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const printSchedule = (fileName: string) => {
  //   Promise.resolve(props.createSchedule(props.workbookId, fileName));
  //   setAnchorEl(null);
  // };

  // const printAssignmentSlips = (fileName: string, workbookWeekId?: string) => {
  //   Promise.resolve(
  //     props.createAssignmentSlips(props.workbookId, fileName, workbookWeekId)
  //   );
  //   setAnchorEl(null);
  // };

  // const printChairmanOutline = (fileName: string, workbookWeekId: string) => {
  //   Promise.resolve(
  //     props.createChairmanOutline(props.workbookId, fileName, workbookWeekId)
  //   );
  //   setAnchorEl(null);
  // };

  // const getMonth = (): string => {
  //   return new Date(props.year, props.month - 1, 1).toLocaleString("default", {
  //     month: "long",
  //   });
  // };

  if (!props.items.length) {
    return null;
  }

  return (
    <>
      <button
        className="btn"
        type="button"
        data-toggle="tooltip"
        title="Options"
        onClick={(event) => handleOpen(event)}
      >
        <span className="menu" />
      </button>
      <Popover
        id={"options-popover"}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="p-3">
          <table className="table table-hover table-sm m-0 no-border">
            <tbody>
              {props.items.map((item) => (
                <tr
                  key={item.description}
                  onClick={() => {
                    item.onClick();
                    setAnchorEl(null);
                  }}
                >
                  <td style={{ cursor: "pointer" }}>
                    <span className={item.className}>{item.description}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Popover>
    </>
  );
};

export default LocalMenu;
