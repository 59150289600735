import Popover from "@mui/material/Popover";
import React, { useState } from "react"
import { connect } from "react-redux"
import { MeetingType } from "../ItemTypes/ItemTypes";
import { ApplicationState } from "../store"
import * as WorkbookStore from './Workbook';
import { GroupedItemTypes } from "./Workbook";
import { getSectionColor } from "../ItemTypes/ItemTypesComponent";

type Props = {
  workbookId: string,
  weekId: string,
  meetingType: MeetingType,
  itemIndex: number,
  groupedItemTypes?: Array<GroupedItemTypes>;
} & DispatchFromProps;

const ItemAddPicker: React.FunctionComponent<Props> = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(-1);

  const handleAddItemOpen = (event: any) => {
    props.loadItemTypes(props.meetingType, "");
    setAnchorEl(event.currentTarget);
    setCurrentItemIndex(props.itemIndex);
    //this.setState({ anchorEl: event.currentTarget, currentWeekId: weekId, currentItemIndex: itemIndex, isDelete: false, isDeleting: false });
  };

  const handleAddItemClose = () => {
    setAnchorEl(null);
    setCurrentItemIndex(0);
    //this.setState({ anchorEl: null, currentWeekId: null, currentItemIndex: 0, isDelete: false, isDeleting: false });
  };

  const handleAddItem = async (weekId: string, itemTypeId: string, index: number) => {
    props.addItem(props.workbookId, weekId, props.meetingType, itemTypeId, index);
    handleAddItemClose();
  };

  const getSectionStyle = (section?: string): any => {
    return {
      backgroundColor: getSectionColor(section),
      color: "white"
    };
  }

  const setFocus = (element: HTMLInputElement): void => {
    if (element && currentItemIndex === props.itemIndex) {
      element.focus();
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'ArrowUp') {
      props.selectPrevItemType(props.meetingType);
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === 'ArrowDown') {
      props.selectNextItemType(props.meetingType);
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === 'Enter') {
      props.groupedItemTypes?.forEach(gr => {
        gr.itemTypes.forEach(itemType => {
          if (itemType.isSelected) {
            handleAddItem(props.weekId, itemType.id, currentItemIndex + 1);
            e.preventDefault();
            e.stopPropagation();
            return;
          }
        })
      })
    }

    if (e.key === 'Escape') {
      handleAddItemClose();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (<>
    <button style={{ padding: "0", marginLeft: "10px" }} className="btn no-border " type="button" onClick={handleAddItemOpen}>
      <span style={{ marginTop: "-3px" }} className="bi-circle-plus" />
    </button>

    <Popover
      id={"add-popover" + props.weekId.toString()}
      open={Boolean(anchorEl)}
      onClose={handleAddItemClose}
      onKeyDown={e => handleKeyDown(e)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>

      <div className="m-3">
        <input ref={setFocus} className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" onChange={event => props.searchItemTypes(props.meetingType, event.target.value)} />
      </div>
      {props.groupedItemTypes?.map(g => (
        <div key={g.section}>
          <div className="pl-3 pr-3 font-weight-bold" style={getSectionStyle(g.section)}>{g.section}</div>
          <table className="table table-hover table-sm mt-0 border">
            <tbody>
              {g.itemTypes.map(i => (
                <tr key={i.id} onClick={() => handleAddItem(props.weekId, i.id, currentItemIndex + 1)} className={i.isSelected ? 'table-active' : ''}>
                  <td style={{ cursor: "pointer" }} className="pl-3 font-size-small p-0">{i.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </Popover>
  </>)
}

interface DispatchFromProps {
  loadItemTypes: (meetingType: MeetingType, term: string) => any;
  selectPrevItemType: (meetingType: MeetingType) => void;
  selectNextItemType: (meetingType: MeetingType) => void;
  searchItemTypes: (meetingType: MeetingType, term: string) => any;
  addItem: (workbookId: string, workbookWeekId: string, meetingType: MeetingType, itemTypeId: string, index: number) => void;
}

const mapStateToProps = (state: ApplicationState, ownProps: { meetingType: MeetingType; }) => ({
  groupedItemTypes: ownProps.meetingType === "midweek"
    ? state.workbook?.midweekMeetingGroupedItemTypes
    : state.workbook?.weekendMeetingGroupedItemTypes
});

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  loadItemTypes: (meetingType: MeetingType, term: string) => dispatch(WorkbookStore.actionCreators.loadItemTypes(meetingType, term)),
  selectPrevItemType: (meetingType: MeetingType) => dispatch(WorkbookStore.actionCreators.selectPrevItemType(meetingType)),
  selectNextItemType: (meetingType: MeetingType) => dispatch(WorkbookStore.actionCreators.selectNextItemType(meetingType)),
  searchItemTypes: (meetingType: MeetingType, term: string) => dispatch(WorkbookStore.actionCreators.searchItemTypes(meetingType, term)),
  addItem: (workbookId: string, workbookWeekId: string, meetingType: MeetingType, itemTypeId: string, index: number) => dispatch(WorkbookStore.actionCreators.addItem(workbookId, workbookWeekId, meetingType, itemTypeId, index)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ItemAddPicker);
