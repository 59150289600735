import * as React from 'react';
import { connect } from 'react-redux';
import { WorkbookWeekItemState, actionCreators } from './ItemStore';

type ItemProps = {
  editMode: boolean,
  item: WorkbookWeekItemState
}

interface ItemState {
  durationMinutes: string,
  isDurationMinutesInvalid: boolean;
};

class Item extends React.PureComponent<ItemProps, ItemState> {
  constructor(props: ItemProps) {
    super(props);
    this.state = {
      durationMinutes: this.props.item.durationMinutes,
      isDurationMinutesInvalid: false
    }
  }

  async handleUpdates(e: any) {
    const updatedDurationMinutes = this.state.durationMinutes;
    if (updatedDurationMinutes !== this.props.item.durationMinutes) {
      const match = updatedDurationMinutes.match("(<)?[0-9]+");
      if (!match || match[0] !== updatedDurationMinutes) {
        this.setState({ isDurationMinutesInvalid: true });
        e?.target?.focus();
      }
      else {
        (this.props as any).updateDuration(this.props.item.workbookId, this.props.item.workbookWeekId, this.props.item.id, updatedDurationMinutes).then((success: boolean) => {
          this.setState({ isDurationMinutesInvalid: !success });
          if (success) {
            this.props.item.durationMinutes = updatedDurationMinutes;
          }
          else {
            e?.target?.focus();
          }
        });
      }
    }
    else {
      this.setState({ isDurationMinutesInvalid: false });
    }
  }

  public render() {
    return !this.props.item.durationMinutes
      ? null
      : this.props.editMode
        ? <><input type="text" style={{ paddingBottom: "2px", width: "25px", marginTop: "2px" }} className={" text-align-right font-size-small no-border ml-1 " + (this.state.isDurationMinutesInvalid ?? false ? "is-invalid" : "")} data-toggle="tooltip" title="Duration (minutes)" placeholder="Duration (minutes)"
          value={this.state.durationMinutes ?? ''}
          onChange={e => this.setState({ durationMinutes: e.target.value })}
          onBlur={e => this.handleUpdates(e)} />
          <span className="font-size-small no-background pl-1 pr-0 m-0">min.</span></>
        : <span className="font-size-small no-border no-background pl-0 ml-1 text-muted" style={{ minInlineSize: "fit-content", marginTop: "2px" }} data-toggle="tooltip" title="Duration (minutes)">
          ({this.props.item.durationMinutes} min.)
        </span>
  }
};

interface DispatchFromProps {
  updateDuration: (workbookId: string, workbookWeekId: string, itemId: string, duration: string) => void;
}

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  updateDuration: (workbookId: string, workbookWeekId: string, itemId: string, duration: string) => dispatch(actionCreators.updateDuration(workbookId, workbookWeekId, itemId, duration)),
});

export default connect(
  null,
  mapDispatchToProps
)(Item);
