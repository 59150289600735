import * as React from "react";
import { connect } from "react-redux";
import * as ExternalSourceStore from "./ExternalSource";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import { TextField } from "../components/TextField";
import { FORM_ERROR } from "final-form";
import { FormButtons } from "../components/FormButtons";

const required = (value: any) => (value ? undefined : "Required");

const ExternalSourceMappingCollection: React.FunctionComponent<{
  readOnly: boolean;
}> = (props, meta) => (
  <div>
    <ul className="list-group list-group-flush border-bottom border-right border-left pt-3">
      <FieldArray name="mappings" readOnly={props.readOnly}>
        {({ fields }) => (
          <div>
            <div className="card">
              <div className="card-header p-0 pl-3">
                <div className="input-group input-group-sm">
                  <div className="input-group-prepend">
                    <span className="input-group-text no-background no-border">
                      Mappings
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <ul className="list-group list-group-flush border-bottom border-right border-left pt-3">
              {fields.map((assignee, index) => (
                <li
                  className="list-group-item input-group-sm no-border pb-0 pt-0 mb-3"
                  key={index}
                >
                  <div className="input-group-prepend w-50 float-left mr-3">
                    <Field
                      label="Name"
                      readOnly={props.readOnly}
                      component={TextField}
                      type="input"
                      validate={required}
                      name={`${assignee}.name`}
                    />
                  </div>
                  <div className="input-group-prepend float-left">
                    <Field
                      label="Sheet Column"
                      readOnly={props.readOnly}
                      component={TextField}
                      type="input"
                      name={`${assignee}.sourceColumn`}
                    />
                  </div>
                  {!props.readOnly && (
                    <button
                      className="btn float-right pr-0  pt-0"
                      type="button"
                      title="Remove mapping"
                      onClick={() => fields.remove(index)}
                    >
                      <span className="bi-trash" />
                    </button>
                  )}
                </li>
              ))}
              {!props.readOnly && (
                <li className="list-group-item input-group-sm p-0 pl-3">
                  <button
                    className="btn btn-sm"
                    type="button"
                    onClick={() => fields.push({})}
                  >
                    Add
                  </button>
                  {(meta.touched || meta.submitFailed) && meta.error && (
                    <span className="form-control-sm text-danger">
                      {meta.error}
                    </span>
                  )}
                </li>
              )}
            </ul>
          </div>
        )}
      </FieldArray>
    </ul>
  </div>
);

interface ExternalSourceProps {
  readOnly: boolean;
  initialValues: ExternalSourceStore.ExternalSourceState | undefined;
  onSubmit?: (
    externalSource: ExternalSourceStore.ExternalSourceState
  ) => Promise<any>;
  onCancel?: () => void;
  onEdit?: () => void;
  onClose?: () => void;
  onDelete?: () => void;
}

export const ExternalSourceForm: React.FunctionComponent<
  ExternalSourceProps
> = (props: ExternalSourceProps) => {
  const { readOnly, initialValues } = props;

  // const [googleSpreadsheetId, setGoogleSpreadsheetId] = useState(
  //   initialValues?.googleSpreadsheetId
  // );

  // const [externalSourceNames, setExternalSourceNames] = useState(
  //   Array<ExternalSourceName>
  // );

  // useEffect(() => {
  //   if (!googleSpreadsheetId) {
  //     return;
  //   }

  //   httpGet<ExternalSourceName[]>(
  //     `externalsources/names`,
  //     new RequestParams("googleSpreadsheetId", googleSpreadsheetId)
  //   ).then((data) => {
  //     setExternalSourceNames(data);
  //   });
  // }, [googleSpreadsheetId, initialValues]);

  return (
    <>
      <p className="h4 pt-4">
        {readOnly ? "Edit External Source" : "View External Source"}
      </p>
      <hr />
      <Form
        onSubmit={async (values) => {
          if (!props.onSubmit) {
            return;
          }

          try {
            return props.onSubmit(
              values as ExternalSourceStore.ExternalSourceState
            );
          } catch (err) {
            return {
              [FORM_ERROR]: `Could not save the changes: ${err.message}`,
            };
          }
        }}
        keepDirtyOnReinitialize={true}
        mutators={{ ...arrayMutators }}
        initialValues={initialValues}
        render={({
          handleSubmit,
          form,
          submitting,
          pristine,
          hasSubmitErrors,
          submitError,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Field
                label="Name"
                readOnly={readOnly}
                component={TextField}
                name="name"
              />
            </div>

            <div className="mb-3">
              <Field
                label="Google Sheets link"
                readOnly={readOnly}
                component={TextField}
                name="googleSpreadsheetId"
                // parse={(value) => {
                //   setGoogleSpreadsheetId(value);
                //   return value;
                // }}
              />
            </div>

            <div className="mb-3">
              <Field
                label="Date Column"
                readOnly={readOnly}
                component={TextField}
                name="dateColumn"
              />
            </div>

            {/* <div className="mb-3">
              <Field
                label="Sheet"
                readOnly={readOnly}
                component={SelectField}
                options={[
                  { id: "", description: "disabled" },
                  { id: 1, description: "at the top" },
                  { id: 2, description: "at the bottom" },
                ]}
                name="sheetId"
              />
            </div> */}

            <div className="mb-3">
              <ExternalSourceMappingCollection readOnly={readOnly} />
            </div>

            <hr />

            {hasSubmitErrors && !readOnly && (
              <span className="form-control-sm text-danger">{submitError}</span>
            )}

            <FormButtons
              readOnly={readOnly}
              pristine={pristine}
              submitting={submitting}
              onCancel={props.onCancel}
              onEdit={props.onEdit}
              onClose={props.onClose}
              onDelete={props.onDelete}
            />
          </form>
        )}
      />
    </>
  );
};

export default connect(
  null,
  ExternalSourceStore.actionCreators
)(ExternalSourceForm);
