import * as React from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { CheckboxField } from './CheckboxField';
import { TextLabel } from './TextLabel';
import { getSectionColor } from '../ItemTypes/ItemTypesComponent';

export type ItemTypeTreeFormProps = {
  fields: string;
  readOnly: boolean;
}

export function ItemTypeTreeForm({
  fields,
  readOnly
}: ItemTypeTreeFormProps) {
  return <ul className="list-group list-group-flush border-bottom border-right border-left pt-3">
    <FieldArray name={fields}>
      {({ fields }) => {
        const meetings = {
          "midweek": "LIFE AND MINISTRY",
          "weekend": "WATCHTOWER STUDY",
        };

        let meetingType = "";
        let displayMeetingType = false;
        let indentMeetingType = false;

        let section = "";
        let displaySection = false;
        let indentSection = false;

        const getIndents = () => {
          let indents = 0;
          if (indentMeetingType) {
            indents++;
          }
          if (indentSection) {
            indents++;
          }

          return indents;
        };

        return fields.map((name, index) => {
          const iterationMeetingType = fields.value[index]["meetingType"];
          const iterationSection = fields.value[index]["section"];
          const description = fields.value[index]["description"];
          if (meetingType !== iterationMeetingType) {
            meetingType = iterationMeetingType;
            indentMeetingType = true;
            displayMeetingType = true;
          }
          else {
            displayMeetingType = false;
          }

          if (section !== iterationSection) {
            section = iterationSection;
            if (section) {
              indentSection = true;
              displaySection = true;
            }
          }
          else {
            displaySection = false;
          }

          return (
            <li className="list-group-item input-group-sm no-border pb-0 pt-0 mb-0" key={index}>
              {displayMeetingType &&
                <div className="input-group-prepend w-100 float-clear mr-3" style={{ fontWeight: "bold", textDecoration: "underline" }}>
                  <TextLabel value={meetings[meetingType]} />
                </div>}
              {displaySection &&
                <div className="input-group-prepend w-100 float-clear mr-3" style={{ marginLeft: `20px`, fontWeight: "bold", color: getSectionColor(section) }}>
                  <TextLabel value={section} />
                </div>}
              <div className="input-group-prepend w-100 float-left mr-3" style={{ marginLeft: `${getIndents() * 20}px` }}>
                <Field
                  label={description}
                  readOnly={readOnly}
                  component={CheckboxField}
                  name={`${name}.isUsed`}
                  type="input"
                  id={`${name}.isUsed`} />
                {/* <Field
                                  readOnly={true}
                                  component={TextField}
                                  name={`${name}.description`} /> */}
              </div>
            </li>
          );
        });
      }}
    </FieldArray>
  </ul>;
}
