import { Action, Reducer } from "redux";
import { AppThunkAction } from "../store";
import {
  httpGet,
  httpPost,
  httpDelete,
  RequestParams,
  httpDownload,
  httpPut,
} from "../store/HttpUtils";
import { IEntityWithId } from "../store/IEntityWithId";
import { WorkbookWeekItemState } from "../Items/ItemStore";
import {
  GetItemTypePageRequestedAction,
  GetItemTypePageResponseAction,
  ItemTypeSearchRequestedAction,
  ItemTypeSelectedResponseAction,
} from "../ItemTypes/ItemType";
import * as ItemTypes from "../ItemTypes/ItemTypes";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface WorkbookChangesState extends IEntityWithId {
  id: string;
  itemChanges: Array<WorkbookItemChangeState>;
}

export interface WorkbookItemChangeState {
  itemId: string;
  changedBy: string;
  isDeletion: boolean;
}

export interface WorkbookState extends IEntityWithId {
  id: string;
  description: string;
  year: number;
  month: number;
  weeks: Array<WorkbookWeekState>;
  midweekMeetingGroupedItemTypes?: Array<GroupedItemTypes>;
  weekendMeetingGroupedItemTypes?: Array<GroupedItemTypes>;
  isPrinting: boolean;
  selectedWeek?: WorkbookWeekState;
  midweekPublishStatus?: string;
  weekendPublishStatus?: string;
}

export interface WorkbookWeekState extends IEntityWithId {
  id: string;
  workbookId: string;
  week: string;
  midweekItems: Array<WorkbookWeekItemState>;
  weekendItems: Array<WorkbookWeekItemState>;
  days: Array<number>;
  source: string;
  fetchStatus?: string;
  fetchError?: string;
  isLoaded: boolean;
  isItemsLoaded: boolean;
  midweekDuration?: number;
  weekendDuration?: number;
}

export class GroupedItemTypes {
  constructor() {
    this.itemTypes = new Array<ItemTypes.ItemTypeState>();
  }

  section?: string;
  itemTypes: Array<ItemTypes.ItemTypeState>;
}

function download(blob: Blob, fileName: string) {
  const anchorElement = window.document.getElementById("anchor");
  if (anchorElement) {
    document.body.removeChild(anchorElement);
  }

  const anchor = window.document.createElement("a");
  anchor.id = "anchor";
  anchor.href = window.URL.createObjectURL(blob);
  anchor.download = fileName;
  anchor.target = "_blank";
  document.body.appendChild(anchor);
  anchor.click();
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface GoYearMonthAction {
  type: "WORKBOOK_GO_YEAR_MONTH";
  data: WorkbookState;
}

export interface GoPrevAction {
  type: "WORKBOOK_GO_PREV_MONTH";
  data: WorkbookState;
}

export interface GoNextAction {
  type: "WORKBOOK_GO_NEXT_MONTH";
  data: WorkbookState;
}

export interface SelectWeekAction {
  type: "WORKBOOK_SELECT_WEEK";
  data: WorkbookWeekState;
}

export interface GetWeekItemsRequestAction {
  type: "WORKBOOK_GET_WEEK_ITEMS_REQUEST";
  workbookId: string;
  workbookWeekId: string;
}

export interface GetWeekItemsResponseAction {
  type: "WORKBOOK_GET_WEEK_ITEMS_RESPONSE";
  workbookWeekId: string;
  items: Array<WorkbookWeekItemState>;
}

export interface FetchWeekItemQueuedResponse {
  type: "WORKBOOK_FETCH_WEEK_ITEMS_QUEUED_RESPONSE";
  workbookWeekId: string;
  success: boolean;
}

export interface GetItemResponse {
  type: "WORKBOOK_GET_ITEM_RESPONSE";
  item: WorkbookWeekItemState;
}

export interface WorkbookWeekItemSetChangedBy {
  type: "WORKBOOK_WEEK_ITEM_SET_CHANGED_BY";
  itemChange: WorkbookItemChangeState;
}

export interface WorkbookWeekSetFetchStatus {
  type: "WORKBOOK_WEEK_SET_FETCH_STATUS";
  workbookId: string;
  workbookWeekId: string;
  fetchStatus: string;
  fetchError: string;
}

export interface WorkbookWeekSourceUpdated {
  type: "WORKBOOK_WEEK_SOURCE_UPDATED";
  workbookId: string;
  workbookWeekId: string;
  source: string;
}

export interface WorkbookPublishStatusUpdated {
  type: "WORKBOOK_PUBLISH_STATUS_UPDATED";
  workbookId: string;
  meetingType: ItemTypes.MeetingType;
  status: string;
}

export interface PrintRequestAction {
  type: "WORKBOOK_PRINT_JOB_REQUEST";
}

export interface PrintResponseAction {
  type: "WORKBOOK_PRINT_JOB_RESPONSE";
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction =
  | GoYearMonthAction
  | GoPrevAction
  | GoNextAction
  | SelectWeekAction
  | GetItemTypePageRequestedAction
  | GetItemTypePageResponseAction
  | ItemTypeSearchRequestedAction
  | GetWeekItemsRequestAction
  | GetWeekItemsResponseAction
  | ItemTypeSelectedResponseAction
  | FetchWeekItemQueuedResponse
  | GetItemResponse
  | WorkbookWeekItemSetChangedBy
  | WorkbookWeekSetFetchStatus
  | WorkbookWeekSourceUpdated
  | WorkbookPublishStatusUpdated
  | PrintRequestAction
  | PrintResponseAction;

const unloadedState: WorkbookState = {
  id: "",
  description: "",
  isPrinting: false,
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  weeks: new Array<WorkbookWeekState>(),
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
  goYearMonth:
    (year: number, month: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        if (!getState().workbook?.midweekMeetingGroupedItemTypes) {
          ItemTypes.actionCreators.get(
            "midweek",
            "all",
            "",
            "",
            200
          )(dispatch, getState);
        }

        if (!getState().workbook?.weekendMeetingGroupedItemTypes) {
          ItemTypes.actionCreators.get(
            "weekend",
            "all",
            "",
            "",
            200
          )(dispatch, getState);
        }

        return httpGet<WorkbookState>(`Workbooks/${year}/${month}`).then(
          (workbook) => {
            dispatch({ type: "WORKBOOK_GO_YEAR_MONTH", data: workbook });
          }
        );
      },
  goNext: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const wb = getState().workbook;
    let year = (wb ?? unloadedState).year;
    let month = (wb ?? unloadedState).month;

    if (month === 12) {
      month = 1;
      year++;
    } else {
      month += 1;
    }

    return actionCreators.goYearMonth(year, month)(dispatch, getState);
  },
  goPrev: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const wb = getState().workbook;
    let year = (wb ?? unloadedState).year;
    let month = (wb ?? unloadedState).month;

    if (month === 1) {
      month = 12;
      year--;
    } else {
      month -= 1;
    }

    return actionCreators.goYearMonth(year, month)(dispatch, getState);
  },
  selectWeek:
    (week: WorkbookWeekState): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        dispatch({ type: "WORKBOOK_SELECT_WEEK", data: week });
      },
  addItem:
    (
      workbookId: string,
      workbookWeekId: string,
      meetingType: ItemTypes.MeetingType,
      itemTypeId: string,
      index: number
    ): AppThunkAction<KnownAction> =>
      (dispatch) => {
        return httpPost<string>(
          `Workbooks/${workbookId}/weeks/${workbookWeekId}/items`,
          new RequestParams(
            "meetingType",
            meetingType,
            "itemTypeId",
            itemTypeId,
            "index",
            index
          )
        ).then(() => {
          return httpGet<Array<WorkbookWeekItemState>>(
            `workbooks/${workbookId}/weeks/${workbookWeekId}/items`
          ).then((items) => {
            dispatch({
              type: "WORKBOOK_GET_WEEK_ITEMS_RESPONSE",
              workbookWeekId: workbookWeekId,
              items: items,
            });
          });
        });
      },
  deleteItem:
    (
      workbookId: string,
      workbookWeekId: string,
      itemId: string
    ): AppThunkAction<KnownAction> =>
      (dispatch) => {
        return httpDelete(
          `workbooks/${workbookId}/weeks/${workbookWeekId}/items`,
          itemId
        ).then((deleted) => {
          if (deleted) {
            httpGet<Array<WorkbookWeekItemState>>(
              `workbooks/${workbookId}/weeks/${workbookWeekId}/items`,
              new RequestParams("workbookWeekId", workbookWeekId)
            ).then((items) => {
              dispatch({
                type: "WORKBOOK_GET_WEEK_ITEMS_RESPONSE",
                workbookWeekId: workbookWeekId,
                items: items,
              });
            });
          }
        });
      },
  loadItem:
    (
      workbookId: string,
      workbookWeekId: string,
      itemId: string
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        return httpGet<WorkbookWeekItemState>(
          `workbooks/${workbookId}/weeks/${workbookWeekId}/items/${itemId}`
        ).then((item) => {
          dispatch({ type: "WORKBOOK_GET_ITEM_RESPONSE", item: item });
        });
      },
  loadItems:
    (workbookId: string, workbookWeekId: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        dispatch({
          type: "WORKBOOK_GET_WEEK_ITEMS_REQUEST",
          workbookId: workbookId,
          workbookWeekId,
        });

        return httpGet<Array<WorkbookWeekItemState>>(
          `workbooks/${workbookId}/weeks/${workbookWeekId}/items`
        ).then((items) => {
          dispatch({
            type: "WORKBOOK_GET_WEEK_ITEMS_RESPONSE",
            workbookWeekId: workbookWeekId,
            items: items,
          });
        });
      },
  loadItemTypes:
    (
      meetingType: ItemTypes.MeetingType,
      searchTerm: string
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        Promise.resolve(
          ItemTypes.actionCreators.get(
            meetingType,
            "active",
            searchTerm,
            "",
            200
          )(dispatch, getState)
        );
      },
  searchItemTypes:
    (
      meetingType: ItemTypes.MeetingType,
      searchTerm: string
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        Promise.resolve(
          ItemTypes.actionCreators.get(
            meetingType,
            "active",
            searchTerm,
            "",
            200
          )(dispatch, getState)
        );
      },
  selectNextItemType:
    (meetingType: ItemTypes.MeetingType): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        ItemTypes.actionCreators.selectNext(meetingType)(dispatch, getState);
      },
  selectPrevItemType:
    (meetingType: ItemTypes.MeetingType): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        ItemTypes.actionCreators.selectPrev(meetingType)(dispatch, getState);
      },
  fetchWeekItems:
    (workbookId: string, workbookWeekId: string): AppThunkAction<KnownAction> =>
      (dispatch) => {
        return httpPost<boolean>(
          `workbooks/${workbookId}/weeks/${workbookWeekId}/jwfetch`
        ).then((res) => {
          dispatch({
            type: "WORKBOOK_FETCH_WEEK_ITEMS_QUEUED_RESPONSE",
            workbookWeekId: workbookWeekId,
            success: res,
          });
        });
      },
  setChangedBy:
    (
      id: string,
      changedBy: string,
      isDeletion: boolean
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        return dispatch({
          type: "WORKBOOK_WEEK_ITEM_SET_CHANGED_BY",
          itemChange: {
            itemId: id,
            changedBy: changedBy,
            isDeletion: isDeletion,
          },
        });
      },
  setWeekFetchStatus:
    (
      workbookId: string,
      workbookWeekId: string,
      fetchStatus: string,
      fetchError: string
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        return dispatch({
          type: "WORKBOOK_WEEK_SET_FETCH_STATUS",
          workbookId,
          workbookWeekId,
          fetchStatus,
          fetchError,
        });
      },
  weekSourceUpdated:
    (
      workbookId: string,
      workbookWeekId: string,
      source: string
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        return dispatch({
          type: "WORKBOOK_WEEK_SOURCE_UPDATED",
          workbookId,
          workbookWeekId,
          source,
        });
      },

  publishStatusUpdated:
    (
      workbookId: string,
      meetingType: ItemTypes.MeetingType,
      status: string
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        return dispatch({
          type: "WORKBOOK_PUBLISH_STATUS_UPDATED",
          workbookId,
          meetingType,
          status,
        });
      },

  updateWeekSource:
    (
      workbookId: string,
      workbookWeekId: string,
      source: string
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        var params = new RequestParams("source", source);

        return httpPut<boolean>(
          `workbooks/${workbookId}/weeks/${workbookWeekId}/UpdateSource`,
          params
        ).then((res) => {
          return dispatch({
            type: "WORKBOOK_WEEK_SOURCE_UPDATED",
            workbookId,
            workbookWeekId,
            source,
          });
        });
      },
  createSchedule:
    (
      workbookId: string,
      meetingType: ItemTypes.MeetingType,
      fileName: string,
      fileFormat: "Odt" | "Docx2013",
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        dispatch({ type: "WORKBOOK_PRINT_JOB_REQUEST" });
        const contentType = fileFormat === "Odt"
          ? "application/vnd.oasis.opendocument.text"
          : "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

        return httpDownload(
          `workbooks/${workbookId}/ScheduleDocument`,
          new RequestParams("meetingType", meetingType, "fileFormat", fileFormat),
          contentType
        ).then((blob) => {
          download(blob, fileName);
          dispatch({ type: "WORKBOOK_PRINT_JOB_RESPONSE" });
        });
      },
  createAssignmentSlips:
    (
      workbookId: string,
      fileName: string,
      workbookWeekId?: string
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        dispatch({ type: "WORKBOOK_PRINT_JOB_REQUEST" });

        return httpDownload(
          `workbooks/${workbookId}/weeks/${workbookWeekId}/AssignmentSlipsPdf`,
          undefined,
          "application/zip"
        ).then((blob) => {
          download(blob, fileName);
          dispatch({ type: "WORKBOOK_PRINT_JOB_RESPONSE" });
        });
      },
  createChairmanOutline:
    (
      workbookId: string,
      meetingType: ItemTypes.MeetingType,
      fileName: string,
      workbookWeekId: string,
      fileFormat: "Odt" | "Docx2013"
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        dispatch({ type: "WORKBOOK_PRINT_JOB_REQUEST" });

        const contentType = fileFormat === "Odt"
          ? "application/vnd.oasis.opendocument.text"
          : "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

        return httpDownload(
          `workbooks/${workbookId}/weeks/${workbookWeekId}/ChairmanOutlineDocument`,
          new RequestParams("meetingType", meetingType, "fileFormat", fileFormat),
          contentType
        ).then((blob) => {
          download(blob, fileName);
          dispatch({ type: "WORKBOOK_PRINT_JOB_RESPONSE" });
        });
      },
};

const getUpdatedSelectedWeek = (
  selectedWeek?: WorkbookWeekState,
  weeks?: Array<WorkbookWeekState>
): WorkbookWeekState | undefined => {
  if (!selectedWeek) {
    return selectedWeek;
  }

  return weeks?.filter((w) => w.id === selectedWeek.id)[0];
};

export const reducer: Reducer<WorkbookState> = (
  state: WorkbookState | undefined,
  incomingAction: Action
): WorkbookState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "WORKBOOK_GO_YEAR_MONTH":
    case "WORKBOOK_GO_PREV_MONTH":
    case "WORKBOOK_GO_NEXT_MONTH":
      const today = new Date();
      const currentWeekDay = today.getUTCDay();
      const currentStartOfTheWeek = new Date(
        new Date().setDate(today.getDate() - currentWeekDay + 1)
      );
      const currentWeek = action.data.weeks.filter(
        (x) =>
          x.week.substring(0, 10) === currentStartOfTheWeek.toLocaleDateString()
      );

      return {
        ...state,
        id: action.data.id,
        description: action.data.description,
        month: action.data.month,
        year: action.data.year,
        weeks: action.data.weeks,
        midweekPublishStatus: action.data.midweekPublishStatus,
        weekendPublishStatus: action.data.weekendPublishStatus,
        selectedWeek:
          currentWeek.length === 1 ? currentWeek[0] : action.data.weeks[0],
      };
    case "WORKBOOK_SELECT_WEEK":
      return {
        ...state,
        selectedWeek: action.data,
      };
    case "ITEMTYPE_SELECTED_RESPONSE":
    case "GET_ITEMTYPE_PAGE_RESPONSE":
      let groupedItemTypes = new Array<GroupedItemTypes>();

      action.data.values.forEach((itemType) => {
        var group = groupedItemTypes.find(
          (x) => x.section === itemType.section
        );

        if (!group) {
          group = new GroupedItemTypes();
          group.section = itemType.section;
          groupedItemTypes.push(group);
        }

        group.itemTypes.push(itemType);
      });

      if (action.meetingType === "midweek") {
        return {
          ...state,
          midweekMeetingGroupedItemTypes: groupedItemTypes,
        };
      }

      return {
        ...state,
        weekendMeetingGroupedItemTypes: groupedItemTypes,
      };

    case "WORKBOOK_GET_WEEK_ITEMS_REQUEST":
      let getWeekItemsRequest = new Array<WorkbookWeekState>();

      state.weeks.forEach((w) => {
        if (w.id === action.workbookWeekId) {
          let newW = {
            workbookId: w.workbookId,
            id: w.id,
            week: w.week,
            midweekItems: w.midweekItems,
            weekendItems: w.weekendItems,
            days: w.days,
            isItemsLoaded: w.isItemsLoaded,
            source: w.source,
            fetchStatus: w.fetchStatus,
            fetchError: w.fetchError,
            isLoaded: false,
          };
          getWeekItemsRequest.push(newW);
        } else {
          getWeekItemsRequest.push(w);
        }
      });

      return {
        ...state,
        weeks: getWeekItemsRequest,
        selectedWeek: getUpdatedSelectedWeek(
          state.selectedWeek,
          getWeekItemsRequest
        ),
      };

    case "WORKBOOK_GET_WEEK_ITEMS_RESPONSE":
      const getDuration = (
        items: Array<WorkbookWeekItemState>
      ): number | undefined => {
        if (!items) {
          return undefined;
        }

        let duration = 0;

        items.forEach((i) => {
          if (i.durationMinutes) {
            const match = i.durationMinutes.match(/\d+/);
            if (match) {
              duration += parseInt(match[0] ?? "0");
            }
          }

          if (i.chairmanAdditionalTimeMinutes) {
            duration += i.chairmanAdditionalTimeMinutes;
          }
        });

        if (duration !== 0) {
          return duration;
        }

        return undefined;
      };

      let newWeeks = new Array<WorkbookWeekState>();

      state.weeks.forEach((w) => {
        if (w.id === action.workbookWeekId) {
          const midweekItems = action.items?.filter(
            (x) => x.meetingType === "midweek"
          );
          const weekendItems = action.items?.filter(
            (x) => x.meetingType === "weekend"
          );
          let newW = {
            workbookId: w.workbookId,
            id: w.id,
            week: w.week,
            midweekItems: midweekItems,
            weekendItems: weekendItems,
            days: w.days,
            isItemsLoaded: true,
            source: w.source,
            fetchStatus: w.fetchStatus,
            fetchError: w.fetchError,
            isLoaded: true,
            midweekDuration: getDuration(midweekItems),
            weekendDuration: getDuration(weekendItems),
          };
          newWeeks.push(newW);
        } else {
          newWeeks.push(w);
        }
      });

      return {
        ...state,
        weeks: newWeeks,
        selectedWeek: getUpdatedSelectedWeek(state.selectedWeek, newWeeks),
      };

    case "WORKBOOK_FETCH_WEEK_ITEMS_QUEUED_RESPONSE":
      return {
        ...state,
        weeks: state.weeks,
        selectedWeek: getUpdatedSelectedWeek(state.selectedWeek, state.weeks),
      };
    case "WORKBOOK_GET_ITEM_RESPONSE":
      let weeks3 = state.weeks;

      weeks3.forEach((w) => {
        if (!w.midweekItems || !w.weekendItems) {
          return;
        }

        const getUpdatedItems = (
          itemsToUpdate: WorkbookWeekItemState[]
        ): WorkbookWeekItemState[] => {
          let updatedItems = [...itemsToUpdate];
          let itemIndex = updatedItems.findIndex(
            (i) => i.id === action.item.id
          );

          if (itemIndex !== -1) {
            updatedItems.splice(itemIndex, 1, action.item);
          } else {
            updatedItems.splice(action.item.index, 0, action.item);
          }

          return [...updatedItems];
        };

        if (w.id === action.item.workbookWeekId) {
          switch (action.item.meetingType) {
            case "midweek":
              w.midweekItems = getUpdatedItems(w.midweekItems);
              break;

            case "weekend":
              w.weekendItems = getUpdatedItems(w.weekendItems);
              break;
            default:
              throw new Error(`${action.item.meetingType} not supported.`);
          }
        }
      });

      return {
        ...state,
        weeks: weeks3,
        selectedWeek: getUpdatedSelectedWeek(state.selectedWeek, weeks3),
      };

    case "WORKBOOK_WEEK_SET_FETCH_STATUS":
      let statusWeeks = [...state.weeks];
      let statusWeek = statusWeeks.find((w) => w.id === action.workbookWeekId);
      if (statusWeek) {
        statusWeek.fetchStatus = action.fetchStatus;
        statusWeek.fetchError = action.fetchError;
      }

      return {
        ...state,
        weeks: statusWeeks,
        selectedWeek: getUpdatedSelectedWeek(state.selectedWeek, statusWeeks),
      };

    case "WORKBOOK_WEEK_SOURCE_UPDATED":
      let sourceWeeks = [...state.weeks];
      let sourceWeek = sourceWeeks.find((w) => w.id === action.workbookWeekId);
      if (sourceWeek) {
        sourceWeek.source = action.source;
      }

      return {
        ...state,
        weeks: sourceWeeks,
        selectedWeek: getUpdatedSelectedWeek(state.selectedWeek, sourceWeeks),
      };

    case "WORKBOOK_PUBLISH_STATUS_UPDATED":
      if (state.id !== action.workbookId) {
        return {
          ...state,
        };
      } else
        return {
          ...state,
          midweekPublishStatus:
            action.meetingType === "midweek"
              ? action.status
              : state.midweekPublishStatus,
          weekendPublishStatus:
            action.meetingType === "weekend"
              ? action.status
              : state.weekendPublishStatus,
        };

    case "WORKBOOK_PRINT_JOB_REQUEST":
      return {
        ...state,
        isPrinting: true,
      };

    case "WORKBOOK_PRINT_JOB_RESPONSE":
      return {
        ...state,
        isPrinting: false,
      };

    default:
      return state;
  }
};

const changesUnloadedState: WorkbookChangesState = {
  id: "",
  itemChanges: new Array<WorkbookItemChangeState>(),
};

export const changesReducer: Reducer<WorkbookChangesState> = (
  state: WorkbookChangesState | undefined,
  incomingAction: Action
): WorkbookChangesState => {
  if (state === undefined) {
    return changesUnloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "WORKBOOK_WEEK_ITEM_SET_CHANGED_BY":
      let itemChanges = [...state.itemChanges];
      let existingChange = itemChanges.find(
        (x) => x.itemId === action.itemChange.itemId
      );
      if (existingChange && !action.itemChange.changedBy) {
        itemChanges.splice(
          itemChanges.findIndex((x) => x.itemId === action.itemChange.itemId),
          1
        );
      } else {
        itemChanges.push(action.itemChange);
      }

      return {
        ...state,
        itemChanges: itemChanges,
      };
    default:
      return state;
  }
};
