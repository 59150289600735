import { MeetingType } from "../ItemTypes/ItemTypes";
import { AppThunkAction } from "../store";
import { httpPut, RequestParam, RequestParams } from "../store/HttpUtils";
import { IEntityWithId } from "../store/IEntityWithId";

export interface WorkbookWeekItemState extends IEntityWithId {
  date: Date;
  itemTypeId: string;
  meetingType: MeetingType;
  description: string;
  index: number;
  durationMinutes: string;
  chairmanAdditionalTimeMinutes?: number;
  workbookId: string;
  workbookWeekId: string;
  handledBy: Array<ItemHandledByStore>;
  metadata: Array<ItemMetadataStore>;
}

export interface ItemHandledByStore {
  handledById: string;
  assignmentType: string;
  hideLabel: boolean;
  personId: string | null;
  personFullName: string;
  externalSourceMappingId: string;
}

export interface ItemMetadataStore {
  key: string;
  value: string;
  isInvalid: boolean;
}

export interface UpdateItemDescriptionAction {
  type: "UPDATE_ITEM_DESCRIPTION";
  description: string;
}

export type KnownAction = UpdateItemDescriptionAction;

export const actionCreators = {
  updateDescription:
    (
      workbookId: string,
      workbookWeekId: string,
      itemId: string,
      description: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      return httpPut(
        `workbooks/${workbookId}/weeks/${workbookWeekId}/items/${itemId}/UpdateDescription`,
        new RequestParams("description", description)
      );
    },
  updateDuration:
    (
      workbookId: string,
      workbookWeekId: string,
      itemId: string,
      duration: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      return httpPut(
        `workbooks/${workbookId}/weeks/${workbookWeekId}/items/${itemId}/UpdateDuration`,
        new RequestParams("duration", duration)
      );
    },
  updateMetadata:
    (
      workbookId: string,
      workbookWeekId: string,
      itemId: string,
      key: string,
      value: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      return httpPut(
        `workbooks/${workbookId}/weeks/${workbookWeekId}/items/${itemId}/UpdateMetadata`,
        new RequestParams("key", key, "value", value)
      );
    },
  updateHandledBy:
    (
      workbookId: string,
      workbookWeekId: string,
      itemId: string,
      handledById: string,
      personId: string | null,
      personFullName: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      var params = new RequestParams(
        "handledById",
        handledById,
        "personFullName",
        personFullName
      );

      if (personId) {
        params.push(new RequestParam("personId", personId));
      }

      return httpPut<boolean>(
        `workbooks/${workbookId}/weeks/${workbookWeekId}/items/${itemId}/UpdateHandledBy`,
        params
      );
    },
  clearHandledBy:
    (
      workbookId: string,
      workbookWeekId: string,
      itemId: string,
      handledById: string
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      var params = new RequestParams("handledById", handledById);

      return httpPut<boolean>(
        `workbooks/${workbookId}/weeks/${workbookWeekId}/items/${itemId}/UpdateHandledByToNone`,
        params
      );
    },
};

// const unloadedState: WorkbookWeekItemState = { meetingType: undefined, id: "", date: new Date(), itemTypeId: "", description: "", index: 0, durationMinutes: "", workbookWeekId: "", handledBy: [], metadata: [] };

// export const reducer: Reducer<WorkbookWeekItemState> = (state: WorkbookWeekItemState | undefined, incomingAction: Action): WorkbookWeekItemState => {
//   if (state === undefined) {
//     return unloadedState;
//   }

//   return state;
// };
