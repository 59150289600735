import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import * as WorkbookStore from "./Workbook";
import { HubConnection } from "@microsoft/signalr";
import { RotateSpinner } from "react-spinners-kit";
import NavigationButtons from "./NavigationButtons";
import WorkbookWeek from "./WorkbookWeek";
import { MeetingType } from "../ItemTypes/ItemTypes";

type WorkbookProps = WorkbookStore.WorkbookState & {
  hubConnection: HubConnection;
  userName: string;
} & typeof WorkbookStore.actionCreators;

class Workbook extends React.PureComponent<WorkbookProps> {
  state = {
    isLoaded: false,
  };

  async componentDidMount() {
    Promise.resolve(
      this.props.goYearMonth(
        new Date().getFullYear(),
        new Date().getMonth() + 1
      )
    ).then(() => {
      this.setState({ isLoaded: true });
    });

    const showHideChangedBy = async (
      itemId: string,
      userName: string,
      isDeletion: boolean,
      then?: () => void | Promise<void> | undefined
    ): Promise<void> => {
      const hideChangedBy = async (): Promise<void> => {
        await Promise.resolve(
          this.props.setChangedBy(itemId, "", isDeletion)
        ).then(() => {
          if (then) {
            then();
          }
        });
      };

      await Promise.resolve(
        this.props.setChangedBy(itemId, userName, isDeletion)
      );
      setTimeout(async () => await hideChangedBy(), 2000);
    };

    if (this.props.hubConnection) {
      this.props.hubConnection.on(
        "ItemAdded",
        async (
          workbookId: string,
          workbookWeekId: string,
          itemId: string,
          index: number,
          userName: string
        ) => {
          if (userName !== this.props.userName) {
            await Promise.resolve(
              this.props.loadItem(workbookId, workbookWeekId, itemId)
            );
            await showHideChangedBy(itemId, userName, false, undefined);
          }
        }
      );

      this.props.hubConnection.on(
        "ItemUpdated",
        async (
          workbookId: string,
          workbookWeekId: string,
          itemId: string,
          userName: string
        ) => {
          if (userName !== this.props.userName) {
            await Promise.resolve(
              this.props.loadItem(workbookId, workbookWeekId, itemId)
            );
            await showHideChangedBy(itemId, userName, false, undefined);
          }
        }
      );

      this.props.hubConnection.on(
        "ItemDeleted",
        async (
          workbookId: string,
          workbookWeekId: string,
          itemId: string,
          userName: string
        ) => {
          if (userName !== this.props.userName) {
            if (
              !this.props.weeks.find((x) => x.id === workbookWeekId)?.isLoaded
            ) {
              return;
            }

            const loadItems = async (): Promise<void> => {
              await Promise.resolve(
                this.props.loadItems(workbookId, workbookWeekId)
              );
            };

            await showHideChangedBy(itemId, userName, true, loadItems);
          }
        }
      );

      this.props.hubConnection.on(
        "WorkbookWeekFetchStatusUpdated",
        async (
          workbookId: string,
          workbookWeekId: string,
          fetchStatus: string,
          fetchError: string,
          userName: string
        ) => {
          if (userName !== this.props.userName) {
            await Promise.resolve(
              this.props.setWeekFetchStatus(
                workbookId,
                workbookWeekId,
                fetchStatus,
                fetchError
              )
            );
          }
        }
      );

      this.props.hubConnection.on(
        "WorkbookWeekSourceUpdated",
        async (
          workbookId: string,
          workbookWeekId: string,
          source: string,
          userName: string
        ) => {
          if (userName !== this.props.userName) {
            await Promise.resolve(
              this.props.weekSourceUpdated(workbookId, workbookWeekId, source)
            );
          }
        }
      );

      this.props.hubConnection.on(
        "WorkbookPublishStatusUpdated",
        async (
          workbookId: string,
          meetingType: MeetingType,
          status: string
        ) => {
          await Promise.resolve(
            this.props.publishStatusUpdated(workbookId, meetingType, status)
          );
        }
      );
    }
  }

  public render() {
    return (
      <React.Fragment>
        <RotateSpinner loading={!this.state.isLoaded} />
        {this.state.isLoaded && (
          <div className="programme pt-3">
            <NavigationButtons />
            <hr className="mb-4" />
            {this.props.selectedWeek && (
              <WorkbookWeek
                key={"week" + this.props.selectedWeek?.id}
                workbookId={this.props.id}
                week={this.props.selectedWeek}
                midweekPublishStatus={this.props.midweekPublishStatus}
                weekendPublishStatus={this.props.weekendPublishStatus}
              />
            )}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  id: state.workbook!.id,
  midweekPublishStatus: state.workbook!.midweekPublishStatus,
  weekendPublishStatus: state.workbook!.weekendPublishStatus,
  weeks: state.workbook!.weeks,
  selectedWeek: state.workbook!.selectedWeek,
  hubConnection: state.principal?.hubConnection,
  userName: state.principal?.email,
});

export default connect(
  mapStateToProps,
  WorkbookStore.actionCreators
)(Workbook as any);
