import React, { ComponentType } from "react";
import { FieldRenderProps } from "react-final-form";

export const TextField: ComponentType<
  FieldRenderProps<any, HTMLElement, any> & {
    label: any;
    readOnly: boolean;
    onChange: any;
  }
> = ({ input, label, readOnly, meta: { touched, error } }) => (
  <div className="input-group input-group-sm normal-text">
    {label && (
      <div className="input-group-prepend">
        <label className="input-group-text">{label}</label>
      </div>
    )}
    {readOnly && (
      <label {...input} className="form-control form-control-sm no-border">
        {input.value}
      </label>
    )}
    {!readOnly && (
      <input {...input} type="text" className="form-control form-control-sm" />
    )}
    {!readOnly && touched && error && (
      <span className="form-control-sm text-danger">{error}</span>
    )}
  </div>
);
