import * as React from "react";
import * as PersonPickerStoreState from "./PersonPickerStore";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { FaMale, FaFemale } from "react-icons/fa";
import { ImpulseSpinner } from "react-spinners-kit";
import PersonPickerPopoverList from "./PersonPickerPopoverList";
import { PersonPickerPopover } from "./PersonPickerPopover";
import { useState } from "react";
import Popover from "@mui/material/Popover/Popover";

type DispatchProps = {
  loadPersonsFor: (
    itemTypeId: string,
    handledById: string,
    date: Date,
    isFemale?: boolean,
    term?: string
  ) => void;
};

type Props = {
  personId: string | null;
  personFullName: string;
  itemTypeId: string;
  handledById: string;
  date: Date;
  onPickPerson: (personId: string, personFullName: string) => void;
  onClearPerson: () => void;
} & DispatchProps &
  PersonPickerStoreState.PersonFors;

const PersonPickerButton: React.FunctionComponent<Props> = (props: Props) => {
  const [isFemale, setIsFemale] = useState(false);
  const [term] = useState("");
  const [addAnchorEl, setAddAnchorEl] = useState(undefined);
  const [clearAnchorEl, setClearAnchorEl] = useState(undefined);

  const loadPersonsFor = (isFemale?: boolean) => {
    setIsFemale(isFemale ?? false);
    return props.loadPersonsFor(
      props.itemTypeId,
      props.handledById,
      props.date,
      isFemale,
      term
    );
  };

  const handlePickPersonOpen = async (event: any) => {
    setAddAnchorEl(event.currentTarget);
    loadPersonsFor();
  };

  const handlePickPersonClose = () => {
    setAddAnchorEl(undefined);
    setClearAnchorEl(undefined);
  };

  const handlePickPerson = async (personId: string, personFullName: string) => {
    props.onPickPerson(personId, personFullName);
    handlePickPersonClose();
  };

  const handleClearPersonOpen = (event: any) => {
    setClearAnchorEl(event.currentTarget);
  };

  const handleClearPersonClose = () => {
    setClearAnchorEl(undefined);
  };

  const handleClearPerson = async () => {
    props.onClearPerson();
  };

  const handleClearPersonKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      handleClearPerson();
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === "Escape") {
      handleClearPersonClose();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const renderPersons = () => {
    return (
      <PersonPickerPopoverList
        handledById={props.handledById}
        date={props.date}
        onClose={handlePickPersonClose}
        anchorEl={addAnchorEl}
        onPersonSelect={handlePickPerson}
      />
    );
  };

  if (props.personId) {
    return (
      <>
        <button
          style={{ padding: "0", marginLeft: "5px" }}
          className="btn no-border opacity-2"
          type="button"
          onClick={handleClearPersonOpen}
        >
          <span style={{ marginTop: "-3px" }} className="bi-person-x" />
        </button>
        {clearAnchorEl && (
          <Popover
            id={"delete-popover" + props.personId.toString()}
            open={Boolean(clearAnchorEl)}
            onClose={handleClearPersonClose}
            onKeyDown={(e) => handleClearPersonKeyDown(e)}
            anchorEl={clearAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="m-3">
              <button
                type="button"
                autoFocus={true}
                className="btn btn-warning"
                onClick={() => handleClearPerson()}
              >
                Clear
              </button>
            </div>
          </Popover>
        )}
      </>
    );
  } else {
    return (
      <span
        className=""
        style={{ marginLeft: "5px", marginRight: "5px", marginTop: "-1px" }}
      >
        <button
          style={{ padding: "0" }}
          className="btn no-border"
          type="button"
          onClick={handlePickPersonOpen}
        >
          <span style={{ marginTop: "-3px" }} className="bi-person-check" />
        </button>
        {addAnchorEl && (
          <PersonPickerPopover
            id={props.handledById}
            isOpen={Boolean(addAnchorEl)}
            anchorEl={addAnchorEl}
            onClose={handlePickPersonClose}
          >
            {props.isLoading && <ImpulseSpinner />}
            {!props.isLoading && props.hasMales && props.hasFemales && (
              <Tabs
                className="m-0"
                onSelect={(index: number) => loadPersonsFor(index === 1)}
                defaultIndex={Number(isFemale)}
              >
                <TabList>
                  <Tab>
                    <FaMale size="20" />
                    Brothers
                  </Tab>
                  <Tab>
                    <FaFemale size="20" />
                    Sisters
                  </Tab>
                </TabList>
                <TabPanel>{renderPersons()}</TabPanel>
                <TabPanel>{renderPersons()}</TabPanel>
              </Tabs>
            )}
            {!props.isLoading &&
              props.hasMales !== props.hasFemales &&
              renderPersons()}
          </PersonPickerPopover>
        )}
      </span>
    );
  }
};

interface DispatchFromProps {
  loadPersonsFor: (
    itemTypeId: string,
    handledById: string,
    date: Date,
    isFemale?: boolean,
    term?: string
  ) => void;
}

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  loadPersonsFor: (
    itemTypeId: string,
    handledById: string,
    date: Date,
    isFemale?: boolean,
    term?: string
  ) =>
    dispatch(
      PersonPickerStoreState.actionCreators.loadPersonsFor(
        itemTypeId,
        handledById,
        date,
        isFemale,
        term
      )
    ),
});

export default connect(
  (state: ApplicationState) => state.personFors,
  mapDispatchToProps
)(PersonPickerButton);
