import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import * as ItemTypePageResult from "./ItemTypes";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { RotateSpinner } from "react-spinners-kit";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ActiveType, MeetingType } from "./ItemTypes";
import { UserRole } from "../Users/User";

export type ItemTypesProps = ItemTypePageResult.ItemTypesState & {
  meetingType: MeetingType;
  activeType: ActiveType;
  roles?: Array<UserRole>;
} & typeof ItemTypePageResult.actionCreators;

const pageSize = 50;

const ItemTypes: React.FunctionComponent<ItemTypesProps> = (
  props: ItemTypesProps
) => {
  const [redirect, setRedirect] = useState("");
  const [loadingRequired, setLoadingRequired] = useState(true);

  useEffect(() => {
    if (!loadingRequired) {
      return;
    }

    setLoadingRequired(false);
    props.get(props.meetingType, props.activeType, "", "", pageSize);
  }, [loadingRequired, props]);

  const handleAdd = () => {
    setRedirect(
      appendReturnTo(`/itemtypes/new-${props.meetingType.toLocaleLowerCase()}/`)
    );
  };

  const handlePrevPage = () => {
    props.get(
      props.meetingType,
      props.activeType,
      "",
      props.tokens[props.pageNumber - 1] ?? "",
      pageSize
    );
  };

  const handleNextPage = () => {
    props.get(
      props.meetingType,
      props.activeType,
      "",
      props.continuationToken,
      pageSize
    );
  };

  const appendReturnTo = (redirect: string): string => {
    return `${redirect}?returnTo=/itemtypes/${props.meetingType.toLocaleLowerCase()}/${props.activeType.toLocaleLowerCase()}`;
  };

  if (redirect) {
    return <Redirect push={true} to={redirect} />;
  }

  const getSectionStyle = (section?: string): any => {
    return { color: getSectionColor(section) };
  };

  const getItemTypeStyle = (isActive: boolean): any => {
    return !isActive ? { color: "#D3D3D3" } : {};
  };

  const renderSections = (): any => {
    if (props.values.length === 0) {
      return null;
    }

    const groupedSections = props.values.reduce(
      (r: any, itemType: ItemTypePageResult.ItemTypeState) => {
        const section = itemType.section ?? "(No section)";
        r[section] = [...(r[section] || []), itemType];
        return r;
      },
      {}
    );

    return Object.keys(groupedSections).map((section: string) => {
      return groupedSections[section].map(
        (itemType: ItemTypePageResult.ItemTypeState, i: number) => {
          if (i === 0) {
            return (
              <React.Fragment key={"sectionContainer" + section}>
                <tr key={section}>
                  <td
                    className="pl-3 pr-3 font-weight-bold"
                    style={getSectionStyle(section)}
                    colSpan={2}
                  >
                    {section}
                  </td>
                </tr>
                <tr key={itemType.id}>
                  <td className="pl-5">
                    <a
                      href={appendReturnTo(`/itemtypes/${itemType.id}/details`)}
                      style={getItemTypeStyle(itemType.isActive)}
                    >
                      {itemType.description}
                    </a>
                  </td>
                  <td className="pl-3">{itemType.index}</td>
                </tr>
              </React.Fragment>
            );
          }

          return (
            <tr key={itemType.id}>
              <td className="pl-5">
                <a
                  href={appendReturnTo(`/itemtypes/${itemType.id}/details`)}
                  style={getItemTypeStyle(itemType.isActive)}
                >
                  {itemType.description}
                </a>
              </td>
              <td className="pl-3">{itemType.index}</td>
            </tr>
          );
        }
      );
    });
  };

  return (
    <>
      <p className="h4 pt-4">Item types</p>

      <hr />

      <RotateSpinner loading={props.isLoading} />

      {!props.isLoading && (
        <>
          {props.roles?.includes("Admin") && <button
            type="button"
            className="btn btn-primary float-left"
            onClick={handleAdd}
          >
            Add
          </button>}
          <div className="float-right">
            <nav className="page" aria-label="Page navigation">
              <ul className="pagination justify-content-end">
                <li key="filters">
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className=" float-left ml-5"
                    value={props.meetingType}
                    onChange={(args) => {
                      setRedirect(
                        `/itemtypes/${args.target.value.toLocaleLowerCase()}/${props.activeType.toLocaleLowerCase()}`
                      );
                    }}
                  >
                    <FormControlLabel
                      value="midweek"
                      control={<Radio size="small" color="primary" />}
                      label="Midweek"
                    />
                    <FormControlLabel
                      value="weekend"
                      control={<Radio size="small" color="primary" />}
                      label="Weekend"
                    />
                  </RadioGroup>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    className=" float-left ml-5"
                    value={props.activeType}
                    onChange={(args) => {
                      setRedirect(
                        `/itemtypes/${props.meetingType
                        }/${args.target.value.toLocaleLowerCase()}`
                      );
                    }}
                  >
                    <FormControlLabel
                      value="active"
                      control={<Radio size="small" color="primary" />}
                      label="Active"
                    />
                    <FormControlLabel
                      value="inactive"
                      control={<Radio size="small" color="secondary" />}
                      label="Inactive"
                    />
                    <FormControlLabel
                      value="all"
                      control={<Radio size="small" color="secondary" />}
                      label="All"
                    />
                  </RadioGroup>
                </li>

                <li
                  key="prevButton"
                  className={
                    props.pageNumber > 0 && !props.isLoading
                      ? "page-item"
                      : "page-item disabled"
                  }
                >
                  <button className="page-link" onClick={handlePrevPage}>
                    Previous
                  </button>
                </li>
                <li
                  key="nextButton"
                  className={
                    props.hasMoreResults && !props.isLoading
                      ? "page-item"
                      : "page-item disabled"
                  }
                >
                  <button className="page-link" onClick={handleNextPage}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>

          <table className="table table-hover table-sm mt-3 border">
            <thead>
              <tr>
                <th scope="col" className="pl-3">
                  Description
                </th>
                <th scope="col" className="pl-3">
                  Sort index
                </th>
              </tr>
            </thead>
            <tbody>{renderSections()}</tbody>
          </table>

          <div className="input-group-sm page float-right">
            Page {props.pageNumber + 1}
          </div>
        </>
      )}
    </>
  );
};

export default connect(
  (state: ApplicationState, ownProps: { meetingType: MeetingType }) =>
    ownProps.meetingType === "midweek"
      ? state.midweekItemTypes
      : ownProps.meetingType === "weekend"
        ? state.weekendItemTypes
        : [],
  ItemTypePageResult.actionCreators
)(ItemTypes as any);

export const getSectionColor = (section?: string) => {
  return section?.startsWith("TREASURES")
    ? "rgb(134, 191, 202)"
    : section?.startsWith("APPLY")
      ? "rgb(255, 205, 92)"
      : section?.startsWith("LIVING")
        ? "rgb(210, 118, 116)"
        : "#A1A1A1";
};
