import * as Workbook from "../Workbooks/Workbook";
import * as Item from "../Items/ItemStore";
import * as ItemType from "../ItemTypes/ItemType";
import * as ItemTypes from "../ItemTypes/ItemTypes";
import * as Person from "../Persons/Person";
import * as Persons from "../Persons/Persons";
import * as User from "../Users/User";
import * as Users from "../Users/Users";
import * as PersonPicker from "../PersonPicker/PersonPickerStore";
import * as Authentication from "../Auth/Authentication";
import * as AppActions from "./AppActions";
import * as ExternalSource from "../ExternalSources/ExternalSource";
import * as ExternalSources from "../ExternalSources/ExternalSources";

// The top-level state object
export interface ApplicationState {
  workbook: Workbook.WorkbookState | undefined;
  workbookChanges: Workbook.WorkbookChangesState | undefined;
  item: Item.WorkbookWeekItemState | undefined;
  itemType: ItemType.ItemTypeState | undefined;
  midweekItemTypes: ItemTypes.ItemTypesState | undefined;
  weekendItemTypes: ItemTypes.ItemTypesState | undefined;
  person: Person.PersonState | undefined;
  persons: Persons.PersonsState | undefined;
  user: User.UserState | undefined;
  users: Users.UsersState | undefined;
  personFors: PersonPicker.PersonFors | undefined;
  principal: Authentication.Principal | undefined;
  externalSource: ExternalSource.ExternalSourceState | undefined;
  externalSources: ExternalSources.ExternalSourcesState | undefined;
  appState: AppActions.AppState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
  workbook: Workbook.reducer,
  workbookChanges: Workbook.changesReducer,
  itemType: ItemType.reducer,
  midweekItemTypes: ItemTypes.reducer,
  weekendItemTypes: ItemTypes.reducer,
  person: Person.reducer,
  persons: Persons.reducer,
  user: User.reducer,
  users: Users.reducer,
  personFors: PersonPicker.reducer,
  principal: Authentication.reducer,
  externalSource: ExternalSource.reducer,
  externalSources: ExternalSources.reducer,
  appState: AppActions.reducer,
};

// This type can be used as a hint on action creators
// so that its 'dispatch' and 'getState' params are
// correctly typed to match our store.
export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
