import { Action, Reducer } from "redux";
import {
  ExternalSourceMappingState,
  GetPageRequestedAction,
  GetPageResponseAction,
} from "./ExternalSource";
import { AppThunkAction } from "../store";
import { httpGetPage } from "../store/HttpUtils";
import { PageResultState } from "../store/PageResultState";

interface ExternalSourceState {
  id: string;
  name: string;
  googleSpreadsheetId: string;
  dateColumn: string;
  mappings: ExternalSourceMappingState[];
}

export interface ExternalSourcesState
  extends PageResultState<ExternalSourceState> {}

export type KnownAction = GetPageRequestedAction | GetPageResponseAction;

export const actionCreators = {
  get:
    (
      continuationToken: string,
      pageSize: number
    ): AppThunkAction<KnownAction> =>
    (dispatch) => {
      dispatch({
        type: "GET_EXTERNAL_SOURCE_PAGE_REQUESTED",
        continuationToken,
        pageSize,
      });

      return httpGetPage<ExternalSourcesState>(
        `externalsources`,
        continuationToken,
        pageSize
      ).then((data) => {
        dispatch({ type: "GET_EXTERNAL_SOURCE_PAGE_RESPONSE", data: data });
      });
    },
};

const unloadedState: ExternalSourcesState = {
  values: [],
  isLoading: false,
  hasMoreResults: false,
  pageNumber: -1,
  tokens: [],
  continuationToken: "",
  sourceContinuationToken: "",
};

export const reducer: Reducer<ExternalSourcesState> = (
  state: ExternalSourcesState | undefined,
  incomingAction: Action
): ExternalSourcesState => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_EXTERNAL_SOURCE_PAGE_REQUESTED":
      return {
        ...state,
        continuationToken: action.continuationToken,
        isLoading: true,
      };
    case "GET_EXTERNAL_SOURCE_PAGE_RESPONSE":
      //TODO: extract this
      if (
        state.continuationToken === action.data.sourceContinuationToken ||
        !action.data.sourceContinuationToken
      ) {
        const tokenIndex = state.tokens.indexOf(
          action.data.sourceContinuationToken
        );
        const pageNumber = tokenIndex >= 0 ? tokenIndex : state.tokens.length;

        let tokens = state.tokens;
        if (tokenIndex === -1) {
          tokens = tokens.concat([action.data.sourceContinuationToken]);
        }

        return {
          ...state,
          continuationToken: action.data.continuationToken,
          values: action.data.values,
          hasMoreResults: action.data.hasMoreResults,
          pageNumber: pageNumber,
          tokens: tokens,
          isLoading: false,
        };
      }
      break;
  }

  return state;
};
