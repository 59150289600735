import { Action, Reducer } from 'redux';
import { GetPageRequestedAction, GetPageResponseAction } from './Person';
import { AppThunkAction } from '../store';
import { httpGet, RequestParams } from '../store/HttpUtils';
import { PageResultState } from '../store/PageResultState';
import { ActiveType } from '../ItemTypes/ItemTypes';

interface PersonState {
  id: string;
  fullName: string;
  comments?: string;
  isActive?: boolean;
  isFemale?: boolean;
}

export interface PersonsState extends PageResultState<PersonState> {
}

export type KnownAction = GetPageRequestedAction | GetPageResponseAction;

export const actionCreators = {
  get: (activeType: ActiveType, continuationToken: string, pageSize: number): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: 'GET_PERSON_PAGE_REQUESTED', activeType, continuationToken, pageSize });

    return httpGet<PersonsState>(
      `persons`,
      new RequestParams(
        "activeType", activeType,
        "continuationToken", continuationToken,
        "pageSize", pageSize))
      .then(data => {
        dispatch({ type: 'GET_PERSON_PAGE_RESPONSE', data: data });
      });
  }
};

const unloadedState: PersonsState = { values: [], isLoading: false, hasMoreResults: false, pageNumber: -1, tokens: [], continuationToken: "", sourceContinuationToken: "" };

export const reducer: Reducer<PersonsState> = (state: PersonsState | undefined, incomingAction: Action): PersonsState => {

  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case 'GET_PERSON_PAGE_REQUESTED':
      return {
        ...state,
        continuationToken: action.continuationToken,
        isLoading: true
      };
    case 'GET_PERSON_PAGE_RESPONSE':
      if (state.continuationToken === action.data.sourceContinuationToken || !action.data.sourceContinuationToken) {
        const tokenIndex = state.tokens.indexOf(action.data.sourceContinuationToken);
        const pageNumber = tokenIndex >= 0 ? tokenIndex : state.tokens.length;

        let tokens = state.tokens;
        if (tokenIndex === -1) {
          tokens = tokens.concat([action.data.sourceContinuationToken])
        }

        return {
          ...state,
          continuationToken: action.data.continuationToken,
          values: action.data.values,
          hasMoreResults: action.data.hasMoreResults,
          pageNumber: pageNumber,
          tokens: tokens,
          isLoading: false
        };
      }
      break;
  }

  return state;
};