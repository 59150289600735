import Popover from "@mui/material/Popover";
import React, { useState } from "react"
import { connect } from "react-redux"
import * as WorkbookStore from './Workbook';

type Props = {
  workbookId: string,
  weekId: string,
  itemId: string,
  itemIndex: number,
} & DispatchFromProps;

const ItemAddPicker: React.FunctionComponent<Props> = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteItemOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsDeleting(false);
  };

  const handleDeleteItemClose = () => {
    setAnchorEl(null);
    setIsDeleting(false);
  };

  const handleDeleteItem = async () => {
    setIsDeleting(true);

    Promise.resolve(props.deleteItem(props.workbookId, props.weekId, props.itemId)).then(() => {
      handleDeleteItemClose();
      setIsDeleting(false);
    });
  };

  const handleDeleteKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      handleDeleteItem();
      e.preventDefault();
      e.stopPropagation();
    }

    if (e.key === 'Escape') {
      handleDeleteItemClose();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  return (<>
    <button style={{ padding: "0", marginLeft: "10px" }} className="btn no-border opacity-2" type="button" onClick={handleDeleteItemOpen}>
      <span style={{ marginTop: "-3px" }} className="bi-trash" />
    </button>
    <Popover
      id={"delete-popover" + props.itemId.toString()}
      open={Boolean(anchorEl)}
      onClose={handleDeleteItemClose}
      onKeyDown={e => handleDeleteKeyDown(e)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>

      <div className="m-3">
        <button type="button" autoFocus={true} className="btn btn-danger" disabled={isDeleting} onClick={() => handleDeleteItem()}>Delete</button>
      </div>

    </Popover>
  </>)
}

interface DispatchFromProps {
  deleteItem: (workbookId: string, workbookWeekId: string, itemId: string) => void;
}

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  deleteItem: (workbookId: string, workbookWeekId: string, itemId: string) => dispatch(WorkbookStore.actionCreators.deleteItem(workbookId, workbookWeekId, itemId)),
});

export default connect(
  null,
  mapDispatchToProps
)(ItemAddPicker);
