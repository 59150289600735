import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { Action, Reducer } from "redux";
import { HubClient, UserInfo } from "./AuthContext";
import AppSettingsService from "../services/AppSettingsService";
import { AppThunkAction } from "../store";
import { UserRole } from "../Users/User";

export interface Principal extends UserInfo {
  isAuthenticated: boolean;
  status?: string;
  hubConnection?: HubConnection;
  hubClients?: Array<HubClient>;
}
export interface SignInSuccessResponseAction {
  type: "SIGNIN_SUCESS_RESPONSE";
  userInfo: UserInfo;
}
export interface SignInFailureResponseAction {
  type: "SIGNIN_FAILURE_RESPONSE";
  data?: string;
}
export interface SignOutSuccessResponseAction {
  type: "SIGNOUT_SUCCESS_RESPONSE";
}
export interface HubConnectedResponseAction {
  type: "SIGNALR_HUB_CONNECTED";
  hubConnection: HubConnection;
}
export interface HubClientsUpdatedResponseAction {
  type: "SIGNALR_HUB_CLIENTS_UPDATED";
  hubClients: Array<HubClient>;
}

export type KnownAction =
  | SignInSuccessResponseAction
  | SignInFailureResponseAction
  | SignOutSuccessResponseAction
  | HubConnectedResponseAction
  | HubClientsUpdatedResponseAction;

const appSettings = new AppSettingsService();
export const actionCreators = {
  signedIn:
    (user: UserInfo): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      if (!user || !user.accessToken) {
        dispatch({ type: "SIGNIN_FAILURE_RESPONSE" });
        return;
      }

      dispatch({
        type: "SIGNIN_SUCESS_RESPONSE",
        userInfo: user,
      });

      const hubConnection = new HubConnectionBuilder()
        .withUrl(appSettings.GetSignalRHubUri(), {
          accessTokenFactory: () => user.accessToken!,
        })
        .withAutomaticReconnect()
        .build();

      await hubConnection.start();

      hubConnection.onclose(() => {});

      hubConnection.on("ClientsUpdated", (hubClients) => {
        dispatch({
          type: "SIGNALR_HUB_CLIENTS_UPDATED",
          hubClients: hubClients,
        });
      });

      hubConnection.on("ConfirmOnline", () => {
        hubConnection.invoke("ConfirmOnline");
      });

      dispatch({
        type: "SIGNALR_HUB_CONNECTED",
        hubConnection: hubConnection,
      });
    },

  logOut: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
    const principal = getState().principal;

    dispatch({
      type: "SIGNALR_HUB_CLIENTS_UPDATED",
      hubClients: [],
    });

    if (principal?.hubConnection) {
      await principal.hubConnection.stop();
    }

    dispatch({ type: "SIGNOUT_SUCCESS_RESPONSE" });
  },
};

let unloadedState: Principal = {
  displayName: "",
  email: "",
  roles: new Array<UserRole>(),
  isAuthenticated: false,
  status: undefined,
  homeAccountId: "",
  picture: undefined,
};

export const reducer: Reducer<Principal> = (
  state: Principal | undefined,
  incomingAction: Action
): Principal => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "SIGNIN_SUCESS_RESPONSE":
      return {
        ...state,
        displayName: action.userInfo.displayName,
        email: action.userInfo.email,
        homeAccountId: action.userInfo.homeAccountId,
        roles: action.userInfo.roles,
        isAuthenticated: true,
        accessToken: action.userInfo.accessToken,
        status: undefined,
      };
    case "SIGNIN_FAILURE_RESPONSE":
      return {
        ...state,
        displayName: "",
        email: "",
        roles: new Array<UserRole>(),
        isAuthenticated: false,
        accessToken: undefined,
        status: action.data,
      };

    case "SIGNOUT_SUCCESS_RESPONSE":
      return {
        ...state,
        displayName: "",
        email: "",
        homeAccountId: "",
        roles: new Array<UserRole>(),
        isAuthenticated: false,
        accessToken: undefined,
        status: undefined,
      };
    case "SIGNALR_HUB_CONNECTED":
      return {
        ...state,
        hubConnection: action.hubConnection,
      };

    case "SIGNALR_HUB_CLIENTS_UPDATED":
      return {
        ...state,
        hubClients: action.hubClients,
      };

    default:
      return state;
  }
};
