import { Action, Reducer } from "redux";
import { AppThunkAction } from "../store";
import { PageResultState } from "../store/PageResultState";
import { httpGet, RequestParam, RequestParams } from "../store/HttpUtils";

export interface PersonFor {
  id: string;
  fullName: string;
  comments: string;
  restDays: string;
  wasDutyItem?: boolean;
  isSelected: boolean;
}

export interface PersonFors extends PageResultState<PersonFor> {
  hasMales: boolean;
  hasFemales: boolean;
  history: Array<ItemHistory>;
  isLoadingHistory: boolean;
}

export interface ItemHistory {
  id: string;
  itemDescription: string;
  assignmentType: string;
  otherHandledByPerson: string;
  otherAssignmentType: string;
  details: string;
  assignmentDate: Date;
}

export interface GetPersonForPageRequestAction {
  type: "GET_PERSON_FOR_REQUEST";
}
export interface GetPersonForPageResponseAction {
  type: "GET_PERSON_FOR_RESPONSE";
  data: PersonFors;
}
export interface PersonForSelectedResponseAction {
  type: "PERSON_FOR_SELECTED_RESPONSE";
  data: PersonFors;
}
export interface GetItemHistoryRequestAction {
  type: "GET_PERSON_ITEM_HISTORY_REQUEST";
}
export interface GetItemHistoryResponseAction {
  type: "GET_PERSON_ITEM_HISTORY_RESPONSE";
  data: ItemHistory[];
}

export type KnownAction =
  | GetPersonForPageResponseAction
  | PersonForSelectedResponseAction
  | GetPersonForPageRequestAction
  | GetItemHistoryRequestAction
  | GetItemHistoryResponseAction;

export const actionCreators = {
  loadPersonsFor:
    (
      itemTypeId: string,
      handledById: string,
      date: Date,
      isFemale?: boolean,
      term?: string
    ): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "GET_PERSON_FOR_REQUEST" });

      const createRequestParams = (
        pageSize: Number,
        isFemale: boolean
      ): RequestParams => {
        let requestParams = new RequestParams("pageSize", pageSize);

        requestParams.push(new RequestParam("itemTypeId", itemTypeId));
        requestParams.push(new RequestParam("handledById", handledById));
        requestParams.push(new RequestParam("date", date));
        requestParams.push(new RequestParam("isFemale", isFemale));
        requestParams.push(new RequestParam("term", term));

        return requestParams;
      };

      let _isFemale = isFemale ?? false;
      let data = await httpGet<PersonFors>(
        `persons/GetPersonsFor`,
        createRequestParams(200, _isFemale)
      );
      
      let otherData = await httpGet<PersonFors>(
        `persons/GetPersonsFor`,
        createRequestParams(1, !_isFemale)
      );

      if (
        data.values.length === 0 &&
        otherData.values.length > 0 &&
        (isFemale === undefined || isFemale === null)
      ) {
        _isFemale = !_isFemale;
        data = await httpGet<PersonFors>(
          `persons/GetPersonsFor`,
          createRequestParams(200, _isFemale)
        );
        otherData = await httpGet<PersonFors>(
          `persons/GetPersonsFor`,
          createRequestParams(1, !_isFemale)
        );
      }

      if (_isFemale) {
        data.hasFemales = data.values.length > 0;
        data.hasMales = otherData.values.length > 0;
      } else {
        data.hasFemales = otherData.values.length > 0;
        data.hasMales = data.values.length > 0;
      }

      dispatch({ type: "GET_PERSON_FOR_RESPONSE", data: data });
    },

  loadItemHistory:
    (personId: string, date: Date, restDays: number): AppThunkAction<KnownAction> =>
    async (dispatch, getState) => {
      dispatch({ type: "GET_PERSON_ITEM_HISTORY_REQUEST" });

      let requestParams = new RequestParams("pageSize", 20);
      requestParams.push(new RequestParam("date", date));
      requestParams.push(new RequestParam("restDays", restDays));

      const data = await httpGet<PageResultState<ItemHistory>>(
        `persons/${personId}/ItemHistory`,
        requestParams
      );

      dispatch({ type: "GET_PERSON_ITEM_HISTORY_RESPONSE", data: data.values });
    },

  selectNext: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    let personForsState = getState().personFors;

    if (!personForsState) {
      return;
    }

    let personFors = [...personForsState.values];

    const selectedPersonForIndex = personFors.findIndex((x) => x.isSelected);

    if (personFors.length > selectedPersonForIndex + 1) {
      if (selectedPersonForIndex >= 0) {
        personFors[selectedPersonForIndex].isSelected = false;
      }

      personFors[selectedPersonForIndex + 1].isSelected = true;
    }

    dispatch({
      type: "PERSON_FOR_SELECTED_RESPONSE",
      data: {
        hasMales: personForsState.hasMales,
        hasFemales: personForsState.hasFemales,
        continuationToken: personForsState.continuationToken,
        hasMoreResults: personForsState.hasMoreResults,
        isLoading: personForsState.isLoading,
        isLoadingHistory: personForsState.isLoadingHistory,
        pageNumber: personForsState.pageNumber,
        sourceContinuationToken: personForsState.sourceContinuationToken,
        tokens: personForsState.tokens,
        values: personFors,
        history: personForsState.history,
      },
    });
  },
  selectPrev: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
    let personForsState = getState().personFors;

    if (!personForsState) {
      return;
    }

    let personFors = [...personForsState.values];

    const selectedPersonForIndex = personFors.findIndex((x) => x.isSelected);

    if (selectedPersonForIndex <= 0) {
      return;
    }

    personFors[selectedPersonForIndex].isSelected = false;

    if (selectedPersonForIndex > 0) {
      personFors[selectedPersonForIndex - 1].isSelected = true;
    }

    dispatch({
      type: "PERSON_FOR_SELECTED_RESPONSE",
      data: {
        hasMales: personForsState.hasMales,
        hasFemales: personForsState.hasFemales,
        continuationToken: personForsState.continuationToken,
        hasMoreResults: personForsState.hasMoreResults,
        isLoading: personForsState.isLoading,
        isLoadingHistory: personForsState.isLoadingHistory,
        pageNumber: personForsState.pageNumber,
        sourceContinuationToken: personForsState.sourceContinuationToken,
        tokens: personForsState.tokens,
        values: personFors,
        history: personForsState.history,
      },
    });
  },
};

const unloadedState: PersonFors = {
  hasMales: false,
  hasFemales: false,
  values: [],
  isLoading: false,
  isLoadingHistory: false,
  history: [],
  hasMoreResults: false,
  pageNumber: -1,
  tokens: [],
  continuationToken: "",
  sourceContinuationToken: "",
};

export const reducer: Reducer<PersonFors> = (
  state: PersonFors | undefined,
  incomingAction: Action
): PersonFors => {
  if (state === undefined) {
    return unloadedState;
  }

  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "GET_PERSON_FOR_RESPONSE":
      const tokenIndex = state.tokens.indexOf(
        action.data.sourceContinuationToken
      );
      const pageNumber = tokenIndex >= 0 ? tokenIndex : state.tokens.length;

      let tokens = state.tokens;
      if (tokenIndex === -1) {
        tokens = tokens.concat([action.data.sourceContinuationToken]);
      }

      return {
        ...state,
        hasFemales: action.data.hasFemales,
        hasMales: action.data.hasMales,
        continuationToken: action.data.continuationToken,
        values: action.data.values,
        hasMoreResults: action.data.hasMoreResults,
        pageNumber: pageNumber,
        tokens: tokens,
        isLoading: false,
      };

    case "GET_PERSON_FOR_REQUEST":
      return {
        ...unloadedState,
        isLoading: true,
      };

    case "PERSON_FOR_SELECTED_RESPONSE":
      return {
        ...state,
        values: action.data.values,
        isLoading: false,
      };

    case "GET_PERSON_ITEM_HISTORY_REQUEST":
      return {
        ...state,
        isLoadingHistory: true,
        history: [],
      };

    case "GET_PERSON_ITEM_HISTORY_RESPONSE":
      return {
        ...state,
        isLoadingHistory: false,
        history: action.data,
      };

    default:
      return state;
  }
};
