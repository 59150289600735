import React from "react";

export const Avatar: React.FunctionComponent<{
  id: string;
  name: string;
  picturePath?: string;
  size: string;
}> = (props) => {
  const avatarClassName = `avatar${props.id}`;
  if (!props.picturePath) {
    return null;
  }

  return (
    <>
      <div
        className={avatarClassName}
        data-toggle="tooltip"
        title={props.name}
      ></div>
      <style>
        {`
                     div.${avatarClassName} {
                       background-image: url(${props.picturePath});
                       width: ${props.size};
                       height: ${props.size};
                       background-size: cover;
                       background-position: center center;
                       border-radius: 50%;
                     }
                   `}
      </style>
    </>
  );
};
