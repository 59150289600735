import React from "react";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import { AppState } from "../store/AppActions";

interface FooterProps {
  appState?: AppState
}


const Footer: React.FunctionComponent<FooterProps> = (props: FooterProps) => {
  return (
    <div>
      {props.appState?.executingAction && <label className="form-control-sm text-danger ml-5">{props.appState.executingAction}...</label>}
    </div>);
}

const mapStateToProps = (state: ApplicationState) => ({
  appState: state.appState
})


export default connect(
  mapStateToProps
)(Footer);

