import { User, UserManager } from "oidc-client-ts";
import { useEffect, useRef, useState } from "react";
import AppSettingsService from "../services/AppSettingsService";
import { UserRole } from "../Users/User";
import { UserInfo } from "./AuthContext";

export const HasAuthParams = (location = window.location): boolean => {
  // response_mode: query
  let searchParams = new URLSearchParams(location.search);
  if (
    (searchParams.get("code") || searchParams.get("error")) &&
    searchParams.get("state")
  ) {
    return true;
  }

  // response_mode: fragment
  searchParams = new URLSearchParams(location.hash.replace("#", "?"));
  if (
    (searchParams.get("code") || searchParams.get("error")) &&
    searchParams.get("state")
  ) {
    return true;
  }

  return false;
};

export const useAuth = (
  onSignedIn?: (userInfo?: UserInfo) => void
): [isLoading: boolean, signIn: () => void, signOut: () => void] => {
  const [isLoading, setIsLoading] = useState(false);
  const appSettings = new AppSettingsService();
  const loginAuthEndpoint = appSettings.GetLoginAuthEndpoint();
  const authClientId = appSettings.GetAuthClientId();
  const reactAppRedirectUri = appSettings.GetReactAppRedirectUri();
  const authClientSecret = appSettings.GetAuthClientSecret();

  const [userManager] = useState(() => {
    return new UserManager({
      authority: "https://accounts.google.com",
      client_id: authClientId,
      redirect_uri: reactAppRedirectUri,
      scope: "openid profile email",
      response_type: "code",
      loadUserInfo: true,
      client_secret: authClientSecret,
      automaticSilentRenew: true,
      includeIdTokenInSilentRenew: true,
      metadata: {
        issuer: "https://accounts.google.com",
        jwks_uri: "https://www.googleapis.com/oauth2/v3/certs",
        revocation_endpoint: "https://oauth2.googleapis.com/revoke",
        authorization_endpoint: "https://accounts.google.com/o/oauth2/v2/auth",
        token_endpoint: "https://oauth2.googleapis.com/token",
        userinfo_endpoint: "https://openidconnect.googleapis.com/v1/userinfo",
      },
    });
  });

  const signIn = async () => {
    await userManager.signinRedirect();
  };

  const signOut = async () => {
    await userManager.revokeTokens();
    await userManager.removeUser();
  };

  const didInitialize = useRef(false);

  useEffect(() => {
    const handleUserLoaded = async (user: User) => {
      const accessToken = user.access_token;
      const email = user.profile.email as string;
      const authUrl = new URL(loginAuthEndpoint);

      fetch(authUrl.toString(), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
          email: email,
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            await userManager.removeUser();
            console.error(response.statusText);
            onSignedIn?.(undefined);
            setIsLoading(false);
            return;
          }

          const roles = await Promise.resolve(
            response.json() as Promise<UserRole[]>
          );

          const userInfo: UserInfo = {
            displayName: user.profile.name as string,
            email: user.profile.email as string,
            homeAccountId: user.profile.sub,
            picture: new URL(String(user.profile.picture)),
            roles: roles,
            accessToken: user.access_token,
          };

          onSignedIn?.(userInfo);
          setIsLoading(false);
        })
        .catch((response) => {
          console.error(response);
        });
    };

    setIsLoading(HasAuthParams());

    if (!userManager || didInitialize.current) {
      return;
    }

    const onSigninCallback = async (_user: User | void): Promise<void> => {
      window.history.replaceState({}, document.title, window.location.pathname);
    };

    didInitialize.current = true;

    void (async (): Promise<void> => {
      if (HasAuthParams()) {
        const user = await userManager.signinCallback();
        await onSigninCallback(user);
      }

      const user = await userManager.getUser();
      if (user) {
        await handleUserLoaded(user);
      }
    })();

    //could be a separate effect?
    userManager.events.addUserLoaded(handleUserLoaded);
    return () => {
      userManager.events.removeUserLoaded(handleUserLoaded);
      setIsLoading(false);
    };
  }, [userManager, loginAuthEndpoint, onSignedIn]);

  return [isLoading, signIn, signOut];
};
