import { connect } from "react-redux";
import * as WorkbookStore from "./Workbook";
import React, { useState } from "react";
import { ItemButtons } from "./ItemButtons";
import Switch from "@mui/material/Switch/Switch";
import { MeetingType } from "../ItemTypes/ItemTypes";
import LocalMenu, { LocalMenuItem } from "./LocalMenu";
import WorkbookRevisionInfo from "./Revisions/WorkbookRevisionInfo";

export type AdditionalData = {
  data: string;
  tooltip?: string;
  onChanged?: (newValue: string) => void;
};

type Props = {
  workbookId: string;
  weekId: string;
  friendlyWeekName: string;
  meeting: string;
  meetingType: MeetingType;
  isLoaded: boolean;
  isItemsLoaded: boolean;
  additionalData: AdditionalData[];
  canFetch: boolean;
  canEdit: boolean;
  canEditWeek: boolean;
  editMode: boolean;
  onEditModeChanged: (editMode: boolean) => void;
} & DispatchFromProps;

const WeekHeader: React.FunctionComponent<Props> = (props: Props) => {
  const handleSwitchChange = async (e: any): Promise<void> => {
    if (!props.isItemsLoaded) {
      return;
    }

    //setEditMode(e.target.checked);
    props.onEditModeChanged(e.target.checked);
  };

  const headerStyle = {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d2e7f9",
    backgroundColor: "#d2e7f9",
  };

  const headerMainElementStyle = {
    fontWeight: "bold",
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    paddingTop: "7px",
    paddingBottom: "7px",
    paddingLeft: "10px",
  };

  const headerAdditionalElementStyle = {
    fontSize: "1rem",
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    marginTop: "0px",
    marginBottom: "0px",
  };

  const midweekMenuItems: LocalMenuItem[] = [
    {
      description: "Chairman outline (docx)",
      onClick: () =>
        props.createChairmanOutline(
          props.workbookId,
          props.meetingType,
          `Chairman outline ${props.friendlyWeekName}.docx`,
          props.weekId,
          "Docx2013"
        ),
    },
    {
      description: "Chairman outline (odt)",
      onClick: () =>
        props.createChairmanOutline(
          props.workbookId,
          props.meetingType,
          `Chairman outline ${props.friendlyWeekName}.odt`,
          props.weekId,
          "Odt"
        ),
    },
    {
      description: "Student slips (zip)",
      onClick: () =>
        props.createAssignmentSlips(
          props.workbookId,
          `${props.friendlyWeekName}.zip`,
          props.weekId
        ),
    },
  ];

  if (props.editMode && props.canFetch) {
    midweekMenuItems.push({
      description: "Fetch from jw.org",
      onClick: () => props.fetchWeekItems(props.workbookId, props.weekId),
    });
  }

  const weekendMenuItems: LocalMenuItem[] = [];

  if (props.editMode) {
    midweekMenuItems.push({
      description: "Add auto items",
      onClick: () => {
        return;
      },
    });

    weekendMenuItems.push({
      description: "Add auto items",
      onClick: () => {
        return;
      },
    });
  }

  return (
    <div key={props.weekId} style={headerStyle}>
      <div key={props.meeting} style={headerMainElementStyle}>
        <div key={props.meeting}>{props.meeting}</div>
        <div
          key="additional-data"
          style={headerAdditionalElementStyle}
          className="text-muted text-lighter"
        >
          {props.additionalData.map((data) => (
            <AdditionalDataComponent
              key={data.data}
              data={data}
              canEdit={props.canEdit}
              editMode={props.editMode}
            />
          ))}
        </div>
      </div>

      {props.isLoaded && (
        <div
          key={props.weekId}
          style={headerAdditionalElementStyle}
          className="text-lighter"
        >
          {props.canEditWeek && props.editMode && props.canEdit && props.isItemsLoaded && (
            <ItemButtons
              workbookId={props.workbookId}
              weekId={props.weekId}
              meetingType={props.meetingType}
              itemId={""}
              itemIndex={-1}
              renderDelete={false}
            />
          )}

          {!props.editMode && props.isItemsLoaded && (
            <WorkbookRevisionInfo
              workbookId={props.workbookId}
              meetingType={props.meetingType}
            />
          )}

          <LocalMenu
            key={"menu" + props.weekId + props.meetingType.toLocaleLowerCase()}
            items={
              props.meetingType === "midweek"
                ? midweekMenuItems
                : weekendMenuItems
            }
          />

          {props.canEditWeek && <Switch
            size="small"
            checked={props.editMode}
            onChange={(x) => handleSwitchChange(x)}
          />}
        </div>
      )}
    </div>
  );
};

type AdditionalDataComponentProps = {
  data: AdditionalData;
  canEdit: boolean;
  editMode: boolean;
};

function AdditionalDataComponent({
  data,
  canEdit,
  editMode,
}: AdditionalDataComponentProps) {
  const shouldEdit = Boolean(data.onChanged) && canEdit && editMode;
  const [dataValue, setDataValue] = useState(data.data);

  return (
    <div
      key={data.data}
      className="ml-3 font-lighter"
      data-toggle="tooltip"
      title={data.tooltip}
    >
      {shouldEdit && (
        <input
          key={data.data}
          className="font-lighter no-border no-background"
          defaultValue={dataValue}
          type="text"
          onBlur={(e) => {
            if (data.onChanged) {
              setDataValue(e.currentTarget.value);
              data.onChanged(e.currentTarget.value);
            }
          }}
        />
      )}
      {!shouldEdit && <span>{data.data}</span>}
    </div>
  );
}

interface DispatchFromProps {
  fetchWeekItems: (workbookId: string, workbookWeekId: string) => any;
  createChairmanOutline: (
    workbookId: string,
    meetinType: MeetingType,
    fileName: string,
    workbookWeekId: string,
    fileFormat: "Odt" | "Docx2013",
  ) => any;
  createAssignmentSlips: (
    workbookId: string,
    fileName: string,
    workbookWeekId: string
  ) => any;
}

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  fetchWeekItems: (workbookId: string, workbookWeekId: string) =>
    dispatch(
      WorkbookStore.actionCreators.fetchWeekItems(workbookId, workbookWeekId)
    ),
  createChairmanOutline: (
    workbookId: string,
    meetinType: MeetingType,
    fileName: string,
    workbookWeekId: string,
    fileFormat: "Odt" | "Docx2013"
  ) =>
    dispatch(
      WorkbookStore.actionCreators.createChairmanOutline(
        workbookId,
        meetinType,
        fileName,
        workbookWeekId,
        fileFormat
      )
    ),
  createAssignmentSlips: (
    workbookId: string,
    fileName: string,
    workbookWeekId: string
  ) =>
    dispatch(
      WorkbookStore.actionCreators.createAssignmentSlips(
        workbookId,
        fileName,
        workbookWeekId
      )
    ),
});

export default connect(null, mapDispatchToProps)(WeekHeader);
