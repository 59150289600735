import React, { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getRevisionsQuery } from './WorkboonRevisionsApi';
import { SVGProps } from "react";
import { MeetingType } from "../../ItemTypes/ItemTypes";
import { httpPost, RequestParams } from "../../store/HttpUtils";

export default function WorkbookRevisionInfo({
  workbookId,
  meetingType,
}: {
  workbookId: string;
  meetingType: MeetingType;
}) {
  const { queryKey, queryFn } = getRevisionsQuery(workbookId);

  const { isLoading, data } = useQuery(
    queryKey,
    queryFn,
    {
      refetchInterval: 100 * 1000,
      cacheTime: 1000,
    });

  if (meetingType === "midweek") {
    return <WorkbookPublishButton
      workbookId={workbookId}
      meetingType={meetingType}
      headRevision={data?.midweekHeadRevision}
      publishedRevision={data?.midweekPublishedRevision}
      isLoading={isLoading} />;
  }

  return <WorkbookPublishButton
    workbookId={workbookId}
    meetingType={meetingType}
    headRevision={data?.weekendHeadRevision}
    publishedRevision={data?.weekendPublishedRevision}
    isLoading={isLoading} />;
}

function WorkbookPublishButton({
  workbookId,
  meetingType,
  headRevision,
  publishedRevision,
  isLoading,
}: {
  workbookId: string;
  meetingType: MeetingType;
  headRevision: string | undefined;
  publishedRevision: string | undefined;
  isLoading: boolean;
}) {
  const queryClient = useQueryClient();
  const [isPublishing, setIsPublishing] = useState(false);

  const publishSchedule =
    () => {
      setIsPublishing(true);
      httpPost<boolean>(
        `workbooks/${workbookId}/Schedule/Publish`,
        new RequestParams("meetingType", meetingType)
      ).then(async () => {
        const { queryKey } = getRevisionsQuery(workbookId);
        await queryClient.invalidateQueries(queryKey);

        setIsPublishing(false);
      });
    };

  if (isPublishing || isLoading) {
    const title =
      meetingType === "midweek"
        ? "Midweek meeting noticeboard is being updated"
        : "Weekend meeting noticeboard is being updated";

    return <span title={title} className="btn no-border"><CloudBusy /></span>;
  }
  else
    if (headRevision !== publishedRevision) {
      const title =
        meetingType === "midweek"
          ? "Midweek meeting noticeboard is out of date"
          : "Weekend meeting noticeboard is out of date";

      return (
        <button
          type="button"
          className="btn no-border"
          data-toggle="tooltip"
          title={title}
          onClick={() => publishSchedule()}
        >
          <CloudWarning />
        </button>
      );
    }
    else {
      const title =
        meetingType === "midweek"
          ? "Midweek meeting noticeboard is up to date"
          : "Weekend meeting noticeboard is up to date";

      return <span title={title} className="btn no-border"><CloudOk /></span>;
    }
}

const CloudWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    color="red"
    viewBox="0 0 16 16"
    height={16}
    width={16}
    {...props}
  >
    <g id="cloud-warning--cloud-network-internet-server-warning-alert">
      <path
        id="Vector"
        stroke="red"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.179 12.374h1.364a2.925 2.925 0 0 0 0.654 -5.773 0.264 0.264 0 0 1 -0.185 -0.214A4.144 4.144 0 0 0 3.964 5.411a0.243 0.243 0 0 1 -0.225 0.136 3.421 3.421 0 0 0 -2.021 5.988c0.682 0.597 1.495 0.838 2.48 0.838h0.621"
        strokeWidth={1}
      />
      <path
        id="Vector_2"
        stroke="red"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5 7.665v3.75"
        strokeWidth={1}
        color="red"
      />
      <g id="Group 4488">
        <path
          id="Ellipse 81"
          stroke="red"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.5 14.196a0.268 0.268 0 1 1 0 -0.536"
          strokeWidth={1}
        />
        <path
          id="Ellipse 82"
          stroke="red"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.5 14.196a0.268 0.268 0 1 0 0 -0.536"
          strokeWidth={1}
        />
      </g>
    </g>
  </svg>
);

const CloudOk = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    height={16}
    width={16}
    {...props}
  >
    <g id="cloud-check--cloud-network-internet-check-server-approve">
      <path
        id="Vector"
        stroke="green"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m10.503 9.161 -3.435 3.864 -1.718 -1.288"
        strokeWidth={1}
      />
      <path
        id="Vector_2"
        stroke="green"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.542 11.778a2.925 2.925 0 0 0 0.654 -5.773 0.264 0.264 0 0 1 -0.185 -0.214 4.144 4.144 0 0 0 -8.045 -0.975 0.244 0.244 0 0 1 -0.225 0.136 3.42 3.42 0 0 0 -0.273 6.796"
        strokeWidth={1}
      />
    </g>
  </svg>
);

const CloudBusy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    height={16}
    width={16}
    {...props}
  >
    <g id="cloud-data-transfer--cloud-data-transfer-internet-server-network">
      <path
        id="Vector"
        stroke="blue"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4.269 9.48 2.109 -1.864v5.591"
        strokeWidth={1}
      />
      <path
        id="Vector_2"
        stroke="blue"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m10.732 11.343 -2.11 1.863V7.616"
        strokeWidth={1}
      />
      <path
        id="Vector_3"
        stroke="blue"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.2 11.616a3.421 3.421 0 0 1 -0.459 -6.825 0.244 0.244 0 0 0 0.225 -0.137 4.144 4.144 0 0 1 8.044 0.975 0.264 0.264 0 0 0 0.185 0.214 2.925 2.925 0 0 1 0.538 5.511"
        strokeWidth={1}
      />
    </g>
  </svg>
);
