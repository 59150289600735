import React from "react";
import * as PersonStore from "./Person";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";
import { PersonForm } from "./PersonFormComponent";
import { UserRole } from "../Users/User";

//TODO: export types using index
type PersonProps = typeof PersonStore.actionCreators & {
  readOnly: boolean;
  roles: Array<UserRole> | undefined;
  person: PersonStore.PersonState | undefined;
} & RouteComponentProps<{ id?: string; returnTo: string }>;

class Person extends React.PureComponent<PersonProps> {
  state = {
    redirect: undefined,
    readOnly: this.props.readOnly,
    id: this.props.match.params.id,
    returnTo: new URLSearchParams(this.props.location.search).get("returnTo"),
  };

  async componentDidMount() {
    if (this.state.id) {
      this.props.get(this.state.id);
    } else {
      this.props.reset();
    }
  }

  componentDidUpdate(
    prevProps: PersonProps,
    prevState: PersonStore.PersonState
  ) {
    if (this.props.person?.savingSucceeded) {
      this.setState({
        redirect: this.state.returnTo,
      });
    }
  }

  save = async (entity: PersonStore.PersonState): Promise<any> => {
    return this.props.save(entity);
  };

  private handleCancel = () => {
    if (!this.state.readOnly) {
      if (!this.state.id) {
        this.setState({ redirect: this.state.returnTo });
      } else {
        this.setState({ readOnly: true });
      }
    }
  };

  private handleClose = () => {
    if (this.state.readOnly) {
      this.setState({ redirect: this.state.returnTo });
    }
  };

  private handleEdit = () => {
    if (this.state.readOnly) {
      this.setState({ readOnly: false });
    }
  };

  private handleDelete = () => {
    if (this.state.id) {
      Promise.resolve(this.props.delete(this.state.id)).then(() => {
        this.setState({ redirect: this.state.returnTo });
      });
    }
  };

  public render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect || ``} />;
    }

    const isScheduleWriter = this.props.roles?.includes("ScheduleWriter");

    return (
      <PersonForm
        readOnly={this.state.readOnly}
        initialValues={this.props.person}
        onSubmit={this.save}
        onCancel={this.handleCancel}
        onEdit={isScheduleWriter ? this.handleEdit : undefined}
        onClose={this.handleClose}
        onDelete={isScheduleWriter ? this.handleDelete : undefined}
      />
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  person: state.person,
  roles: state.principal?.roles,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      get: PersonStore.actionCreators.get,
      reset: PersonStore.actionCreators.reset,
      delete: PersonStore.actionCreators.delete,
      save: PersonStore.actionCreators.save,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Person);
