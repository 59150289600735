import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { WorkbookWeekState } from "./Workbook";
import * as WorkbookStore from "./Workbook";
import WeekHeader, { AdditionalData } from "./WeekHeader";
import { MeetingType } from "../ItemTypes/ItemTypes";
import { WorkbookWeekItemState } from "../Items/ItemStore";
import { WorkbookWeekItem } from "./WorkbookWeekItem";
import { HubClient } from "../Auth/AuthContext";
import { ApplicationState } from "../store";
import { UserRole } from "../Users/User";
import { getSectionColor } from "../ItemTypes/ItemTypesComponent";

type Props = {
  workbookId: string;
  midweekPublishStatus?: string;
  weekendPublishStatus?: string;
  week: WorkbookWeekState;
  itemChanges: Array<WorkbookStore.WorkbookItemChangeState>;
  hubClients: Array<HubClient>;
  roles: Array<UserRole>;
  getSection(itemTypeId: string): string | undefined;
};

const WorkbookWeek: React.FunctionComponent<Props> = (props: Props) => {
  const [loadingRequired, setLoadingRequired] = useState(true);

  useEffect(() => {
    if (!loadingRequired) {
      return;
    }

    setLoadingRequired(false);
    (props as any).loadItems(props.workbookId, props.week.id);
  }, [
    loadingRequired,
    props,
    props.week,
    props.week.midweekItems,
    props.week.weekendItems,
  ]);

  const canEdit =
    props.roles?.includes("ScheduleWriter") &&
    props.week.isLoaded &&
    props.week.isItemsLoaded;

  const canAssignItem = (itemTypeId: string, handledById: string): boolean => {
    return props.roles?.includes(`ScheduleWriter:${itemTypeId}:${handledById}`)
      && props.week.isLoaded &&
      props.week.isItemsLoaded;
  }

  const canEditWeek =
    !!props.roles?.find(x => x === 'ScheduleWriter') &&
    !!props.roles?.find(x => x.startsWith('ScheduleWriter')) &&
    props.week.isLoaded &&
    props.week.isItemsLoaded;

  const canFetch = canEdit && props.week.midweekItems.length === 0;

  const weekStart = props.week.week;
  const friendlyWeekName = `${weekStart.substring(0, 2)}-${weekStart.substring(
    3,
    5
  )}-${weekStart.substring(6, 10)}`;

  const getDefaultHandledBy = (items: WorkbookWeekItemState[]) => {
    const chairmen = items?.filter((x) =>
      x.description?.toLocaleLowerCase().includes("chairman")
    );

    if (
      chairmen &&
      chairmen.length > 0 &&
      chairmen[0].handledBy.length > 0 &&
      chairmen[0].handledBy[0].personFullName
    ) {
      return chairmen[0].handledBy[0].personFullName;
    }

    return "Chairman";
  };

  return (
    <div>
      <Meeting
        key="midweek"
        workbookId={props.workbookId}
        weekId={props.week.id}
        publishStatus={props.midweekPublishStatus}
        defaultHandledBy={getDefaultHandledBy(props.week.midweekItems)}
        friendlyWeekName={friendlyWeekName}
        meeting="LIFE AND MINISTRY"
        meetingType="midweek"
        getSection={props.getSection}
        meetingItems={props.week.midweekItems}
        isLoaded={props.week.isLoaded}
        isItemsLoaded={props.week.isItemsLoaded}
        canEdit={canEdit}
        canFetch={canFetch}
        itemChanges={props.itemChanges}
        hubClients={props.hubClients}
        duration={props.week.midweekDuration}
        source={props.week.source}
        sourceChanged={(newSource: string) =>
          (props as any).updateWeekSource(
            props.workbookId,
            props.week.id,
            newSource
          )
        }
        fetchError={props.week.fetchError}
        fetchStatus={props.week.fetchStatus}
        canEditItem={canAssignItem}
        canEditWeek={canEditWeek}
      />

      {/* <Meeting
        key="weekend"
        workbookId={props.workbookId}
        publishStatus={props.weekendPublishStatus}
        weekId={props.week.id}
        defaultHandledBy={getDefaultHandledBy(props.week.weekendItems)}
        friendlyWeekName={friendlyWeekName}
        meeting="PUBLIC TALK AND WATCHTOWER"
        meetingType="weekend"
        getSection={props.getSection}
        meetingItems={props.week.weekendItems}
        isLoaded={props.week.isLoaded}
        isItemsLoaded={props.week.isItemsLoaded}
        canEdit={canEdit}
        canFetch={false}
        itemChanges={props.itemChanges}
        hubClients={props.hubClients}
        duration={props.week.weekendDuration}
        canEditItem={canAssignItem}
        canEditWeek={canEditWeek}
      /> */}
    </div>
  );
};

type MeetingParams = {
  workbookId: string;
  weekId: string;
  publishStatus?: string;
  defaultHandledBy: string;
  friendlyWeekName: string;
  meetingType: MeetingType;
  meeting: string;
  getSection(itemTypeId: string): string | undefined;
  meetingItems: WorkbookWeekItemState[] | undefined;
  isLoaded: boolean;
  isItemsLoaded: boolean;
  canEdit: boolean;
  canFetch: boolean;
  itemChanges: Array<WorkbookStore.WorkbookItemChangeState>;
  hubClients: Array<HubClient>;
  duration?: number;
  source?: string;
  sourceChanged?: (newSource: string) => void;
  fetchError?: string;
  fetchStatus?: string;
  canEditItem: (itemTypeId: string, handledById: string) => boolean;
  canEditWeek: boolean;
};

function Meeting({
  workbookId,
  weekId,
  publishStatus,
  defaultHandledBy,
  friendlyWeekName,
  meetingType,
  meeting,
  getSection,
  meetingItems,
  isLoaded,
  isItemsLoaded,
  canEdit,
  canFetch,
  itemChanges,
  hubClients,
  duration,
  source,
  sourceChanged,
  fetchError,
  fetchStatus,
  canEditItem,
  canEditWeek
}: MeetingParams) {
  const getSectionStyle = (section?: string) => {
    return {
      color: getSectionColor(section),
      filter: "brightness(85%)",
      marginTop: "15px"
    };
  };

  const getGroupedSections = (items?: WorkbookWeekItemState[]) => {
    return items?.reduce((r: any, item: WorkbookWeekItemState) => {
      const section = getSection(item.itemTypeId) ?? "";
      r[section] = [...(r[section] || []), item];
      return r;
    }, {});
  };

  const editModeKey = `${meeting} edit mode`;
  let sessionStorageEditMode =
    sessionStorage.getItem(editModeKey)?.toLowerCase() === "true";
  const [editMode, setEditMode] = useState(sessionStorageEditMode);

  const handleEditModeChanged = (editMode: boolean): void => {
    sessionStorage.setItem(editModeKey, String(editMode).toLowerCase());
    setEditMode(editMode);
  };

  const meetingItemsStyle = {
    border: "1px solid #d2e7f9",
    borderRadius: "5px",
  };

  const getAdditionalData = () => {
    let additionalData = Array<AdditionalData>();

    if (duration) {
      additionalData.push({ data: `Duration: ${duration} min.` });
    }

    if (source) {
      additionalData.push({
        data: source,
        onChanged: sourceChanged,
      });
    }

    if (fetchStatus) {
      additionalData.push({ data: fetchStatus, tooltip: fetchError });
    }

    if (publishStatus) {
      additionalData.push({ data: publishStatus });
    }

    return additionalData;
  };

  const groupedMeetingItems = getGroupedSections(meetingItems);

  return (
    <>
      <div key={weekId} className="mb-4" style={meetingItemsStyle}>
        <WeekHeader
          workbookId={workbookId}
          weekId={weekId}
          friendlyWeekName={friendlyWeekName}
          isLoaded={isLoaded}
          isItemsLoaded={isItemsLoaded}
          meeting={meeting}
          meetingType={meetingType}
          canFetch={canFetch}
          canEdit={canEdit}
          canEditWeek={canEditWeek}
          editMode={editMode}
          additionalData={getAdditionalData()}
          onEditModeChanged={handleEditModeChanged}
        />

        <div key={weekId}>
          {isItemsLoaded && (
            <div
              key={weekId}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {meetingItems && !meetingItems.length ? (
                <div key={"emptySection"} className="p-0 pl-3 pr-3">
                  <span
                    style={{ fontSize: "0.875rem" }}
                    className="no-border no-background p-0 m-0 pr-1"
                  >
                    empty
                  </span>
                </div>
              ) : (
                meetingItems &&
                Object.keys(groupedMeetingItems).map((section: string) => {
                  const sectionItems = groupedMeetingItems[section];
                  return sectionItems.map(
                    (item: WorkbookWeekItemState, i: number) => {
                      const isFirstItem = item.id === meetingItems[0].id;
                      const isLastItem =
                        item.id === meetingItems[meetingItems.length - 1].id;

                      let borderClassNames =
                        !isFirstItem && i <= sectionItems.length
                          ? "border-top"
                          : "";

                      if (isFirstItem && section !== "") {
                        borderClassNames += " border-top";
                      }

                      if (!isLastItem && i === sectionItems.length - 1) {
                        borderClassNames += " border-bottom";
                      }

                      return (
                        <React.Fragment key={"section" + section + item.id}>
                          {i === 0 && section !== "" && (
                            <div
                              key={"section" + section}
                              className="p-0 pl-3 pr-3 font-weight-bold"
                              style={getSectionStyle(section)}
                            >
                              {section}
                            </div>
                          )}
                          <div className={borderClassNames}>
                            <WorkbookWeekItem
                              workbookId={workbookId}
                              weekId={weekId}
                              item={item}
                              defaultHandledBy={defaultHandledBy}
                              editMode={editMode}
                              itemChanges={itemChanges}
                              hubClients={hubClients}
                              canEdit={canEdit}
                              canEditItem={canEditItem}
                            />
                          </div>
                        </React.Fragment>
                      );
                    }
                  );
                })
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: ApplicationState) => ({
  getSection: function (itemTypeId: string): string | undefined {
    let sectionName: string | undefined;
    state.workbook?.midweekMeetingGroupedItemTypes?.forEach((group) => {
      group.itemTypes.forEach((itemType) => {
        if (itemType.id === itemTypeId) {
          sectionName = group.section;
          return;
        }
      });

      if (sectionName) {
        return;
      }
    });

    if (sectionName) {
      return sectionName;
    }

    state.workbook?.weekendMeetingGroupedItemTypes?.forEach((group) => {
      group.itemTypes.forEach((itemType) => {
        if (itemType.id === itemTypeId) {
          sectionName = group.section;
          return;
        }
      });

      if (sectionName) {
        return;
      }
    });

    return sectionName;
  },
  itemChanges: state.workbookChanges!.itemChanges!,
  hubClients: state.principal!.hubClients!,
  roles: state.principal?.roles ?? new Array<UserRole>(),
});

export default connect(
  mapStateToProps,
  WorkbookStore.actionCreators
)(WorkbookWeek);
