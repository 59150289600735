import * as React from "react";
import { connect } from "react-redux";
import { WorkbookWeekItemState, actionCreators } from "./ItemStore";

type Props = {
  editMode: boolean;
  item: WorkbookWeekItemState;
};

interface State {
  description: string;
  isDescriptionInvalid: boolean;
}

class ItemDescription extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      description: this.props.item.description,
      isDescriptionInvalid: false,
    };
  }

  async handleUpdates(e: any) {
    const updatedDescription = this.state.description;
    if (updatedDescription !== this.props.item.description) {
      (this.props as any)
        .updateDescription(
          this.props.item.workbookId,
          this.props.item.workbookWeekId,
          this.props.item.id,
          updatedDescription
        )
        .then((success: boolean) => {
          this.setState({ isDescriptionInvalid: !success });
          if (success) {
            this.props.item.description = updatedDescription;
          } else {
            e?.target?.focus();
          }
        });
    } else {
      this.setState({ isDescriptionInvalid: false });
    }
  }

  public render() {
    return this.props.editMode ? (
      <input
        type="text"
        style={{
          marginTop: "2px",
          marginBottom: "1px",
          paddingBottom: "2px",
          width: "100%",
        }}
        className={
          "font-size-small no-border " +
          (this.state.isDescriptionInvalid ?? false ? "is-invalid" : "")
        }
        data-toggle="tooltip"
        title="Description"
        value={this.state.description}
        onChange={(e) => this.setState({ description: e.target.value })}
        onBlur={(e) => this.handleUpdates(e)}
      />
    ) : (
      <span
        style={{ width: "100%", paddingTop: "2px" }}
        className="font-size-small no-background pl-0 text-truncate pr-10"
        data-toggle="tooltip"
        title={"Description: " + this.props.item.description}
      >
        {this.props.item.description ?? ""}
      </span>
    );
  }
}

interface DispatchFromProps {
  updateDescription: (
    workbookId: string,
    workbookWeekId: string,
    id: string,
    description: string
  ) => void;
}

const mapDispatchToProps = (dispatch: any): DispatchFromProps => ({
  updateDescription: (
    workbookId: string,
    workbookWeekId: string,
    itemId: string,
    description: string
  ) =>
    dispatch(
      actionCreators.updateDescription(
        workbookId,
        workbookWeekId,
        itemId,
        description
      )
    ),
});

export default connect(null, mapDispatchToProps)(ItemDescription);
